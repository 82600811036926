import React, { useContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import uncheck from "../assets/Icons/uncheck.svg";
import check from "../assets/Icons/check.svg";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { Context as AuthContext } from "../context/AuthContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import usePrismicLogin from "../hooks/usePrismicLogin"
import { processAPI, processAPIWithHeaders } from "../utils/helpers";
import DialogBox from "../components/Common/DialogBox";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    "&.focused": {
      color: theme.palette.common.darkGrey,
    },
    "&.shrink": {
      fontSize: "1rem",
    },
  },

  contentContainer: {
    maxWidth: theme.layout.contentWidth,

    margin: "0 auto",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
    },
  },
  placeholder: {
    backgroundColor: "blue",
    height: 100,
    width: "100%",
  },

  mainContent: {
    width: "100%",
    height: 220,
    backgroundColor: "#d8d8d8",
    borderRadius: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    ...theme.typography.HL1,
  },

  contentText: {
    ...theme.typography.content,
  },
  checkMark: {
    width: "0.875em",
    height: "0.875em",
    marginTop: 2,
  },
  icon: {
    width: "3.75em",
    height: "3.75em",
  },
  registrationForm: {
    backgroundColor: "white",

    width: "100%",
    boxShadow: "5px 10px 40px rgba(120, 120, 120, 0.22)",
    borderRadius: 8,
  },
  registrationFormContent: {
    marginLeft: 50,
    marginRight: 50,
    paddingTop: 43,
    paddingBottom: 43,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
      marginRight: 30,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 23,
      paddingBottom: 23,
    },
  },
  formControl: {
    width: "100%",
    backgroundColor: theme.palette.common.lightBrown,
    marginBottom: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  downArrow: {
    height: "0.75em",
  },
  dropdownFilled: {
    backgroundColor: theme.palette.common.lightBrown,
  },
  form: {
    marginTop: 23,
  },
  textFields: {
    height: "3.75em",
  },
  textFieldsError: {
    height: "3.75em",
    boxShadow: "0 0 0 2px #da3030",
    borderRadius: 5,
  },
  dropDownContainer: {
    display: "flex",
    flexDirection: "row",
  },
  dropDownRoot: {
    "&:hover": {
      backgroundColor: theme.palette.common.lightBrown,
    },
  },
  blackCircle: {
    backgroundColor: theme.palette.common.darkGrey,
    width: 7,
    height: 7,
    borderRadius: 5,
    marginRight: 5,
  },
  greenCircle: {
    backgroundColor: "#5ba668",
    width: 7,
    height: 7,
    borderRadius: 5,
    marginRight: 5,
  },
  passwordText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
  },
  passwordValidText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.brown,
  },
  submitButton: {
    height: "3.75em",
    backgroundColor: theme.palette.common.main,
    marginTop: 18,
    borderRadius: "2em",
    "&:hover": {
      backgroundColor: theme.palette.common.main,
    },
  },
  buttonText: {
    ...theme.typography.buttonText,
  },
  alreadyMember: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
    width: "100%",
    textAlign: "center",
    textDecorationLine: "underline",
    textDecorationColor: theme.palette.common.darkGrey,
  },
  check: {
    widht: 30,
    height: 30,
  },
  contentTextLink: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
    textUnderlineOffset: "0.25em",
  },
  dropdownStyle: {
    backgroundColor: theme.palette.common.lightBrown,
  },
  underlineText: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",

    lineHeight: "1.5rem",
    textTransform: "uppercase",
    textDecorationLine: "underline",
    textDecorationColor: theme.palette.common.main,
    textDecorationThickness: 2,
    color: theme.palette.common.darkGrey,
    width: "100%",
    textAlign: "center",
    textUnderlineOffset: "0.25em",
  },
  subheading: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
  },
  fieldFocused: {
    backgroundColor: "white",
    border: "2px solid #4C726D",
  },
  filledInputRoot: {
    overflow: "hidden",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingRight: 10,
    borderRadius: 5,
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    height: "100%",
    "&:hover": {
      backgroundColor: "#F9F8F4",
      //backgroundColor: "red",
    },
  },
}));

export default function Login() {
  const { t , i18n} = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [checked, setchecked] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const { setuser, setusersession, setOnboarding } = useContext(AuthContext);
  const [password, setPassword] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [code, setCode] = React.useState("");
  const [verifyAccount, setVerifyAccount] = React.useState(false);
  const [localLoading, setLocalLoading] = React.useState(false);

  const [errEmail, setErrEmail] = React.useState("");
  const [errPassword, setErrPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState({
    title: "Invalid Code",
    text: "Please enter correct code",
  });
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrEmail("");
    setErrPassword("");
    if (verifyAccount) {
      if (code.length !== 6) {
        setError({
          title: "Invalid Code",
          text: "Please enter correct code",
        });
        setOpen(true);
        return;
      }
      verifyCode();
    } else {
      let err = false;
      if (email === "") {
        setErrEmail(t("RequiredField"));
        err = true;
      } else {
        var reg =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!reg.test(email)) {
          setErrEmail(t("InvalidEmail"));
          err = true;
        }
      }

      if (password === "") {
        setErrPassword(t("RequiredField"));
        err = true;
      }

      if (err) {
        return;
      }
      signIn();
    }
  };

  async function signIn(setup = false) {
    console.log("signing in");
    try {
      setLocalLoading(true);
      const user = await Auth.signIn(email, password);
      console.log(user);
      const username = user.username;
      const token = user.signInUserSession.idToken.jwtToken;
      const userData = user.attributes;

      if (setup) {
        const response = await processAPIWithHeaders("secure/users", "POST", {
          body: {
            id: user.username,
            email: userData.email,
            name: {
              first: userData["custom:firstName"],
              last: userData["custom:lastName"],
            },
            gender: userData["custom:gender"],
            date_of_birth: userData["dob"],
            title: userData["title"],
            phone: {
              number: "0151521111",
            },
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
        });
        console.log(response);
      }

      const response = await processAPIWithHeaders(
        "secure/users/" + username,
        "GET",
        {
          headers: {
            //"Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      console.log(response);

      const onboardingResponse = await processAPIWithHeaders(
        "secure/user/onboarding-data/" + username,
        "GET",
        {
          headers: {
            //"Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      setOnboarding(onboardingResponse.has_data);
      setuser(response);
      setusersession(user.signInUserSession);
      setLocalLoading(false);
      history.replace("/doctor-type");
    } catch (error) {
      setLocalLoading(false);
      if (error.code === "UserNotConfirmedException") {
        await Auth.resendSignUp(email);
        setVerifyAccount(true);
      } else {
        setError({
          title: "Error signing in",
          text: error.message,
        });
        setOpen(true);
      }

      console.log("error signing in", error);
    }
  }

  async function verifyCode() {
    console.log("verifying");

    try {
      setLocalLoading(true);
      const result = await Auth.confirmSignUp(email, code);
      console.log(result);
      signIn(true);
    } catch (error) {
      setLocalLoading(false);
      setError({
        title: "Error verifying",
        text: error.message,
      });
      setOpen(true);
      console.log("error verifying:", error);
    }
  }
  const [dataSet, setDataSet] = useState(false);

  const languageMap = {
    en: "en-us",
    de: "de-de",
  };

  const { prismicData, isPremismicLoading, prismicError } = usePrismicLogin();
  const [content, setContent] = useState({
    "en-us": {},
    "de-de": {},
  });

  useEffect(() => {
    if (prismicData) {
      console.log(prismicData);

      let results = prismicData.results;
      let c = content;

      console.log("Result is ", results);
      results.map((result) => {
        if (result.type === "login" && result.data.application === "Patients") {
          //language data
          c[result.lang] = result.data;
          // let fieldSetName = [];
          console.log("Resul",result.data);
        }
      });

      setContent(c);

      setDataSet(true);
    }
  }, [prismicData]);


  // This tells us which language is currently selected
  let lan = languageMap[i18n.language];
  // According to currently selected language we set our main data which we use below in render
  let mainData = content[lan];
  console.log("mainData ds ",mainData);
  if (!dataSet) {
    return <div></div>;
  }

  return (
    <React.Fragment>
      <div className={classes.contentContainer}>
        <Grid container spacing={4} justify="center">
          <Grid item md={6} sm={8} xs={12}>
            <div className={classes.registrationForm}>
              <div className={classes.registrationFormContent}>
                <Typography className={classes.heading}>
                  {verifyAccount
                    ? mainData.verify_account_heading[0].text
                    : mainData.heading[0].text}
                </Typography>
                <Typography
                  className={classes.subheading}
                  style={{ marginTop: 5 }}
                >
                  {verifyAccount
                 ? mainData.verify_account_sub_heading[0].text
                 : mainData.sub_heading[0].text}
                </Typography>

                <form
                  className={classes.form}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleFormSubmit}
                >
                  {verifyAccount && (
                    <TextField
                      id="verifyCode"
                      label={mainData.verify_code_textfield_label[0].text}
                      variant="filled"
                      color="primary"
                      placeholder={ dataSet ? mainData.verify_code_textfield_placeholder[0].text : "Please Enter the code sent to your email address."}
                      value={code}
                      onChange={(event) => {
                        const text = event.target.value;
                        setCode(text);
                      }}
                      fullWidth
                      classes={{ root: classes.textFields }}
                      InputLabelProps={{
                        classes: {
                          root: classes.inputLabel,
                          focused: "focused",
                          shrink: "shrink",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.filledInputRoot,
                          focused: classes.fieldFocused,
                          shrink: "shrink",
                        },
                      }}
                    />
                  )}

                  {!verifyAccount && (
                    <div>
                      <TextField
                        id="email"
                        label={mainData.email_textfield_label[0].text}
                        value={email}
                        onChange={(event) => {
                          const text = event.target.value;
                          setEmail(text);
                        }}
                        variant="filled"
                        color="primary"
                        fullWidth
                        style={{ marginTop: 15 }}
                        classes={{
                          root: errEmail
                            ? classes.textFieldsError
                            : classes.textFields,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: "focused",
                            shrink: "shrink",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.filledInputRoot,
                            focused: classes.fieldFocused,
                            shrink: "shrink",
                          },
                        }}
                      />
                      {errEmail && (
                        <Typography
                          className={classes.errorText}
                          style={{ marginTop: 3 }}
                        >
                          {errEmail}
                        </Typography>
                      )}
                    </div>
                  )}
                  {!verifyAccount && (
                    <div>
                      <TextField
                        id="password"
                        label={mainData.password_textfield_label[0].text}
                        variant="filled"
                        color="primary"
                        value={password}
                        onChange={(event) => {
                          const password = event.target.value;
                          setPassword(password);
                        }}
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        style={{ marginTop: 15 }}
                        classes={{
                          root: errPassword
                            ? classes.textFieldsError
                            : classes.textFields,
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.inputLabel,
                            focused: "focused",
                            shrink: "shrink",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.filledInputRoot,
                            focused: classes.fieldFocused,
                            shrink: "shrink",
                          },
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {errPassword && (
                        <Typography
                          className={classes.errorText}
                          style={{ marginTop: 3 }}
                        >
                          {errPassword}
                        </Typography>
                      )}
                    </div>
                  )}

                  {!verifyAccount && (
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      style={{ marginTop: 20 }}
                    >
                      <Grid item lg={1} md={2} sm={2} xs={2}>
                        <Link
                          to="#"
                          onClick={() => {
                            setchecked(!checked);
                          }}
                        >
                          <img
                            src={checked ? check : uncheck}
                            alt="icon"
                            className={classes.check}
                          />
                        </Link>
                      </Grid>

                      <Grid item lg={11} md={10} sm={10} xs={10}>
                        <div className={classes.subheading}>
                        <span>{mainData.remember_credentials_text[0].text}</span>
                        </div>
                      </Grid>
                    </Grid>
                  )}

                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    disableElevation
                    classes={{
                      root: classes.submitButton,
                      label: classes.buttonText,
                    }}
                  >
                    {localLoading ? (
                      <CircularProgress />
                    ) : verifyAccount ? (
                      mainData.verify_button[0].text
                      ) : (
                        mainData.login_button[0].text
                        )}
                  </Button>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <Typography
                      style={{ marginTop: 26 }}
                      className={classes.underlineText}
                    >
                    {mainData.signup_text[0].text}

                    </Typography>
                  </Link>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <DialogBox
        open={open}
        onClose={() => {
          console.log("closing dialog");
          setOpen(false);
        }}
        title={error.title}
        text={error.text}
      />
    </React.Fragment>
  );
}
