import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { fetcher, fetcherWithHeader } from "../utils/helpers";
import usePrismicRef from "./usePrismicRef";

export default function usePrismicLogin() {
  const { ref, isRefLoading, refError } = usePrismicRef();

  const prismicRef = ref?.refs[0].ref;
  //const prismicRef = "YWQqphQAACAAqDIc";
  const { data, error } = useSWR(
    [
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_PRISMIC_REPO}${process.env.REACT_APP_PRISMIC_BASE_URL}/documents/search?ref=${prismicRef}&lang=*&access_token=${process.env.REACT_APP_PRISMIC_ACCESS_TOKEN}&pageSize=100&q=[[at(document.tags,["login_practitioner"])]]`,
      null,
    ],
    fetcher
  );

  return {
    prismicData: data,
    isPremismicLoading: !error && !data,
    prismicError: error,
  };
}
