import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import ic_arrow_bottom from "../../assets/Icons/ic_arrow_bottom.svg";
import uncheck from "../../assets/Icons/uncheck.svg";
import check from "../../assets/Icons/check.svg";

import { expertiseList } from "../../utils/Constant";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StyledRadio from "../FormFields/StyledRadio";
import { Context as OnboardingContext } from "../../context/OnboardingContext";

const treatmentFocus = [
  "Burn-out",
  "Depression",
  "Angst und Panikstörungen",
  "Essstörungen",
  "AD(H)S",
  "Persönlichkeitsstörungen",
  "Psychische Unterstützung und Begleitung bei Krebserkrankungen",
  "Psychische Unterstützung und Begleitung bei Psychoonkologie",
  "Weiteres",
];

const coachingServicesList = [
  "Business-Coach",
  "Unternehmer-Coach",
  "Mental Coach",
  "Karriere Coach",
  "Werte Coach",
  "Coach für Hochsensible (HSP)",
  "Projekt Coach",
  "Anti-Burnout Coach",
  "Weiteres",
];

const useStyles = makeStyles((theme) => ({
  cardContent: {
    backgroundColor: "white",

    maxWidth: theme.layout.contentWidth,
    boxShadow: "5px 10px 40px rgba(120, 120, 120, 0.22)",
    borderRadius: 8,
    display: "block",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 23,
  },
  heading: {
    ...theme.typography.HL2,
  },

  radioLabel: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    textTransform: "none",
  },
  formControl: {
    width: "100%",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingTop: 14,
    paddingBottom: 14,
    height: "4.3em",
    marginBottom: 15,
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
    },
  },
  menuItem: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    textTransform: "none",
  },
  textFields: {
    height: "3.75em",
  },

  fieldFocused: {
    backgroundColor: "white",
    border: "2px solid #4C726D",
  },
  filledInputRoot: {
    overflow: "hidden",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingRight: 10,
    borderRadius: 5,
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    height: "100%",
    "&:hover": {
      backgroundColor: "#F9F8F4",
      //backgroundColor: "red",
    },
  },
  inputLabel: {
    "&.focused": {
      color: theme.palette.common.darkGrey,
    },
    "&.shrink": {
      fontSize: "1rem",
    },
  },
  addLanguage: {
    width: "100%",
    backgroundColor: "transparent",
    paddingTop: 14,
    paddingBottom: 14,
    height: "4.3em",
    marginBottom: 15,
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  check: {
    width: 20,
    height: 20,
  },
  errorText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.red,
  },
}));

export default function PhychotherapyForm() {
  const initialAdditionalDesignations = [
    "Akupunktur",
    "Allergologie",
    "Andrologie",
    "Arbeitsmedizin",
    "Ärztliches Qualitätsmanagement",
  ];

  const {
    state: { professionalData, errorData },
    updateProfessionalData,
    resetProfessionalData,
  } = useContext(OnboardingContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const [approbation, setApprobation] = useState("-");
  const [approbationWidth, setApprobationWidth] = useState("0");
  const [approbationAnchorEl, setApprobationAnchorEl] = React.useState(null);

  const [anderes, setAnderes] = useState("");

  const [licenseDate, setLicenseDate] = useState("");

  const [furtherTraining, setFurtherTraining] = useState("");

  const [furtherTrainingDate, setFurtherTrainingDate] = useState("");

  const [licenseFor, setLicenseFor] = useState("");

  const [medicalHistory, setMedicalHistory] = useState("");

  const [areaExpertiseWidth, setAreaExpertiseWidth] = useState("0");
  const [areaExpertiseAnchorEl, setAreaExpertiseAnchorEl] =
    React.useState(null);
  const [areaExpertise, setAreaExpertise] = useState(["-"]);
  const [expertiseCount, setExpertiseCount] = useState(1);
  const [menuIndex, setMenuIndex] = useState(-1);

  const [additionalDesignations, setAdditionalDesignations] = useState([
    "Akupunktur",
    "Allergologie",
    "Andrologie",
    "Arbeitsmedizin",
    "Ärztliches Qualitätsmanagement",
  ]);
  const [additionalDesignationWidth, setAdditionalDesignationWidth] =
    useState("0");
  const [additionalDesignationAnchorEl, setAdditionalDesignationAnchorEl] =
    React.useState(null);
  const [totalAD, setTotalAD] = useState(["-"]);
  const [adCount, setADCount] = useState(1);

  const [licenseRegistered, setLicenseRegistered] = useState("-");
  const [lrWidth, setLRWidth] = useState("0");
  const [lrAnchorEl, setlrAnchorEL] = React.useState(null);

  const [wantSeat, setWantSeat] = useState(false);

  const [burnOut, setBurnOut] = useState(false);
  const [depression, setDepression] = useState(false);
  const [anxiety, setAnxiety] = useState(false);
  const [eatingDisorder, setEatingDisorder] = useState(false);
  const [adhs, setADHS] = useState(false);
  const [personality, setPersonalityDisorder] = useState(false);
  const [cancer, setCancer] = useState(false);
  const [psycho, setPsycho] = useState(false);
  const [additional, setAdditional] = useState(false);

  const [coachingServices, setCoachingServices] = useState("");
  const [businessCoach, setBusinessCoach] = useState(false);
  const [entrepreneur, setEntrepreneur] = useState(false);
  const [mental, setMental] = useState(false);
  const [karriere, setKarriere] = useState(false);
  const [werte, setWerte] = useState(false);
  const [hsp, setHSP] = useState(false);
  const [project, setProject] = useState(false);
  const [antiBurnOut, setAntiBuronOut] = useState(false);
  const [additionalCoach, setAdditionalCoach] = useState(false);

  const [treatments, setTreatments] = useState([]);
  const [coaching_services, setCoachingServicesList] = useState([]);

  useEffect(() => {
    if (
      professionalData.treatment_focus !== undefined &&
      treatments.length === 0
    ) {
      setTreatments(professionalData.treatment_focus);
    }
  }, [treatments, professionalData.treatment_focus]);

  useEffect(() => {
    if (
      professionalData.coaching_services !== undefined &&
      coaching_services.length === 0 &&
      professionalData.coaching_services.offer === "yes" &&
      professionalData.coaching_services.services !== undefined &&
      professionalData.coaching_services.services.length > 0
    ) {
      setCoachingServicesList(professionalData.coaching_services.services);
    }
  }, [coaching_services, professionalData.coaching_services]);

  const handleClickApprobation = (event) => {
    var w = document.getElementById("approbation").clientWidth;
    console.log(w);
    setApprobationWidth(w.toString());
    setApprobationAnchorEl(event.currentTarget);
  };

  const handleCloseApprobation = (approbation = "") => {
    setApprobationAnchorEl(null);
    if (approbation !== "") {
      updateProfessionalData({
        professional_permit_issued_in: approbation,
      });
      setApprobation(approbation);
    }
  };

  const handleFurtherTrainingChange = (event) => {
    setFurtherTraining(event.target.value);
  };

  const handleLicenseFor = (event) => {
    updateProfessionalData({
      cash_register_seat: {
        have: event.target.value,
      },
    });
    setLicenseFor(event.target.value);
  };

  const handleCoachingServices = (event) => {
    updateProfessionalData({
      coaching_services: {
        offer: event.target.value,
        services: [],
      },
    });
    setCoachingServices(event.target.value);
  };

  const handleClickAreaExpertise = (event, index) => {
    var w = document.getElementById("areaExpertise").clientWidth;
    console.log(w);
    setAreaExpertiseWidth(w.toString());
    setMenuIndex(index);
    setAreaExpertiseAnchorEl(event.currentTarget);
  };

  const handleCloseAreaExpertise = (expertise = "", index) => {
    setAreaExpertiseAnchorEl(null);
    if (expertise !== "") {
      let tl = areaExpertise;
      console.log(index);
      tl[menuIndex] = expertise;
      setAreaExpertise(tl);
      setMenuIndex(-1);
    }
  };

  const handleClickAD = (event, index) => {
    var w = document.getElementById("additionalDesignations").clientWidth;
    console.log(w);
    setAdditionalDesignationWidth(w.toString());
    var l = additionalDesignations;

    totalAD.forEach((t) => {
      var i = l.indexOf(t);
      if (i > -1) {
        l.splice(i, 1);
      }
    });
    console.log(l);
    setMenuIndex(index);
    setAdditionalDesignations(l);
    setAdditionalDesignationAnchorEl(event.currentTarget);
  };

  const handleCloseAD = (lang = "", index) => {
    setAdditionalDesignationAnchorEl(null);
    if (lang !== "") {
      let tl = totalAD;

      tl[menuIndex] = lang;
      setTotalAD(tl);
      setAdditionalDesignations(initialAdditionalDesignations);
      setMenuIndex(-1);
    }
  };

  const handleClickLR = (event) => {
    var w = document.getElementById("whereLicenseRegistered").clientWidth;
    console.log(w);
    setLRWidth(w.toString());
    setlrAnchorEL(event.currentTarget);
  };

  const handleCloseLR = (approbation = "") => {
    setlrAnchorEL(null);
    if (approbation !== "") {
      updateProfessionalData({
        cash_register_seat: {
          have: licenseFor,
          head_office: approbation,
        },
      });
      setLicenseRegistered(approbation);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.cardContent}>
        <Typography className={classes.heading}>
          {t("ProfessionalExperience")}*
        </Typography>
        <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                value={
                  professionalData.professional_training === undefined
                    ? ""
                    : professionalData.professional_training
                }
                aria-label="licensedDoctor"
                name="customized-radios"
                onChange={(event, value) => {
                  updateProfessionalData({ professional_training: value });
                }}
              >
                <Typography className={classes.radioLabel}>
                  {t("StateRecognizedTraining")}
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {/* <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("WhereWasPermitIssues")}
        </Typography>
        <Grid container style={{ marginTop: 5 }} spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Button
              aria-controls="approbation"
              aria-haspopup="true"
              onClick={handleClickApprobation}
              id="approbation"
              disableRipple
              className={classes.formControl}
              classes={{ label: classes.menuLabel }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "MessinaSans-Regular",
                    fontSize: "0.875rem",
                    color: theme.palette.common.darkGrey,
                    textTransform: "none",
                    padding: 0,
                    marginLeft: 3,
                  }}
                >
                  Place
                </Typography>
                <Typography
                  style={{
                    fontFamily: "MessinaSans-SemiBold",
                    fontSize: "1rem",
                    color: theme.palette.common.darkGrey,
                    textTransform: "none",
                    marginLeft: 3,
                    textAlign: "left",
                  }}
                >
                  {professionalData.professional_permit_issued_in === undefined
                    ? "-"
                    : professionalData.professional_permit_issued_in !==
                      "Deutschland"
                    ? "Anderes Land"
                    : "Deutschland"}
                </Typography>
              </div>
              <img
                src={ic_arrow_bottom}
                alt="select"
                className={classes.downArrow}
              />
            </Button>

            <Menu
              id="approbation"
              anchorEl={approbationAnchorEl}
              open={Boolean(approbationAnchorEl)}
              onClose={() => {
                handleCloseApprobation();
              }}
              PaperProps={{
                style: {
                  width: parseFloat(approbationWidth),
                  backgroundColor: "white",
                  paddingTop: 10,

                  border: `2px solid ${theme.palette.common.main}`,
                },
              }}
            >
              <div
                onClick={() => {
                  handleCloseApprobation();
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: 15,
                  justifyContent: "space-between",
                  paddingRight: 15,
                  marginBottom: 10,
                }}
              >
                <div>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-Regular",
                      fontSize: "0.875rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      padding: 0,
                    }}
                  >
                    Place
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-SemiBold",
                      fontSize: "1rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      marginLeft: i18next.language === "en" ? 0 : 0,
                    }}
                  >
                    {professionalData.professional_permit_issued_in ===
                    undefined
                      ? "-"
                      : professionalData.professional_permit_issued_in !==
                        "Deutschland"
                      ? "Anderes Land"
                      : "Deutschland"}
                  </Typography>
                </div>
                <img
                  src={ic_arrow_bottom}
                  alt="select"
                  className={classes.downArrow}
                />
              </div>

              <MenuItem
                className={classes.menuItem}
                onClick={() => handleCloseApprobation("Deutschland")}
              >
                Deutschland
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                onClick={() => handleCloseApprobation("Anderes Land")}
              >
                Anderes Land
              </MenuItem>
            </Menu>

            {professionalData.professional_permit_issued_in !== undefined &&
              professionalData.professional_permit_issued_in !== "-" &&
              professionalData.professional_permit_issued_in !==
                "Deutschland" && (
                <TextField
                  id="anderesland"
                  label={t("Anderes Land")}
                  variant="filled"
                  color="primary"
                  value={
                    professionalData.professional_permit_issued_in ===
                    "Anderes Land"
                      ? ""
                      : professionalData.professional_permit_issued_in
                  }
                  onChange={(event) => {
                    const text = event.target.value;
                    updateProfessionalData({
                      professional_permit_issued_in: text,
                    });
                    setAnderes(text);
                  }}
                  fullWidth
                  classes={{
                    root: classes.textFields,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                      focused: "focused",
                      shrink: "shrink",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.filledInputRoot,
                      focused: classes.fieldFocused,
                      shrink: "shrink",
                    },
                  }}
                />
              )}
          </Grid>
        </Grid>
        <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("WhenWasPermitIssued")}
        </Typography>
        <Grid container style={{ marginTop: 5 }} spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TextField
              id="licenseDate"
              label={t("Date")}
              variant="filled"
              color="primary"
              placeholder="MM/YYYY"
              value={
                professionalData.professional_permit_issued_on === undefined
                  ? ""
                  : professionalData.professional_permit_issued_on
              }
              onChange={(event) => {
                const text = event.target.value;
                updateProfessionalData({
                  professional_permit_issued_on: text,
                });
                setLicenseDate(text);
              }}
              fullWidth
              classes={{
                root: classes.textFields,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.filledInputRoot,
                  focused: classes.fieldFocused,
                  shrink: "shrink",
                },
              }}
            />
          </Grid>
        </Grid> */}
        <Grid container style={{ marginTop: 15 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                value={
                  professionalData.cash_register_seat !== undefined
                    ? professionalData.cash_register_seat.have
                    : ""
                }
                aria-label="licenseFor"
                name="customized-radios"
                onChange={handleLicenseFor}
              >
                <Typography className={classes.radioLabel}>
                  {t("CashRegisterSeat")}
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {professionalData.cash_register_seat?.have === "yes" && (
          <div style={{ width: "100%" }}>
            {/* <Typography
              className={classes.radioLabel}
              style={{ marginTop: 20 }}
            >
              {t("WhereHeadOffice")}
            </Typography>

            <Grid container style={{ marginTop: 5 }} spacing={2}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Button
                  aria-controls="whereLicenseRegistered"
                  aria-haspopup="true"
                  onClick={handleClickLR}
                  id="whereLicenseRegistered"
                  disableRipple
                  className={classes.formControl}
                  classes={{ label: classes.menuLabel }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-Regular",
                        fontSize: "0.875rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        padding: 0,
                        marginLeft: 3,
                      }}
                    >
                      Head Office
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: 3,
                        textAlign: "left",
                      }}
                    >
                      {professionalData.cash_register_seat.head_office ===
                      undefined
                        ? "-"
                        : professionalData.cash_register_seat.head_office}
                    </Typography>
                  </div>
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                </Button>

                <Menu
                  id="whereLicenseRegistered"
                  anchorEl={lrAnchorEl}
                  open={Boolean(lrAnchorEl)}
                  onClose={() => {
                    handleCloseLR();
                  }}
                  PaperProps={{
                    style: {
                      width: parseFloat(lrWidth),
                      backgroundColor: "white",
                      paddingTop: 10,

                      border: `2px solid ${theme.palette.common.main}`,
                    },
                  }}
                >
                  <div
                    onClick={() => {
                      handleCloseLR();
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: 15,
                      justifyContent: "space-between",
                      paddingRight: 15,
                      marginBottom: 10,
                    }}
                  >
                    <div>
                      <Typography
                        style={{
                          fontFamily: "MessinaSans-Regular",
                          fontSize: "0.875rem",
                          color: theme.palette.common.darkGrey,
                          textTransform: "none",
                          padding: 0,
                        }}
                      >
                        Head Office
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "MessinaSans-SemiBold",
                          fontSize: "1rem",
                          color: theme.palette.common.darkGrey,
                          textTransform: "none",
                          marginLeft: i18next.language === "en" ? 0 : 0,
                        }}
                      >
                        {professionalData.cash_register_seat.head_office ===
                        undefined
                          ? "-"
                          : professionalData.cash_register_seat.head_office}
                      </Typography>
                    </div>
                    <img
                      src={ic_arrow_bottom}
                      alt="select"
                      className={classes.downArrow}
                    />
                  </div>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Baden-Württemberg")}
                  >
                    Baden-Württemberg
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Bayern")}
                  >
                    Bayern
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Berlin")}
                  >
                    Berlin
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Brandenburg")}
                  >
                    Brandenburg
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Bremen")}
                  >
                    Bremen
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Hamburg")}
                  >
                    Hamburg
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Hessen")}
                  >
                    Hessen
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Mecklenburg-Vorpommern")}
                  >
                    Mecklenburg-Vorpommern
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Niedersachsen")}
                  >
                    Niedersachsen
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Nordrhein")}
                  >
                    Nordrhein
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Rheinland-Pfalz")}
                  >
                    Rheinland-Pfalz
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Saarland")}
                  >
                    Saarland
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Sachsen")}
                  >
                    Sachsen
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Sachsen-Anhalt")}
                  >
                    Sachsen-Anhalt
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Schleswig-Holstein")}
                  >
                    Schleswig-Holstein
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Thüringen")}
                  >
                    Thüringen
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Westfalen-Lippe")}
                  >
                    Westfalen-Lippe
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid> */}

            <Typography
              className={classes.radioLabel}
              style={{ marginTop: 20 }}
            >
              {t("MinimumConsultationHours")}
            </Typography>

            <Grid container style={{ marginTop: 10 }} spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={
                      professionalData.minimum_consultation_hours === undefined
                        ? ""
                        : professionalData.minimum_consultation_hours
                    }
                    aria-label="consultationHours"
                    name="customized-radios"
                    onChange={(event, value) => {
                      updateProfessionalData({
                        minimum_consultation_hours: value,
                      });
                    }}
                  >
                    <FormControlLabel
                      classes={{ root: classes.radioLabel }}
                      value="25"
                      control={<StyledRadio />}
                      label={t("Voller Versorgungsauftrag (25 Std./Woche)")}
                    />
                    <FormControlLabel
                      classes={{ root: classes.radioLabel }}
                      value="18.75"
                      control={<StyledRadio />}
                      label={t(
                        "Dreiviertel Versorgungsauftrag (18,75 Std./Woche)"
                      )}
                    />
                    <FormControlLabel
                      classes={{ root: classes.radioLabel }}
                      value="12.5"
                      control={<StyledRadio />}
                      label={t(
                        "Hälftiger Versorgungsauftrag (12,5 Std./Woche)"
                      )}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}
        {professionalData.cash_register_seat?.have === "no" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Link
              href="#"
              onClick={() => {
                updateProfessionalData({
                  cash_register_seat: {
                    have: licenseFor,
                    need_seat: !wantSeat,
                  },
                });
                setWantSeat(!wantSeat);
              }}
            >
              <img
                src={
                  professionalData.cash_register_seat.need_seat
                    ? check
                    : uncheck
                }
                alt="icon"
                className={classes.check}
              />
            </Link>

            <Typography
              className={classes.radioLabel}
              style={{ marginLeft: 10, marginTop: -2 }}
            >
              {t("NeedCashRegister")}
            </Typography>
          </div>
        )}
        {/* <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("WhatTreatmentFocus")}
        </Typography>
        {treatmentFocus.map((treatment, index) => (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Link
              href="#"
              onClick={() => {
                if (treatments.includes(treatment)) {
                  let t = treatments;
                  let i = t.indexOf(treatment);
                  t.splice(i, 1);
                  updateProfessionalData({
                    treatment_focus: t,
                  });
                  setTreatments(t);
                } else {
                  let t = treatments;

                  t.push(treatment);
                  updateProfessionalData({
                    treatment_focus: t,
                  });
                  setTreatments(t);
                }
              }}
            >
              <img
                src={treatments.includes(treatment) ? check : uncheck}
                alt="icon"
                className={classes.check}
              />
            </Link>

            <Typography
              className={classes.radioLabel}
              style={{ marginLeft: 10, marginTop: -2 }}
            >
              {t(treatment)}
            </Typography>
          </div>
        ))}

        {treatments.includes("Weiteres") && (
          <Grid container style={{ marginTop: 5 }} spacing={2}>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <TextField
                id="additional"
                label={t("Weiteres")}
                variant="filled"
                color="primary"
                fullWidth
                value={
                  professionalData.additional_treatment === undefined
                    ? ""
                    : professionalData.additional_treatment
                }
                onChange={(event) => {
                  const text = event.target.value;
                  updateProfessionalData({
                    additional_treatment: text,
                  });
                }}
                classes={{
                  root: classes.textFields,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.filledInputRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>
          </Grid>
        )} */}
        {/* <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                value={
                  professionalData.coaching_services === undefined
                    ? ""
                    : professionalData.coaching_services.offer
                }
                aria-label="coachingServices"
                name="customized-radios"
                onChange={handleCoachingServices}
              >
                <Typography className={classes.radioLabel}>
                  {t("CoachingServices")}
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {professionalData.coaching_services?.offer === "yes" && (
          <div style={{ width: "100%" }}>
            <Typography
              className={classes.radioLabel}
              style={{ marginTop: 20 }}
            >
              {t("WhatMainFocus")}
            </Typography>

            {coachingServicesList.map((coaching, index) => (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Link
                  href="#"
                  onClick={() => {
                    if (coaching_services.includes(coaching)) {
                      let t = coaching_services;
                      let i = t.indexOf(coaching);
                      t.splice(i, 1);
                      updateProfessionalData({
                        coaching_services: {
                          offer: professionalData.coaching_services.offer,
                          services: t,
                        },
                      });
                      setCoachingServicesList(t);
                    } else {
                      let t = coaching_services;

                      t.push(coaching);
                      updateProfessionalData({
                        coaching_services: {
                          offer: professionalData.coaching_services.offer,
                          services: t,
                        },
                      });
                      setCoachingServicesList(t);
                    }
                  }}
                >
                  <img
                    src={coaching_services.includes(coaching) ? check : uncheck}
                    alt="icon"
                    className={classes.check}
                  />
                </Link>

                <Typography
                  className={classes.radioLabel}
                  style={{ marginLeft: 10, marginTop: -2 }}
                >
                  {t(coaching)}
                </Typography>
              </div>
            ))}
          </div>
        )}

        {coaching_services.includes("Weiteres") && (
          <Grid container style={{ marginTop: 5 }} spacing={2}>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <TextField
                id="additional"
                label={t("Weiteres")}
                variant="filled"
                color="primary"
                fullWidth
                value={
                  professionalData.additional_coaching_service === undefined
                    ? ""
                    : professionalData.additional_coaching_service
                }
                onChange={(event) => {
                  const text = event.target.value;
                  updateProfessionalData({
                    additional_coaching_service: text,
                  });
                }}
                classes={{
                  root: classes.textFields,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.filledInputRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                value={
                  professionalData.offer_supervision === undefined
                    ? ""
                    : professionalData.offer_supervision
                }
                aria-label="licensedDoctor"
                name="customized-radios"
                onChange={(event, value) => {
                  updateProfessionalData({
                    offer_supervision: value,
                  });
                }}
              >
                <Typography className={classes.radioLabel}>
                  {t("Supervision")}
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                value={
                  professionalData.offer_couple_therapy === undefined
                    ? ""
                    : professionalData.offer_couple_therapy
                }
                aria-label="licensedDoctor"
                name="customized-radios"
                onChange={(event, value) => {
                  updateProfessionalData({
                    offer_couple_therapy: value,
                  });
                }}
              >
                <Typography className={classes.radioLabel}>
                  {t("CoupleTherapy")}
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid> */}
        {errorData.error && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("AllFieldsRequiredInSection")}
          </Typography>
        )}
      </div>
    </React.Fragment>
  );
}
