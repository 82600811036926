import createDataContext from "./createDataContext";

const authReducer = (state, action) => {
  switch (action.type) {
    case "set_user":
      return { ...state, user: action.payload };
    case "set_user_session":
      return { ...state, usersession: action.payload };
    case "set_on_boarding":
      return { ...state, onBoarding: action.payload };
    default:
      return state;
  }
};

const setuser = (dispatch) => async (user) => {
  dispatch({ type: "set_user", payload: user });
};

const setusersession = (dispatch) => async (user) => {
  dispatch({ type: "set_user_session", payload: user });
};

const setOnboarding = (dispatch) => async (onBoarding) => {
  dispatch({ type: "set_on_boarding", payload: onBoarding });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  { setuser, setusersession, setOnboarding },
  { user: null, usersession: null, onBoarding: null }
);
