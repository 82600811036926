import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import ic_arrow_bottom from "../../assets/Icons/ic_arrow_bottom.svg";
import uncheck from "../../assets/Icons/uncheck.svg";
import check from "../../assets/Icons/check.svg";

import { expertiseList } from "../../utils/Constant";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StyledRadio from "../FormFields/StyledRadio";
import { Context as OnboardingContext } from "../../context/OnboardingContext";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    backgroundColor: "white",

    maxWidth: theme.layout.contentWidth,
    boxShadow: "5px 10px 40px rgba(120, 120, 120, 0.22)",
    borderRadius: 8,
    display: "block",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 23,
  },
  heading: {
    ...theme.typography.HL2,
  },

  radioLabel: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    textTransform: "none",
  },
  formControl: {
    width: "100%",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingTop: 14,
    paddingBottom: 14,
    height: "4.3em",
    marginBottom: 15,
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
    },
  },
  menuItem: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    textTransform: "none",
  },
  textFields: {
    height: "3.75em",
  },

  fieldFocused: {
    backgroundColor: "white",
    border: "2px solid #4C726D",
  },
  filledInputRoot: {
    overflow: "hidden",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingRight: 10,
    borderRadius: 5,
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    height: "100%",
    "&:hover": {
      backgroundColor: "#F9F8F4",
      //backgroundColor: "red",
    },
  },
  inputLabel: {
    "&.focused": {
      color: theme.palette.common.darkGrey,
    },
    "&.shrink": {
      fontSize: "1rem",
    },
  },
  addLanguage: {
    width: "100%",
    backgroundColor: "transparent",
    paddingTop: 14,
    paddingBottom: 14,
    height: "4.3em",
    marginBottom: 15,
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  check: {
    width: 20,
    height: 20,
  },
  errorText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.red,
  },
}));

export default function NaturopathForm() {
  const initialAdditionalDesignations = [
    "Akupunktur",
    "Allergologie",
    "Andrologie",
    "Arbeitsmedizin",
    "Ärztliches Qualitätsmanagement",
  ];

  const {
    state: { professionalData, errorData },
    updateProfessionalData,
    resetProfessionalData,
  } = useContext(OnboardingContext);

  useEffect(() => {
    //resetProfessionalData();
  }, []);

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const [approbation, setApprobation] = useState("-");
  const [approbationWidth, setApprobationWidth] = useState("0");
  const [approbationAnchorEl, setApprobationAnchorEl] = React.useState(null);

  const [anderes, setAnderes] = useState("");

  const [licenseDate, setLicenseDate] = useState("");

  const [furtherTraining, setFurtherTraining] = useState("");

  const [furtherTrainingDate, setFurtherTrainingDate] = useState("");

  const [licenseFor, setLicenseFor] = useState("");

  const [medicalHistory, setMedicalHistory] = useState("");

  const [areaExpertiseWidth, setAreaExpertiseWidth] = useState("0");
  const [areaExpertiseAnchorEl, setAreaExpertiseAnchorEl] =
    React.useState(null);
  const [areaExpertise, setAreaExpertise] = useState(["-"]);
  const [expertiseCount, setExpertiseCount] = useState(1);
  const [menuIndex, setMenuIndex] = useState(-1);

  const [additionalDesignations, setAdditionalDesignations] = useState([
    "Akupunktur",
    "Allergologie",
    "Andrologie",
    "Arbeitsmedizin",
    "Ärztliches Qualitätsmanagement",
  ]);
  const [additionalDesignationWidth, setAdditionalDesignationWidth] =
    useState("0");
  const [additionalDesignationAnchorEl, setAdditionalDesignationAnchorEl] =
    React.useState(null);
  const [totalAD, setTotalAD] = useState(["-"]);
  const [adCount, setADCount] = useState(1);

  const [licenseRegistered, setLicenseRegistered] = useState("-");
  const [lrWidth, setLRWidth] = useState("0");
  const [lrAnchorEl, setlrAnchorEL] = React.useState(null);

  const [wantSeat, setWantSeat] = useState(false);

  const [burnOut, setBurnOut] = useState(false);
  const [depression, setDepression] = useState(false);
  const [anxiety, setAnxiety] = useState(false);
  const [eatingDisorder, setEatingDisorder] = useState(false);
  const [adhs, setADHS] = useState(false);
  const [personality, setPersonalityDisorder] = useState(false);
  const [cancer, setCancer] = useState(false);
  const [psycho, setPsycho] = useState(false);
  const [additional, setAdditional] = useState(false);

  const [coachingServices, setCoachingServices] = useState("");
  const [businessCoach, setBusinessCoach] = useState(false);
  const [entrepreneur, setEntrepreneur] = useState(false);
  const [mental, setMental] = useState(false);
  const [karriere, setKarriere] = useState(false);
  const [werte, setWerte] = useState(false);
  const [hsp, setHSP] = useState(false);
  const [project, setProject] = useState(false);
  const [antiBurnOut, setAntiBuronOut] = useState(false);
  const [additionalCoach, setAdditionalCoach] = useState(false);

  const handleClickApprobation = (event) => {
    var w = document.getElementById("approbation").clientWidth;
    console.log(w);
    setApprobationWidth(w.toString());
    setApprobationAnchorEl(event.currentTarget);
  };

  const handleCloseApprobation = (approbation = "") => {
    setApprobationAnchorEl(null);
    if (approbation !== "") {
      updateProfessionalData({
        treatment_focus: approbation,
      });
      setApprobation(approbation);
    }
  };

  const handleFurtherTrainingChange = (event) => {
    setFurtherTraining(event.target.value);
  };

  const handleLicenseFor = (event) => {
    setLicenseFor(event.target.value);
  };

  const handleCoachingServices = (event) => {
    setCoachingServices(event.target.value);
  };

  const handleClickAreaExpertise = (event, index) => {
    var w = document.getElementById("areaExpertise").clientWidth;
    console.log(w);
    setAreaExpertiseWidth(w.toString());
    setMenuIndex(index);
    setAreaExpertiseAnchorEl(event.currentTarget);
  };

  const handleCloseAreaExpertise = (expertise = "", index) => {
    setAreaExpertiseAnchorEl(null);
    if (expertise !== "") {
      let tl = areaExpertise;
      console.log(index);
      tl[menuIndex] = expertise;
      setAreaExpertise(tl);
      setMenuIndex(-1);
    }
  };

  const handleClickAD = (event, index) => {
    var w = document.getElementById("additionalDesignations").clientWidth;
    console.log(w);
    setAdditionalDesignationWidth(w.toString());
    var l = additionalDesignations;

    totalAD.forEach((t) => {
      var i = l.indexOf(t);
      if (i > -1) {
        l.splice(i, 1);
      }
    });
    console.log(l);
    setMenuIndex(index);
    setAdditionalDesignations(l);
    setAdditionalDesignationAnchorEl(event.currentTarget);
  };

  const handleCloseAD = (lang = "", index) => {
    setAdditionalDesignationAnchorEl(null);
    if (lang !== "") {
      let tl = totalAD;

      tl[menuIndex] = lang;
      setTotalAD(tl);
      setAdditionalDesignations(initialAdditionalDesignations);
      setMenuIndex(-1);
    }
  };

  const handleClickLR = (event) => {
    var w = document.getElementById("whereLicenseRegistered").clientWidth;
    console.log(w);
    setLRWidth(w.toString());
    setlrAnchorEL(event.currentTarget);
  };

  const handleCloseLR = (approbation = "") => {
    setlrAnchorEL(null);
    if (approbation !== "") {
      setLicenseRegistered(approbation);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.cardContent}>
        <Typography className={classes.heading}>
          {t("ProfessionalExperience")}*
        </Typography>
        <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                value={
                  professionalData.recognized_basic_qualification !== undefined
                    ? professionalData.recognized_basic_qualification
                    : ""
                }
                aria-label="licensedDoctor"
                name="customized-radios"
                onChange={(event, value) => {
                  updateProfessionalData({
                    recognized_basic_qualification: value,
                  });
                }}
              >
                <Typography className={classes.radioLabel}>
                  {t("BasicQualification")}
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                value={
                  professionalData.have_medical_examiner !== undefined
                    ? professionalData.have_medical_examiner
                    : ""
                }
                aria-label="licensedDoctor"
                name="customized-radios"
                onChange={(event, value) => {
                  updateProfessionalData({
                    have_medical_examiner: value,
                  });
                }}
              >
                <Typography className={classes.radioLabel}>
                  {t("MedicalExaminer")}
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {/* 
        <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("WhichHealthDepartment")}
        </Typography>
        <Grid container style={{ marginTop: 5 }} spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TextField
              id="licenseDate"
              label={t("HealthDepartment")}
              variant="filled"
              color="primary"
              fullWidth
              value={
                professionalData.qualification_from !== undefined
                  ? professionalData.qualification_from
                  : ""
              }
              onChange={(event) => {
                const text = event.target.value;
                updateProfessionalData({
                  qualification_from: text,
                });
              }}
              classes={{
                root: classes.textFields,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.filledInputRoot,
                  focused: classes.fieldFocused,
                  shrink: "shrink",
                },
              }}
            />
          </Grid>
        </Grid>

        <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("WhenReviewed")}
        </Typography>
        <Grid container style={{ marginTop: 5 }} spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TextField
              id="licenseDate"
              label={t("Date")}
              variant="filled"
              color="primary"
              placeholder="MM/YYYY"
              value={
                professionalData.qualification_date !== undefined
                  ? professionalData.qualification_date
                  : ""
              }
              onChange={(event) => {
                const text = event.target.value;
                updateProfessionalData({
                  qualification_date: text,
                });
                setLicenseDate(text);
              }}
              fullWidth
              classes={{
                root: classes.textFields,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.filledInputRoot,
                  focused: classes.fieldFocused,
                  shrink: "shrink",
                },
              }}
            />
          </Grid>
        </Grid>
        <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("WhatTreatmentFocus")}
        </Typography>
        <Grid container style={{ marginTop: 5 }} spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Button
              aria-controls="approbation"
              aria-haspopup="true"
              onClick={handleClickApprobation}
              id="approbation"
              disableRipple
              className={classes.formControl}
              classes={{ label: classes.menuLabel }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "MessinaSans-Regular",
                    fontSize: "0.875rem",
                    color: theme.palette.common.darkGrey,
                    textTransform: "none",
                    padding: 0,
                    marginLeft: 3,
                  }}
                >
                  Treatment
                </Typography>
                <Typography
                  style={{
                    fontFamily: "MessinaSans-SemiBold",
                    fontSize: "1rem",
                    color: theme.palette.common.darkGrey,
                    textTransform: "none",
                    marginLeft: 3,
                    textAlign: "left",
                  }}
                >
                  {professionalData.treatment_focus === undefined
                    ? "-"
                    : professionalData.treatment_focus}
                </Typography>
              </div>
              <img
                src={ic_arrow_bottom}
                alt="select"
                className={classes.downArrow}
              />
            </Button>

            <Menu
              id="approbation"
              anchorEl={approbationAnchorEl}
              open={Boolean(approbationAnchorEl)}
              onClose={() => {
                handleCloseApprobation();
              }}
              PaperProps={{
                style: {
                  width: parseFloat(approbationWidth),
                  backgroundColor: "white",
                  paddingTop: 10,

                  border: `2px solid ${theme.palette.common.main}`,
                },
              }}
            >
              <div
                onClick={() => {
                  handleCloseApprobation();
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: 15,
                  justifyContent: "space-between",
                  paddingRight: 15,
                  marginBottom: 10,
                }}
              >
                <div>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-Regular",
                      fontSize: "0.875rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      padding: 0,
                    }}
                  >
                    Treatment
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-SemiBold",
                      fontSize: "1rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      marginLeft: i18next.language === "en" ? 0 : 0,
                    }}
                  >
                    {professionalData.treatment_focus === undefined
                      ? "-"
                      : professionalData.treatment_focus}
                  </Typography>
                </div>
                <img
                  src={ic_arrow_bottom}
                  alt="select"
                  className={classes.downArrow}
                />
              </div>

              <MenuItem
                className={classes.menuItem}
                onClick={() =>
                  handleCloseApprobation(
                    "Heilpraktiker Ernährungsberatung und andere Spezialisierungen."
                  )
                }
              >
                Heilpraktiker Ernährungsberatung
                <br /> und andere Spezialisierungen.
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                onClick={() =>
                  handleCloseApprobation("Heilpraktiker Akupunktur")
                }
              >
                Heilpraktiker Akupunktur
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                onClick={() =>
                  handleCloseApprobation("Heilpraktiker Ernährungsberatung")
                }
              >
                Heilpraktiker Ernährungsberatung
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                onClick={() =>
                  handleCloseApprobation("Heilpraktiker Heilpflanzenkunde")
                }
              >
                Heilpraktiker Heilpflanzenkunde
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                onClick={() =>
                  handleCloseApprobation("Heilpraktiker Klassische Homöopathie")
                }
              >
                Heilpraktiker Klassische Homöopathie
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                onClick={() =>
                  handleCloseApprobation("Heilpraktiker Klassische Homöopathie")
                }
              >
                Heilpraktiker Psychotherapie
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                onClick={() =>
                  handleCloseApprobation("Heilpraktiker Klassische Homöopathie")
                }
              >
                Heilpraktiker Sportmedizin
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                value={
                  professionalData.offer_chinese_medicine !== undefined
                    ? professionalData.offer_chinese_medicine
                    : ""
                }
                aria-label="licensedDoctor"
                name="customized-radios"
                onChange={(event, value) => {
                  updateProfessionalData({
                    offer_chinese_medicine: value,
                  });
                }}
              >
                <Typography className={classes.radioLabel}>
                  {t("TraditionalChineseMedicine")}
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid> */}
        {errorData.error && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("AllFieldsRequiredInSection")}
          </Typography>
        )}
      </div>
    </React.Fragment>
  );
}
