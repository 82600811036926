import React, { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link, useHistory, useLocation } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import logo from "../assets/logo.svg";
import ic_arrow_bottom from "../assets/Icons/ic_arrow_bottom.svg";
import avatar from "../assets/images/doctor_1.jpg";
import { Context as AuthContext } from "../context/AuthContext";
import Menu from "@material-ui/core/Menu";
import usePrismicHeader from "../hooks/usePrismicHeader";
import { useTranslation } from "react-i18next";

const languageMap = {
  en: "en-us",
  de: "de-de",
};

const useStyles = makeStyles((theme) => ({
  logo: {
    height: "1.7em",
    marginTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down("xs")]: {
      marginTop: 30,
      marginBottom: 30,
    },
  },
  submenuarrow: {
    height: "0.75em",
    transform: `rotate(90deg)`,
  },
  submenuarrowhub: {
    height: "0.75em",
    marginTop: 10,
  },
  submenuarrowhubOpen: {
    height: "0.75em",
    marginTop: 10,
    transform: `rotate(180deg)`,
  },
  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  toolbar: {
    maxWidth: theme.layout.contentWidth,

    margin: "0 auto",
    paddingLeft: 50,
    paddingRight: 50,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  menuContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  menuButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  avatar: {
    width: "3em",
    height: "3em",
    border: "3px solid white",
    boxShadow: "0px 3px 12px rgba(120, 120, 120, 0.22)",

    [theme.breakpoints.down("xs")]: {
      width: "2.5em",
      height: "2.5em",
    },
  },
  avatarOpen: {
    width: "3em",
    height: "3em",
    border: "3px solid #4C726D",
    boxShadow: "0px 3px 12px rgba(120, 120, 120, 0.22)",

    [theme.breakpoints.down("xs")]: {
      width: "2.5em",
      height: "2.5em",
    },
  },
  menu: {
    boxShadow: "0px 3px 12px rgba(120, 120, 120, 0.22)",
    marginTop: 0,
    borderRadius: 8,
    marginLeft: 8,
  },
  submenu: {
    boxShadow: "0px 3px 12px rgba(120, 120, 120, 0.22)",
    marginTop: 10,
    borderRadius: 8,
    marginLeft: -225,
  },
  submenu2: {
    boxShadow: "0px 3px 12px rgba(120, 120, 120, 0.22)",
    marginTop: 10,
    borderRadius: 8,
    marginLeft: -240,
  },
  menuTitle: {
    ...theme.typography.menu,
    boxShadow: "0px 3px 12px rgba(120, 120, 120, 0.22)",
    backgroundColor: "white",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 30,
    marginRight: -20,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    fontFamily: "MessinaSans-SemiBold",
    color: theme.palette.common.darkGrey,
  },
  menuTitleOpen: {
    ...theme.typography.menu,
    backgroundColor: "white",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 30,
    marginRight: -20,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    fontFamily: "MessinaSans-SemiBold",
    color: theme.palette.common.darkGrey,
  },
  menuTitleHub: {
    ...theme.typography.menu,
    boxShadow: "0px 3px 12px rgba(120, 120, 120, 0.22)",
    backgroundColor: "white",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 8,
    fontFamily: "MessinaSans-SemiBold",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    color: theme.palette.common.darkGrey,
  },
  menuSection: {
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  menulist: {
    ...theme.typography.menu,
    color: theme.palette.common.darkGrey,
    paddingLeft: 15,
    paddingRight: 15,
    fontFamily: "MessinaSans-SemiBold",
  },
  menuListSelected: {
    textTransform: "none",
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    color: theme.palette.common.brown,
    paddingLeft: 15,
    paddingRight: 15,
    // "&:hover": {
    //   ...theme.typography.menu,
    //   color: theme.palette.common.darkGrey,
    // },
  },
  menuListButton: {
    display: "flex",
    height: 40,

    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  mobileMenuListButton: {
    display: "flex",
    height: 40,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  mainMenu: {
    boxShadow: "0px 3px 12px rgba(120, 120, 120, 0.22)",
    marginTop: 14,
    borderRadius: 8,
    marginLeft: -90,
    width: 250,
    paddingTop: 55,
    paddingBottom: 20,
    [theme.breakpoints.down("md")]: {
      marginLeft: -60,
    },
  },
  mainMenuMobile: {
    boxShadow: "0px 3px 12px rgba(120, 120, 120, 0.22)",
    marginTop: 4,
    borderRadius: 8,
    borderTopRightRadius: "1.5em",

    width: "100%",

    paddingTop: 50,
  },
  mainMenuItemSelected: {
    ...theme.typography.mainMenu,
    color: theme.palette.common.darkGrey,
    paddingLeft: 15,
    paddingRight: 35,
  },
  mainMenuItem: {
    textTransform: "none",

    fontSize: "1.125rem",
    color: theme.palette.common.darkGrey,
    paddingLeft: 15,
    paddingRight: 37,
    fontFamily: "MessinaSans-Regular",
    "&:hover": {
      ...theme.typography.mainMenu,
      color: theme.palette.common.darkGrey,
    },
  },

  mainMenuItemHub: {
    textTransform: "none",
    fontWeight: 200,
    fontSize: "1.125rem",
    color: theme.palette.common.brown,
    paddingLeft: 15,
    paddingRight: 37,
    "&:hover": {
      ...theme.typography.mainMenu,
      color: theme.palette.common.darkGrey,
    },
  },
  mainSubMenuItem: {
    flex: 1,
    textTransform: "none",
    fontWeight: 200,
    fontSize: "1.125rem",
    color: theme.palette.common.darkGrey,
    paddingLeft: 15,
    paddingRight: 37,
    fontFamily: "MessinaSans-Regular",
    "&:hover": {
      ...theme.typography.mainMenu,
      color: theme.palette.common.darkGrey,
    },
  },
  subMenuListButton: {
    display: "flex",
    height: 40,
    width: "100%",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "rgba(168, 160, 146,0.2)",
    },
  },
}));

var optionsList = ["", "Berlin", "Frankfurt am Main", "Hamburg"];

export default function Header() {
  let history = useHistory();
  let location = useLocation();
  const { t, i18n } = useTranslation();
  const {
    state: { user },
    setuser,
  } = useContext(AuthContext);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const [selectedIndexMenu, setSelectedIndexMenu] = React.useState(1);
  const [anchorElSubMenu, setAnchorElSubMenu] = React.useState(null);

  const [anchorElSubMenu2, setAnchorElSubMenu2] = React.useState(null);
  const [mouseSubMenu, setMouseSubMenu] = React.useState(false);
  const [mobilehub, setmobilehub] = React.useState(false);
  const [content, setContent] = useState({
    "en-us": {},
    "de-de": {},
  });

  const [navMenu, setNavMenu] = useState({
    "en-us": {},
    "de-de": {},
  });

  const [activeWidth, setActiveWidth] = useState("0");

  const [dataSet, setDataSet] = useState(false);

  const [currentSubMenu, setCurrentSubMenu] = useState([]);

  const { prismicData, isPremismicLoading, prismicError } = usePrismicHeader();
  const [showSubMenu, setShowSubMenu] = useState(-1);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (prismicData) {
      let results = prismicData.results;

      let c = content;
      let nm = navMenu;

      results.map((result) => {
        if (
          result.type === "header" &&
          result.data.application === "Practitioners"
        ) {
          //language data

          c[result.lang] = result.data;

          //navigation menu data
          let mainNavMenuId = result.data.navigation_menu.id;
          let mainNavMenuObjArray = results.filter(
            (results) => results.id == mainNavMenuId
          );
          let mainNavMenuObj = mainNavMenuObjArray[0];
          let mainNavMenuItemsObjs = mainNavMenuObj.data.items;

          let mainNavMenu = [];
          mainNavMenuItemsObjs.map((menuItem) => {
            let linkType = "internal";
            let externalLink = null;
            let internalLink = null;
            let subMenu = [];

            if (menuItem.external_link["id"] !== undefined) {
              linkType = "external";
              let externalLinkArr = results.filter(
                (results) => results.id == menuItem.external_link.id
              );
              externalLink = externalLinkArr[0].data;
            } else if (menuItem.internal_link["id"] !== undefined) {
              linkType = "internal";
              let externalLinkArr = results.filter(
                (results) => results.id == menuItem.internal_link.id
              );
              internalLink = externalLinkArr[0].data;
            }

            if (menuItem.sub_menu["id"] !== undefined) {
              let subMenuArr = results.filter(
                (results) => results.id == menuItem.sub_menu.id
              );
              let subMenuObj = subMenuArr[0];
              let subMenuItemsObjs = subMenuObj.data.items;

              subMenuItemsObjs.map((subMenuItem) => {
                let subMenuLinkType = "internal";
                let subMenuExternalLink = null;
                let subMenuInternalLink = null;
                if (subMenuItem.external_link.id !== undefined) {
                  subMenuLinkType = "external";
                  let subMenuExternalLinkArr = results.filter(
                    (results) => results.id == subMenuItem.external_link.id
                  );
                  subMenuExternalLink = subMenuExternalLinkArr[0].data;
                } else if (subMenuItem.internal_link.id !== undefined) {
                  subMenuLinkType = "internal";
                  let subMenuInternalLinkArr = results.filter(
                    (results) => results.id == subMenuItem.internal_link.id
                  );
                  subMenuInternalLink = subMenuInternalLinkArr[0].data;
                }
                subMenu.push({
                  type: subMenuLinkType,
                  link:
                    subMenuLinkType === "internal"
                      ? subMenuInternalLink
                      : subMenuExternalLink,
                });
              });
            }

            mainNavMenu.push({
              type: linkType,
              link: linkType === "internal" ? internalLink : externalLink,
              subMenu,
            });
          });
          nm[result.lang] = mainNavMenu;
        }
      });

      setContent(c);
      setNavMenu(nm);

      setSelectedIndex(1);

      setDataSet(true);
    }
  }, [prismicData]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    if (index === 0) {
      setAnchorEl(null);
      return;
    }

    setAnchorEl(null);
    setTimeout(() => {
      setSelectedIndex(index);
    }, 200);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSubMenuClose = () => {
    //setMouseSubMenu(false);

    setAnchorElSubMenu(null);
  };
  const handleSubMenuClose2 = () => {
    setAnchorElSubMenu2(null);
  };

  const handleClickListItemMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
    setShowMenu(true);
  };
  const handleClickListItemSubMenu = (event) => {
    setAnchorElSubMenu(event.currentTarget);
  };
  const handleClickListItemSubMenu2 = (event) => {
    setAnchorElSubMenu2(event.currentTarget);
  };

  const signOut = async () => {
    await Auth.signOut();
  };

  const handleMenuItemClickMenu = (event, index) => {
    if (index === 0) {
      setAnchorElMenu(null);
      return;
    }

    if (index === 7) {
      signOut();
      setuser(null);
      history.replace("/login");
    }

    setAnchorElMenu(null);

    setTimeout(() => {
      setSelectedIndexMenu(index);
    }, 200);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setShowMenu(false);
    setShowSubMenu(-1);
  };

  var options = [
    "Active Hub: " + optionsList[selectedIndex],
    "Berlin",
    "Frankfurt am Main",
    "Hamburg",
  ];

  let lan = languageMap[i18n.language];
  let mainData = content[lan];
  let mainNav = navMenu[lan];
  if (dataSet) {
    console.log(mainData);
    console.log(mainNav);
  }

  return (
    <React.Fragment>
      <Toolbar disableGutters className={classes.toolbar}>
        <Button
          component={Link}
          to="/"
          className={classes.logoContainer}
          disableRipple
        >
          {dataSet && (
            <img
              src={mainData.logo.url}
              alt="Eterno"
              className={classes.logo}
            />
          )}
        </Button>
        {(location.pathname === "/dashboard" ||
          location.pathname === "/confirm-registration") &&
          user && (
            <div className={classes.menuContainer}>
              <div>
                <Button
                  aria-haspopup="true"
                  aria-controls="main-menu"
                  aria-label={
                    matches
                      ? Boolean(anchorElMenu)
                        ? "Close"
                        : "Menu"
                      : "Maximilian"
                  }
                  onClick={
                    matches
                      ? Boolean(anchorElMenu)
                        ? handleCloseMenu
                        : handleClickListItemMenu
                      : showMenu
                      ? handleCloseMenu
                      : handleClickListItemMenu
                  }
                  onMouseOver={handleClickListItemMenu}
                  disableRipple
                  className={classes.menuButton}
                  style={{ zIndex: 999, paddingRight: 0, marginRight: -5 }}
                >
                  <Typography
                    className={
                      matches
                        ? Boolean(anchorElMenu)
                          ? classes.menuTitleOpen
                          : classes.menuTitle
                        : showMenu
                        ? classes.menuTitleOpen
                        : classes.menuTitle
                    }
                  >
                    {matches
                      ? Boolean(anchorElMenu)
                        ? "Close"
                        : "Menu"
                      : user
                      ? user.name.first
                      : "Maximilian"}
                  </Typography>
                  <Avatar
                    alt="Maximilian"
                    src={avatar}
                    variant="circle"
                    className={
                      matches
                        ? Boolean(anchorElMenu)
                          ? classes.avatarOpen
                          : classes.avatar
                        : showMenu
                        ? classes.avatarOpen
                        : classes.avatar
                    }
                  />
                </Button>
                {matches ? (
                  <Menu
                    id="main-menu"
                    anchorEl={anchorElMenu}
                    classes={{
                      paper: classes.mainMenuMobile,
                    }}
                    keepMounted
                    open={Boolean(anchorElMenu)}
                    onClose={handleCloseMenu}
                    //MenuListProps={{ onMouseLeave: handleCloseMenu }}
                    style={{ zIndex: 998 }}
                  >
                    {dataSet &&
                      mainNav.map((nav, index) => (
                        <React.Fragment>
                          <Button
                            disableRipple
                            className={classes.mobileMenuListButton}
                            onClick={() => {
                              history.push(nav.link.path[0].text);
                            }}
                          >
                            {location.pathname === nav.link.path[0].text && (
                              <div
                                style={{
                                  height: 40,
                                  width: 10,
                                  backgroundColor: theme.palette.common.main,
                                  paddingLeft: 0,
                                  marginLeft: -10,
                                }}
                              ></div>
                            )}
                            <Typography
                              className={
                                location.pathname === nav.link.path[0].text
                                  ? classes.mainMenuItemSelected
                                  : classes.mainMenuItem
                              }
                              align="left"
                            >
                              {nav.link.title[0].text}
                            </Typography>
                          </Button>
                        </React.Fragment>
                      ))}

                    <React.Fragment>
                      <Button
                        disableRipple
                        className={classes.mobileMenuListButton}
                        onClick={(event) => handleMenuItemClickMenu(event, 7)}
                        style={{ marginTop: 10 }}
                      >
                        {selectedIndexMenu === 7 && (
                          <div
                            style={{
                              height: 40,
                              width: 10,
                              backgroundColor: theme.palette.common.main,
                              paddingLeft: 0,
                              marginLeft: -10,
                            }}
                          ></div>
                        )}
                        <Typography
                          className={
                            selectedIndexMenu === 7
                              ? classes.mainMenuItemSelected
                              : classes.mainMenuItem
                          }
                          align="right"
                        >
                          Logout
                        </Typography>
                      </Button>
                    </React.Fragment>
                  </Menu>
                ) : (
                  <React.Fragment>
                    <div
                      onMouseLeave={() => {
                        setShowMenu(false);
                        setShowSubMenu(-1);
                      }}
                      style={{
                        boxShadow: "0px 3px 12px rgba(120, 120, 120, 0.22)",
                        top: 27.5,
                        borderRadius: 8,
                        borderTopRightRadius: 20,
                        marginLeft: -92,
                        width: 250,
                        paddingTop: 55,
                        paddingBottom: 20,
                        backgroundColor: "white",
                        position: "absolute",
                        display: showMenu ? "block" : "none",
                        zIndex: 998,
                      }}
                    >
                      {dataSet &&
                        mainNav.map((nav, index) => {
                          if (nav.subMenu.length === 0) {
                            return (
                              <React.Fragment>
                                <Button
                                  disableRipple
                                  className={classes.menuListButton}
                                  onMouseOver={() => {
                                    handleSubMenuClose2();
                                    handleSubMenuClose();
                                    setShowSubMenu(index);
                                  }}
                                  onClick={() => {
                                    if (nav.type === "external") {
                                      window.location.href =
                                        nav.link.path[0].text;
                                    } else {
                                      history.push(nav.link.path[0].text);
                                    }

                                    handleCloseMenu();
                                    setShowMenu(false);
                                  }}
                                >
                                  <Typography
                                    className={
                                      location.pathname ===
                                      nav.link.path[0].text
                                        ? classes.mainMenuItemSelected
                                        : classes.mainMenuItem
                                    }
                                    align="right"
                                  >
                                    {nav.link.title[0].text}
                                  </Typography>
                                  {location.pathname ===
                                    nav.link.path[0].text && (
                                    <div
                                      style={{
                                        height: 40,
                                        width: 10,
                                        backgroundColor:
                                          theme.palette.common.main,
                                        paddingRight: 0,
                                        marginRight: -8,
                                      }}
                                    ></div>
                                  )}
                                </Button>
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <div>
                                <div
                                  style={{
                                    position: "absolute",
                                    left: -275,
                                    boxShadow:
                                      "0px 3px 12px rgba(120, 120, 120, 0.22)",

                                    borderRadius: 8,

                                    width: 275,

                                    backgroundColor: "white",
                                    display:
                                      showSubMenu === index ? "block" : "none",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    zIndex: 99999,
                                  }}
                                >
                                  {nav.subMenu.map((currentItem) => (
                                    <Button
                                      disableRipple
                                      className={classes.menuListButton}
                                      onClick={(event) => {
                                        //handleClickListItemSubMenu(event, 0);
                                        handleSubMenuClose();
                                        handleCloseMenu();
                                        if (currentItem.type === "external") {
                                          window.location.href =
                                            currentItem.link.path[0].text;
                                        } else {
                                          history.push(
                                            currentItem.link.path[0].text
                                          );
                                        }
                                      }}
                                    >
                                      <Typography
                                        className={classes.mainMenuItem}
                                        align="right"
                                      >
                                        {currentItem.link.title[0].text}
                                      </Typography>
                                    </Button>
                                  ))}
                                </div>
                                <React.Fragment>
                                  <Button
                                    aria-haspopup="true"
                                    aria-controls={`sub-menu-${index}`}
                                    aria-label={`Sub Menu ${index}`}
                                    disableRipple
                                    className={classes.subMenuListButton}
                                    onMouseOver={(event) => {
                                      setShowSubMenu(index);
                                      // handleClickListItemSubMenu(
                                      //   event,
                                      //   index,
                                      //   nav
                                      // );
                                    }}
                                    onClick={() => {
                                      if (nav.type === "external") {
                                        window.location.href =
                                          nav.link.path[0].text;
                                      } else {
                                        history.push(nav.link.path[0].text);
                                      }

                                      handleCloseMenu();
                                    }}
                                  >
                                    <img
                                      src={ic_arrow_bottom}
                                      alt="submenu"
                                      className={classes.submenuarrow}
                                    />
                                    <Typography
                                      className={classes.mainSubMenuItem}
                                      align="right"
                                    >
                                      {nav.link.title[0].text}
                                    </Typography>
                                  </Button>
                                </React.Fragment>
                              </div>
                            );
                          }
                        })}

                      <React.Fragment>
                        <Button
                          disableRipple
                          className={classes.menuListButton}
                          onClick={(event) => handleMenuItemClickMenu(event, 7)}
                          style={{ marginTop: 10 }}
                          onMouseOver={() => {
                            handleSubMenuClose2();
                            handleSubMenuClose();
                          }}
                        >
                          <Typography
                            className={
                              selectedIndexMenu === 7
                                ? classes.mainMenuItemSelected
                                : classes.mainMenuItem
                            }
                            align="right"
                          >
                            Logout
                          </Typography>
                          {selectedIndexMenu === 7 && (
                            <div
                              style={{
                                height: 40,
                                width: 10,
                                backgroundColor: theme.palette.common.main,
                                paddingRight: 0,
                                marginRight: -8,
                              }}
                            ></div>
                          )}
                        </Button>
                      </React.Fragment>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
      </Toolbar>
    </React.Fragment>
  );
}
