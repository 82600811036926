import axios from "axios";
const BASE_API_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}/`;

export async function processAPI(endpoint, requestMethod, opts) {
  const { method, body } = { method: requestMethod, body: null, ...opts };

  const res = await fetch(`${BASE_API_URL}${endpoint}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res.json();
}

export async function processAPIWithHeaders(endpoint, requestMethod, opts) {
  const { method, body, headers } = {
    method: requestMethod,
    body: null,
    headers: null,
    ...opts,
  };

  const res = await fetch(`${BASE_API_URL}${endpoint}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    ...(headers && { headers: headers }),
  });

  return res.json();
}

export const fetcher = (url, token) => axios.get(url).then((res) => res.data);

export const fetcherWithHeader = (url, headers) =>
  axios.get(url, { headers: headers }).then((res) => res.data);

export const getISODate = (date) => {
  let dateArr = date.split("/");
  return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
};
