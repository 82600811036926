import React, { useContext, useState, useEffect } from "react";

//import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import placeholderIcon from "../assets/images/placeholder.png";
import ic_arrow_bottom from "../assets/Icons/ic_arrow_bottom.svg";
import ic_check from "../assets/Icons/ic_check.svg";
import uncheck from "../assets/Icons/uncheck.svg";
import check from "../assets/Icons/check.svg";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import i18next from "i18next";

import { country_list, expertiseList } from "../utils/Constant";

import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import FilledButton from "../components/Common/FilledButton";
import OutlinedButton from "../components/Common/OutlinedButton";
import UnderlinedButton from "../components/Common/UnderlinedButton";

// import { Context as AuthContext } from "../context/AuthContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import GeneralPhysicianForm from "../components/Form/GeneralPhysicianForm";
import PhysiotherapyForm from "../components/Form/PhysiotherapyForm";
import PhychotherapyForm from "../components/Form/PhychotherapyForm";
import NaturopathForm from "../components/Form/NaturopathForm";
import CoachesForm from "../components/Form/CoachesForm";
import YogaForm from "../components/Form/YogaForm";
import MeditationForm from "../components/Form/MeditationForm";
import MidWiveForm from "../components/Form/MidWiveForm";
import { Context as OnboardingContext } from "../context/OnboardingContext";
import { Context as AuthContext } from "../context/AuthContext";
import { processAPIWithHeaders } from "../utils/helpers";
import { validProfessionalData } from "../utils/formValidation";

import axios from "axios";

const doctorTypes = [
  "GeneralPractitioner",
  "Psychotherapist",
  "MidWives",
  "Physiotherapists",
  "YogaTeacher",
  "MeditationTrainer",
  "Coach",
  "Naturopath",
];

var validateDate = require("validate-date");

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    "&.focused": {
      color: theme.palette.common.darkGrey,
    },
    "&.shrink": {
      fontSize: "1rem",
    },
  },
  contentContainer: {
    maxWidth: theme.layout.contentWidth,

    margin: "0 auto",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
    },
  },
  heading: {
    ...theme.typography.HL1,
  },
  subheading: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1.5rem",
    textTransform: "none",
    lineHeight: "1.875rem",
    color: theme.palette.common.darkGrey,
  },
  subText: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.875rem",
    color: theme.palette.common.darkGrey,
  },
  cardHeading: {
    ...theme.typography.HL3,
  },
  contentText: {
    ...theme.typography.content,
  },
  chatText: {
    ...theme.typography.content,
    width: "100%",
    textAlign: "center",
    paddingTop: 10,
  },
  cardContent: {
    backgroundColor: "white",

    maxWidth: theme.layout.contentWidth,
    boxShadow: "5px 10px 40px rgba(120, 120, 120, 0.22)",
    borderRadius: 8,
    display: "block",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 23,
  },
  fieldFocused: {
    backgroundColor: "white",
    border: "2px solid #4C726D",
  },
  filledInputRoot: {
    overflow: "hidden",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingRight: 10,
    borderRadius: 5,
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    height: "100%",
    "&:hover": {
      backgroundColor: "#F9F8F4",
      //backgroundColor: "red",
    },
  },
  textAreaRoot: {
    overflow: "hidden",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 30,
    paddingBottom: 10,
    borderRadius: 5,
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    height: "100%",

    "&:hover": {
      backgroundColor: "white",
      //backgroundColor: "red",
    },
  },
  textFields: {
    height: "3.75em",
  },
  textArea: {
    height: "6em",
    padding: 0,
  },
  textFieldsError: {
    height: "3.75em",
    boxShadow: "0 0 0 2px #da3030",
    borderRadius: 5,
  },
  formControl: {
    width: "100%",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingTop: 14,
    paddingBottom: 14,
    height: "4.3em",
    marginBottom: 15,
    justifyContent: "space-between",
    // [theme.breakpoints.down("md")]: {
    //   width: 150,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: 100,
    // },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
    },
  },
  addLanguage: {
    width: "100%",
    backgroundColor: "transparent",
    paddingTop: 14,
    paddingBottom: 14,
    height: "4.3em",
    marginBottom: 15,
    justifyContent: "space-between",
    // [theme.breakpoints.down("md")]: {
    //   width: 150,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: 100,
    // },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  menuItem: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    textTransform: "none",
  },
  icon: {
    borderRadius: "50%",
    width: 22,
    height: 22,
    border: "1px solid #D5D5D5",
    // boxShadow:
    //   "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "white",
    // backgroundImage:
    //   "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    // "$root.Mui-focusVisible &": {
    //   outline: "2px auto rgba(19,124,189,.6)",
    //   outlineOffset: 2,
    // },
    "input:hover ~ &": {
      backgroundColor: "white",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.common.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 22,
      height: 22,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.common.main,
    },
  },
  radioRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  radioLabel: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    textTransform: "none",
  },
  findClassbutton: {
    height: "3.75em",
    backgroundColor: "transparent",
    border: `solid 2px ${theme.palette.common.brown}`,
    marginTop: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  findClassText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.brown,
  },
  submitButton: {
    height: "3.75em",
    backgroundColor: theme.palette.common.yellow,

    "&:hover": {
      backgroundColor: theme.palette.common.yellow,
    },
  },
  laterButton: {
    height: "3.75em",
    backgroundColor: theme.palette.common.mediumBrown,

    "&:hover": {
      backgroundColor: theme.palette.common.mediumBrown,
    },
  },
  buttonText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
  },
  laterButtonText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
  },
  errorText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.red,
  },
}));

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.radioRoot}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function RegistrationForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const initialLanguages = [
    "Deutsch",
    "Englisch",
    "Spanisch",
    "Italienisch",
    "Französisch",
    "Russisch",
    "Portugiesisch",
    "Türkisch",
    "Ägyptisch-Arabisch",
  ];

  const initialAdditionalDesignations = [
    "Akupunktur",
    "Allergologie",
    "Andrologie",
    "Arbeitsmedizin",
    "Ärztliches Qualitätsmanagement",
  ];

  const classes = useStyles();
  let { docId } = useParams();

  const buildFileSelector = () => {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute(
      "accept",
      "image/jpeg,application/pdf,image/png,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    );
    return fileSelector;
  };

  const [appFileSelector, setAppFileSelector] = useState(null);
  const [insFileSelector, setInsFileSelector] = useState(null);
  const [appFile, setAppFile] = useState(null);
  const [insFile, setInsFile] = useState(null);

  const {
    state: { user, usersession },
    setOnboarding,
  } = useContext(AuthContext);

  const {
    state: { onboardingData, professionalData },
    updateOnboardingData,
    setProfessionalData,
    updateErrorData,
    resetErrorData,
    returningFromForm,
  } = useContext(OnboardingContext);

  // useEffect(() => {
  //   updateOnboardingData({ category: doctorTypes[docId] });
  //   let approbationFileSelector = buildFileSelector();
  //   let insuranceFileSelector = buildFileSelector();
  //   approbationFileSelector.addEventListener("input", (event) => {
  //     let file = approbationFileSelector.files[0];

  //     setAppFile(file);
  //   });

  //   insuranceFileSelector.addEventListener("input", (event) => {
  //     let file = insuranceFileSelector.files[0];
  //     setInsFile(file);
  //   });

  //   setAppFileSelector(approbationFileSelector);
  //   setInsFileSelector(insuranceFileSelector);

  //   fetchSavedData();
  // }, []);

  const [privateStreet, setPrivateStreet] = useState("");
  const [privateCity, setPrivateCity] = useState("");
  const [privateZip, setPrivateZip] = useState("");
  const [privateCountry, setPrivateCountry] = useState("Germany");
  const [privateCountryWidth, setPrivateCountryWidth] = useState("0");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [businessStreet, setBusinessStreet] = useState("");
  const [businessCity, setBusinessCity] = useState("");
  const [businessZip, setBusinessZip] = useState("");
  const [businessCountry, setBusinessCountry] = useState("Germany");
  const [businessCountryWidth, setBusinessCountryWidth] = useState("0");
  const [businessAnchorEl, setBusinessAnchorEl] = React.useState(null);

  const [additionalDesignations, setAdditionalDesignations] = useState([
    "Akupunktur",
    "Allergologie",
    "Andrologie",
    "Arbeitsmedizin",
    "Ärztliches Qualitätsmanagement",
  ]);
  const [additionalDesignationWidth, setAdditionalDesignationWidth] =
    useState("0");
  const [additionalDesignationAnchorEl, setAdditionalDesignationAnchorEl] =
    React.useState(null);
  const [totalAD, setTotalAD] = useState(["-"]);

  const [language, setLanguage] = useState("Deutsch");
  const [languages, setLanguages] = useState([
    "Deutsch",
    "Englisch",
    "Spanisch",
    "Italienisch",
    "Französisch",
    "Russisch",
    "Portugiesisch",
    "Türkisch",
    "Ägyptisch-Arabisch",
  ]);
  const [languageWidth, setLanguageWidth] = useState("0");
  const [languageAnchorEl, setLanguageAnchorEl] = React.useState(null);

  const [licenseRegistered, setLicenseRegistered] = useState("-");
  const [lrWidth, setLRWidth] = useState("0");
  const [lrAnchorEl, setlrAnchorEL] = React.useState(null);

  const [areaExpertiseWidth, setAreaExpertiseWidth] = useState("0");
  const [areaExpertiseAnchorEl, setAreaExpertiseAnchorEl] =
    React.useState(null);
  const [approbation, setApprobation] = useState("-");
  const [approbationWidth, setApprobationWidth] = useState("0");
  const [approbationAnchorEl, setApprobationAnchorEl] = React.useState(null);

  const [loading, setLoading] = useState(false);

  const [totalLanguages, setTotalLanguages] = useState(["Deutsch"]);
  const [areaExpertise, setAreaExpertise] = useState(["-"]);
  const [expertiseCount, setExpertiseCount] = useState(1);
  const [menuLanguage, setMenuLanguages] = useState(languages);
  const [languageCount, setLanguageCount] = useState(1);
  const [whyEterno, setWhyEterno] = useState("");
  const [menuIndex, setMenuIndex] = useState(-1);
  const [adCount, setADCount] = useState(1);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [employeer, setEmployeer] = useState("");

  const [anderes, setAnderes] = useState("");

  const [licenseDate, setLicenseDate] = useState("");

  const [furtherTraining, setFurtherTraining] = useState("");

  const [furtherTrainingDate, setFurtherTrainingDate] = useState("");

  const [medicalHistory, setMedicalHistory] = useState("");
  const [licenseFor, setLicenseFor] = useState("");

  const [error, setError] = useState({
    privateAddress: false,
    whyEterno: false,
    workingHours: false,
    careers: false,
    validDate: true,
  });

  const [careers, setCareers] = useState([
    {
      from: "",
      to: "",
      employer: "",
    },
  ]);
  const [careerCount, setCareerCount] = useState(1);

  const [workingHours, setWorkingHours] = useState("ten");

  const [autoSaveCount, setAutoSaveCount] = useState(59);

  const handleClickBusinessCountry = (event) => {
    var w = document.getElementById("businessCountry").clientWidth;
    console.log(w);
    setBusinessCountryWidth(w.toString());
    setBusinessAnchorEl(event.currentTarget);
  };

  const handleCloseBusinessCountry = (country = "") => {
    setBusinessAnchorEl(null);
    if (country !== "") {
      setBusinessCountry(country);
    }
  };

  const handleClickLR = (event) => {
    var w = document.getElementById("whereLicenseRegistered").clientWidth;
    console.log(w);
    setLRWidth(w.toString());
    setlrAnchorEL(event.currentTarget);
  };

  const handleCloseLR = (approbation = "") => {
    setlrAnchorEL(null);
    if (approbation !== "") {
      setLicenseRegistered(approbation);
    }
  };

  const handleClickApprobation = (event) => {
    var w = document.getElementById("approbation").clientWidth;
    console.log(w);
    setApprobationWidth(w.toString());
    setApprobationAnchorEl(event.currentTarget);
  };

  const handleCloseApprobation = (approbation = "") => {
    setApprobationAnchorEl(null);
    if (approbation !== "") {
      setApprobation(approbation);
    }
  };

  const handleClickAreaExpertise = (event, index) => {
    var w = document.getElementById("areaExpertise").clientWidth;
    console.log(w);
    setAreaExpertiseWidth(w.toString());
    setMenuIndex(index);
    setAreaExpertiseAnchorEl(event.currentTarget);
  };

  const handleCloseAreaExpertise = (expertise = "", index) => {
    setAreaExpertiseAnchorEl(null);
    if (expertise !== "") {
      let tl = areaExpertise;
      console.log(index);
      tl[menuIndex] = expertise;
      setAreaExpertise(tl);
      setMenuIndex(-1);
    }
  };

  const handleClickAD = (event, index) => {
    var w = document.getElementById("additionalDesignations").clientWidth;
    console.log(w);
    setAdditionalDesignationWidth(w.toString());
    var l = additionalDesignations;

    totalAD.forEach((t) => {
      var i = l.indexOf(t);
      if (i > -1) {
        l.splice(i, 1);
      }
    });
    console.log(l);
    setMenuIndex(index);
    setAdditionalDesignations(l);
    setAdditionalDesignationAnchorEl(event.currentTarget);
  };

  const handleCloseAD = (lang = "", index) => {
    setAdditionalDesignationAnchorEl(null);
    if (lang !== "") {
      let tl = totalAD;

      tl[menuIndex] = lang;
      setTotalAD(tl);
      setAdditionalDesignations(initialAdditionalDesignations);
      setMenuIndex(-1);
    }
  };

  const handleClickLanguage = (event, index) => {
    var w = document.getElementById("language").clientWidth;
    console.log(w);
    setLanguageWidth(w.toString());
    var l = languages;
    console.log(languages);
    console.log(menuLanguage);
    console.log(totalLanguages);
    totalLanguages.forEach((t) => {
      var i = l.indexOf(t);
      if (i > -1) {
        l.splice(i, 1);
      }
    });
    console.log(l);
    setMenuIndex(index);
    setMenuLanguages(l);
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleCloseLanguage = (lang = "", index) => {
    setLanguageAnchorEl(null);
    if (lang !== "") {
      let tl = totalLanguages;
      console.log(index);
      tl[menuIndex] = lang;
      setTotalLanguages(tl);
      setLanguage(lang);
      setLanguages(initialLanguages);
      setMenuIndex(-1);
    }
  };

  const handleClickPrivateCountry = (event) => {
    var w = document.getElementById("privateCountry").clientWidth;
    console.log(w);
    setPrivateCountryWidth(w.toString());
    setAnchorEl(event.currentTarget);
  };

  const handleClosePrivateCountry = (country = "") => {
    setAnchorEl(null);
    if (country !== "") {
      setPrivateCountry(country);
    }
  };

  const handleFurtherTrainingChange = (event) => {
    setFurtherTraining(event.target.value);
  };

  const handleLicenseFor = (event) => {
    setLicenseFor(event.target.value);
  };

  const renderPractionerForm = () => {
    if (docId === "0") {
      return <GeneralPhysicianForm />;
    } else if (docId === "1") {
      return <PhychotherapyForm />;
    } else if (docId === "2") {
      return <MidWiveForm />;
    } else if (docId === "3") {
      return <PhysiotherapyForm />;
    } else if (docId === "4") {
      return <YogaForm />;
    } else if (docId === "5") {
      return <MeditationForm />;
    } else if (docId === "6") {
      return <CoachesForm />;
    } else if (docId === "7") {
      return <NaturopathForm />;
    }
  };

  useEffect(() => {
    updateOnboardingData({ category: doctorTypes[docId] });
    let approbationFileSelector = buildFileSelector();
    let insuranceFileSelector = buildFileSelector();
    approbationFileSelector.addEventListener("input", (event) => {
      let file = approbationFileSelector.files[0];

      setAppFile(file);
    });

    insuranceFileSelector.addEventListener("input", (event) => {
      let file = insuranceFileSelector.files[0];
      setInsFile(file);
    });

    setAppFileSelector(approbationFileSelector);
    setInsFileSelector(insuranceFileSelector);

    fetchSavedData();
    let interval = setInterval(() => {
      setAutoSaveCount((prevCount) => {
        if (prevCount === 0) {
          return 60;
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (autoSaveCount === 60) {
      saveForLater();
    }
  }, [autoSaveCount]);

  const fetchSavedData = async () => {
    let userId = user.id;
    let dataObj = localStorage.getItem(userId.toString());
    if (dataObj) {
      let data = JSON.parse(dataObj);
      console.log(data);
      const {
        private_address,
        business_address,
        languages,
        why_join_eterno,
        working_hours,
        careers,
        professional_data,
      } = data;

      //getting private address
      const { street, city, zip, country } = private_address;
      setPrivateStreet(street);
      setPrivateZip(zip);
      setPrivateCountry(country);
      setPrivateCity(city);

      //getting business address
      setBusinessCity(business_address.city);
      setBusinessCountry(business_address.country);
      setBusinessStreet(business_address.street);
      setBusinessZip(business_address.zip);

      //set languages
      setTotalLanguages(languages);
      setLanguageCount(languages.length);

      //why eterno
      setWhyEterno(why_join_eterno);
      setWorkingHours(working_hours);

      //careers
      setCareers(careers);
      setCareerCount(careers.length);

      //set professional data
      setProfessionalData(professional_data);
    }
  };

  const saveForLater = async () => {
    let userId = user.id;
    let data = {
      category: doctorTypes[docId],
      private_address: {
        street: privateStreet,
        city: privateCity,
        zip: privateZip,
        country: privateCountry,
      },
      business_address: {
        street: businessStreet,
        city: businessCity,
        zip: businessZip,
        country: businessCountry,
      },
      languages: totalLanguages,
      why_join_eterno: whyEterno,
      working_hours: workingHours,
      careers: careers,
      professional_data: professionalData,
    };
    console.log(data);
    localStorage.setItem(userId.toString(), JSON.stringify(data));
  };

  const submit = async () => {
    setLoading(true);
    let token = usersession.idToken.jwtToken;
    let data = {
      user_id: user.id,
      onboarding_data: {
        category: doctorTypes[docId],
        private_address: {
          street: privateStreet,
          city: privateCity,
          zip: privateZip,
          country: privateCountry,
        },
        business_address: {
          street: businessStreet,
          city: businessCity,
          zip: businessZip,
          country: businessCountry,
        },
        languages: totalLanguages,
        why_join_eterno: whyEterno,
        working_hours: workingHours,
        careers: careers,
        professional_data: professionalData,
      },
      data_files: {
        approbation_file: {
          upload: appFile !== null ? true : false,
          content_type: appFile !== null ? appFile.type : null,
        },
        liability_insurance_file: {
          upload: insFile !== null ? true : false,
          content_type: insFile !== null ? insFile.type : null,
        },
      },
    };
    console.log(data);

    const onBoardingResponse = await processAPIWithHeaders(
      "secure/user/onboarding-data",
      "POST",
      {
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    console.log(onBoardingResponse);

    if (appFile) {
      let signedUrl = onBoardingResponse.data_file_upload_urls.approbation_file;
      let options = {
        headers: {
          "Content-Type": appFile.type,
        },
      };

      const appFileResult = await axios.put(signedUrl, appFile, options);
      console.log(appFileResult);
    }

    if (insFile) {
      let signedUrl =
        onBoardingResponse.data_file_upload_urls.liability_insurance_file;
      let options = {
        headers: {
          "Content-Type": insFile.type,
        },
      };

      const insFileResult = await axios.put(signedUrl, appFile, options);
      console.log(insFileResult);
    }
    setOnboarding(true);
    setLoading(false);
    history.replace("/confirm-registration");
  };

  return (
    <React.Fragment>
      <div className={classes.contentContainer}>
        <div style={{ marginTop: 23 }}>
          <Typography className={classes.heading}>
            {t("WelcomeToEterno")} Maximilian
          </Typography>
        </div>

        <div style={{ marginTop: 23 }}>
          <Typography className={classes.subheading}>
            {t("StartSettingProfile")}
          </Typography>
        </div>

        <div style={{ marginTop: 10 }}>
          <Typography className={classes.subText}>
            {t("ChooseProfileField")}
          </Typography>
        </div>

        <div style={{ marginTop: 10 }}>
          <Typography className={classes.subText}>
            {t("RequiredFields")}
          </Typography>
        </div>

        {/* <div className={classes.cardContent}>
          <Typography className={classes.cardHeading}>
            {t("PrivateAddress")}*
          </Typography>

          <Grid container style={{ marginTop: 10 }} spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                id="privateStreet"
                label={t("StreetNumber")}
                variant="filled"
                color="primary"
                value={privateStreet}
                onChange={(event) => {
                  const text = event.target.value;
                  setPrivateStreet(text);
                }}
                fullWidth
                classes={{
                  root: classes.textFields,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.filledInputRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <TextField
                id="privateCity"
                label={t("City")}
                variant="filled"
                color="primary"
                value={privateCity}
                onChange={(event) => {
                  const text = event.target.value;
                  setPrivateCity(text);
                }}
                fullWidth
                classes={{
                  root: classes.textFields,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.filledInputRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: 10 }} spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                id="privateZip"
                label={t("Zip")}
                variant="filled"
                color="primary"
                value={privateZip}
                onChange={(event) => {
                  const text = event.target.value;
                  setPrivateZip(text);
                }}
                fullWidth
                classes={{
                  root: classes.textFields,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.filledInputRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Button
                aria-controls="privateCountry"
                aria-haspopup="true"
                onClick={handleClickPrivateCountry}
                id="privateCountry"
                disableRipple
                className={classes.formControl}
                classes={{ label: classes.menuLabel }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-Regular",
                      fontSize: "0.875rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      padding: 0,
                      marginLeft: 3,
                    }}
                  >
                    {t("Country")}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-SemiBold",
                      fontSize: "1rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      marginLeft: 3,
                      textAlign: "left",
                    }}
                  >
                    {privateCountry}
                  </Typography>
                </div>
                <img
                  src={ic_arrow_bottom}
                  alt="select"
                  className={classes.downArrow}
                />
              </Button>

              <Menu
                id="privateCountry"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                  handleClosePrivateCountry();
                }}
                //className={classes.customWidth}
                // classes={{
                //   paper: classes.dropDownMenuPopup,
                // }}
                PaperProps={{
                  style: {
                    width: parseFloat(privateCountryWidth),
                    backgroundColor: "white",
                    paddingTop: 10,

                    border: `2px solid ${theme.palette.common.main}`,
                    height: 400,
                  },
                }}
              >
                <div
                  onClick={() => {
                    handleClosePrivateCountry();
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: 15,
                    justifyContent: "space-between",
                    paddingRight: 15,
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-Regular",
                        fontSize: "0.875rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        padding: 0,
                      }}
                    >
                      {t("Country")}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                      {privateCountry}
                    </Typography>
                  </div>
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                </div>

                {country_list.map((c) => (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleClosePrivateCountry(c)}
                  >
                    {c}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
          {error.privateAddress && (
            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
              {t("AllFieldsRequiredInSection")}
            </Typography>
          )}
        </div>

        <div className={classes.cardContent}>
          <Typography className={classes.cardHeading}>
            {t("BusinessAddress")}
          </Typography>

          <Grid container style={{ marginTop: 10 }} spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                id="businessStreet"
                label={t("StreetNumber")}
                variant="filled"
                color="primary"
                value={businessStreet}
                onChange={(event) => {
                  const text = event.target.value;
                  setBusinessStreet(text);
                }}
                fullWidth
                classes={{
                  root: classes.textFields,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.filledInputRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <TextField
                id="businessCity"
                label={t("City")}
                variant="filled"
                color="primary"
                value={businessCity}
                onChange={(event) => {
                  const text = event.target.value;
                  setBusinessCity(text);
                }}
                fullWidth
                classes={{
                  root: classes.textFields,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.filledInputRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: 10 }} spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                id="businessZip"
                label={t("Zip")}
                variant="filled"
                color="primary"
                value={businessZip}
                onChange={(event) => {
                  const text = event.target.value;
                  setBusinessZip(text);
                }}
                fullWidth
                classes={{
                  root: classes.textFields,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.filledInputRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Button
                aria-controls="businessCountry"
                aria-haspopup="true"
                onClick={handleClickBusinessCountry}
                id="businessCountry"
                // onClick={() => {
                //   if (i18next.language === "en") {
                //     i18next.changeLanguage("de", (err, t) => {
                //       if (err)
                //         return console.log("something went wrong loading", err);
                //     });
                //   } else {
                //     i18next.changeLanguage("en", (err, t) => {
                //       if (err)
                //         return console.log("something went wrong loading", err);
                //     });
                //   }
                // }}
                disableRipple
                className={classes.formControl}
                classes={{ label: classes.menuLabel }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-Regular",
                      fontSize: "0.875rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      padding: 0,
                      marginLeft: 3,
                    }}
                  >
                    {t("Country")}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-SemiBold",
                      fontSize: "1rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      marginLeft: 3,
                      textAlign: "left",
                    }}
                  >
                    {businessCountry}
                  </Typography>
                </div>
                <img
                  src={ic_arrow_bottom}
                  alt="select"
                  className={classes.downArrow}
                />
              </Button>

              <Menu
                id="businessCountry"
                anchorEl={businessAnchorEl}
                open={Boolean(businessAnchorEl)}
                onClose={() => {
                  handleCloseBusinessCountry();
                }}
                //className={classes.customWidth}
                // classes={{
                //   paper: classes.dropDownMenuPopup,
                // }}
                PaperProps={{
                  style: {
                    width: parseFloat(businessCountryWidth),
                    backgroundColor: "white",
                    paddingTop: 10,

                    border: `2px solid ${theme.palette.common.main}`,
                    height: 400,
                  },
                }}
              >
                <div
                  onClick={() => {
                    handleCloseBusinessCountry();
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: 15,
                    justifyContent: "space-between",
                    paddingRight: 15,
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-Regular",
                        fontSize: "0.875rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        padding: 0,
                      }}
                    >
                      {t("Country")}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                      {businessCountry}
                    </Typography>
                  </div>
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                </div>

                {country_list.map((c) => (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseBusinessCountry(c)}
                  >
                    {c}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </div> */}

        {/* <div className={classes.cardContent}>
          <Typography className={classes.cardHeading}>
            {t("LanguagesYouSpeak")}*
          </Typography>
          {totalLanguages.map((lang, index) => (
            <Grid
              container
              style={{ marginTop: index === 0 ? 10 : 0 }}
              spacing={2}
              key={index}
            >
              <Grid item md={4} sm={6} xs={12}>
                <Button
                  aria-controls="language"
                  aria-haspopup="true"
                  onClick={(event) => handleClickLanguage(event, index)}
                  id="language"
                  // onClick={() => {
                  //   if (i18next.language === "en") {
                  //     i18next.changeLanguage("de", (err, t) => {
                  //       if (err)
                  //         return console.log("something went wrong loading", err);
                  //     });
                  //   } else {
                  //     i18next.changeLanguage("en", (err, t) => {
                  //       if (err)
                  //         return console.log("something went wrong loading", err);
                  //     });
                  //   }
                  // }}
                  disableRipple
                  className={classes.formControl}
                  classes={{ label: classes.menuLabel }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-Regular",
                        fontSize: "0.875rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        padding: 0,
                        marginLeft: 3,
                      }}
                    >
                      {t("SelectLanguage")}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: 3,
                        textAlign: "left",
                      }}
                    >
                      {lang}
                    </Typography>
                  </div>
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                </Button>

                <Menu
                  id="language"
                  anchorEl={languageAnchorEl}
                  open={Boolean(languageAnchorEl)}
                  onClose={() => {
                    handleCloseLanguage();
                  }}
                  //className={classes.customWidth}
                  // classes={{
                  //   paper: classes.dropDownMenuPopup,
                  // }}
                  PaperProps={{
                    style: {
                      width: parseFloat(languageWidth),
                      backgroundColor: "white",
                      paddingTop: 10,

                      border: `2px solid ${theme.palette.common.main}`,
                      maxHeight: 400,
                    },
                  }}
                >
                  <div
                    onClick={() => {
                      handleCloseLanguage();
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: 15,
                      justifyContent: "space-between",
                      paddingRight: 15,
                      marginBottom: 10,
                    }}
                  >
                    <div>
                      <Typography
                        style={{
                          fontFamily: "MessinaSans-Regular",
                          fontSize: "0.875rem",
                          color: theme.palette.common.darkGrey,
                          textTransform: "none",
                          padding: 0,
                        }}
                      >
                        {t("SelectLanguage")}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "MessinaSans-SemiBold",
                          fontSize: "1rem",
                          color: theme.palette.common.darkGrey,
                          textTransform: "none",
                          marginLeft: i18next.language === "en" ? 0 : 0,
                        }}
                      >
                        {totalLanguages[menuIndex]}
                      </Typography>
                    </div>
                    <img
                      src={ic_arrow_bottom}
                      alt="select"
                      className={classes.downArrow}
                    />
                  </div>

                  {menuLanguage.map((l, ind) => (
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => handleCloseLanguage(l, index)}
                    >
                      {l}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
              {index === languageCount - 1 && (
                <Grid item md={4} sm={6} xs={12}>
                  <UnderlinedButton
                    text={t("AddAnotherLanguage")}
                    noMarginTop={true}
                    marginTop={matches ? 0 : 20}
                    onPress={() => {
                      var tl = totalLanguages;
                      tl.push("-");
                      console.log(tl);
                      setTotalLanguages(tl);
                      setLanguageCount(languageCount + 1);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          ))}
        </div> */}

        <div className={classes.cardContent}>
          <Typography className={classes.cardHeading}>
            {t("WhyEterno")}*
          </Typography>

          <Grid container style={{ marginTop: 10 }} spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <TextField
                id="whyeterno"
                variant="filled"
                placeholder={t("WriteText")}
                color="primary"
                value={whyEterno}
                onChange={(event) => {
                  const text = event.target.value;
                  setWhyEterno(text);
                }}
                fullWidth
                multiline
                rows={5}
                classes={{
                  root: classes.textArea,
                }}
                // InputLabelProps={{
                //   classes: {
                //     root: classes.inputLabel,
                //     focused: "focused",
                //     shrink: "shrink",
                //   },
                // }}
                InputProps={{
                  classes: {
                    root: classes.textAreaRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>
          </Grid>
          {error.whyEterno && (
            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
              {t("RequiredField")}
            </Typography>
          )}
        </div>

        {renderPractionerForm()}

        {/* <div className={classes.cardContent}>
            <Typography className={classes.cardHeading}>
              {t("PlannedWorkingHours")}*
            </Typography>

            <Grid container style={{ marginTop: 10 }} spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue={workingHours}
                    value={workingHours}
                    aria-label="gender"
                    name="customized-radios"
                    onChange={(event, value) => {
                      setWorkingHours(value);
                    }}
                  >
                    <FormControlLabel
                      classes={{ root: classes.radioLabel }}
                      value="ten"
                      control={<StyledRadio />}
                      label={t("TenHours")}
                    />
                    <FormControlLabel
                      classes={{ root: classes.radioLabel }}
                      value="twenty"
                      control={<StyledRadio />}
                      label={t("TwentyHours")}
                    />
                    <FormControlLabel
                      classes={{ root: classes.radioLabel }}
                      value="thirty"
                      control={<StyledRadio />}
                      label={t("ThirtyHours")}
                    />
                    <FormControlLabel
                      classes={{ root: classes.radioLabel }}
                      value="over thirty"
                      control={<StyledRadio />}
                      label={t("OverThirty")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {error.workingHours && (
              <Typography className={classes.errorText} style={{ marginTop: 3 }}>
                {t("RequiredField")}
              </Typography>
            )}
          </div> */}

        {/* <div className={classes.cardContent}>
          <Typography className={classes.cardHeading}>
            {t("Career")}*
          </Typography>
          {careers.map((careerData, index) => (
            <Grid container style={{ marginTop: 10 }} spacing={2}>
              <Grid item md={2} sm={3} xs={12}>
                <TextField
                  id="fromdate"
                  label={t("From")}
                  variant="filled"
                  color="primary"
                  placeholder="DD/MM/YYYY"
                  value={careerData.from}
                  onChange={(event) => {
                    let text = event.target.value;
                    let d = careers[index];

                    let oldDate = d["from"];
                    let newDate = "";
                    if (
                      oldDate.length > text.length &&
                      (text.length === 5 || text.length === 2)
                    ) {
                      newDate = text.slice(0, text.length - 1);
                    }
                    if (
                      oldDate.length < text.length &&
                      (text.length === 2 || text.length === 5)
                    ) {
                      text += "/";
                    }
                    if (text.length === 11) {
                      return;
                    }
                    d["from"] = text;
                    if (newDate !== "") {
                      d["from"] = newDate;
                    }
                    careers[index] = d;
                    setCareers(careers);
                    setFromDate(text);
                  }}
                  fullWidth
                  classes={{
                    root: classes.textFields,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                      focused: "focused",
                      shrink: "shrink",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.filledInputRoot,
                      focused: classes.fieldFocused,
                      shrink: "shrink",
                    },
                  }}
                />
              </Grid>

              <Grid item md={2} sm={3} xs={12}>
                <TextField
                  id="todate"
                  label={t("To")}
                  variant="filled"
                  color="primary"
                  placeholder="DD/MM/YYYY"
                  value={careerData.to}
                  onChange={(event) => {
                    let text = event.target.value;
                    let d = careers[index];
                    let oldDate = d["to"];
                    let newDate = "";
                    if (
                      oldDate.length > text.length &&
                      (text.length === 5 || text.length === 2)
                    ) {
                      newDate = text.slice(0, text.length - 1);
                    }
                    if (
                      oldDate.length < text.length &&
                      (text.length === 2 || text.length === 5)
                    ) {
                      text += "/";
                    }
                    if (text.length === 11) {
                      return;
                    }
                    d["to"] = text;
                    if (newDate !== "") {
                      d["to"] = newDate;
                    }
                    careers[index] = d;
                    setCareers(careers);
                    setToDate(text);
                  }}
                  fullWidth
                  classes={{
                    root: classes.textFields,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                      focused: "focused",
                      shrink: "shrink",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.filledInputRoot,
                      focused: classes.fieldFocused,
                      shrink: "shrink",
                    },
                  }}
                />
              </Grid>

              <Grid item md={8} sm={6} xs={12}>
                <TextField
                  id="employeer"
                  label={t("Employeer")}
                  variant="filled"
                  color="primary"
                  value={careerData.employer}
                  onChange={(event) => {
                    const text = event.target.value;
                    let d = careers[index];
                    d["employer"] = text;
                    careers[index] = d;
                    setCareers(careers);
                    setEmployeer(text);
                  }}
                  fullWidth
                  classes={{
                    root: classes.textFields,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                      focused: "focused",
                      shrink: "shrink",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.filledInputRoot,
                      focused: classes.fieldFocused,
                      shrink: "shrink",
                    },
                  }}
                />
              </Grid>
            </Grid>
          ))}
          <UnderlinedButton
            text={t("AnotherCareer")}
            onPress={() => {
              var c = careers;
              c.push({
                from: "",
                to: "",
                employer: "",
              });
              setCareers(c);
              setCareerCount(careerCount + 1);
            }}
          />

          {error.careers && (
            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
              {t("AllFieldsRequiredInSection")}
            </Typography>
          )}

          {!error.careers && !error.validDate && (
            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
              {t("EnterValidDate")}
            </Typography>
          )}
        </div> */}

        {/* <div className={classes.cardContent}>
          <Typography className={classes.cardHeading}>
            {t("RequiredDocuments")}
          </Typography>

          <Grid container style={{ marginTop: 10 }} spacing={2}>
            <Grid item md={4} sm={12} xs={12}>
              <div
                style={{
                  height: "3.75em",

                  alignItems: "center",

                  display: "flex",
                }}
              >
                <Typography className={classes.contentText}>
                  {t("Approbationsurkunde")}
                </Typography>
              </div>
            </Grid>

            <Grid item md={8} sm={12} xs={12}>
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <FilledButton
                  text={t("Upload")}
                  onPress={() => {
                    appFileSelector.click();
                  }}
                />

                {appFile && (
                  <div
                    style={{
                      height: "3.75em",

                      alignItems: "center",
                      marginLeft: 10,
                      display: "flex",
                    }}
                  >
                    <Typography className={classes.contentText}>
                      {appFile.name}
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: 10 }} spacing={2}>
            <Grid item md={4} sm={12} xs={12}>
              <div
                style={{
                  height: "3.75em",

                  alignItems: "center",

                  display: "flex",
                }}
              >
                <Typography className={classes.contentText}>
                  {t("Professional Liability Insurance")}
                </Typography>
              </div>
            </Grid>

            <Grid item md={8} sm={12} xs={12}>
              <div
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <FilledButton
                  text={t("Upload")}
                  onPress={() => {
                    appFileSelector.click();
                  }}
                />
                {insFile && (
                  <div
                    style={{
                      height: "3.75em",

                      alignItems: "center",
                      marginLeft: 10,
                      display: "flex",
                    }}
                  >
                    <Typography className={classes.contentText}>
                      {insFile.name}
                    </Typography>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </div> */}

        <Grid container style={{ marginTop: 23 }} spacing={2}>
          <Grid item lg={7} md={3} sm={12} xs={12}>
            <UnderlinedButton
              text={t("Back")}
              noMarginTop={true}
              onPress={() => {
                returningFromForm(true);
                setTimeout(() => {
                  history.replace("/doctor-type");
                }, 1000);
              }}
            />
          </Grid>

          <Grid
            item
            lg={3}
            md={6}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: matchesSm ? "flex-start" : "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: matchesSm ? "flex-start" : "center",
                alignItems: matchesSm ? "flex-start" : "center",
              }}
            >
              <OutlinedButton
                text={t("SaveForLater")}
                onPress={() => {
                  saveForLater();
                }}
              />
              <Typography
                className={classes.contentText}
                style={{ fontSize: 12 }}
              >
                Autosave in {autoSaveCount} seconds.
              </Typography>
            </div>
          </Grid>

          <Grid item lg={2} md={3} sm={12} xs={12}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: matchesSm ? "flex-start" : "flex-end",
                alignItems: matchesSm ? "flex-start" : "flex-end",
              }}
            >
              <FilledButton
                text={t("Continue")}
                loading={loading}
                // component={Link}
                // to="/confirm-registration"
                onPress={() => {
                  resetErrorData();
                  // updateOnboardingData({
                  //   private_address: {
                  //     street: privateStreet,
                  //     city: privateCity,
                  //     zip: privateZip,
                  //     country: privateCountry,
                  //   },
                  //   business_address: {
                  //     street: businessStreet,
                  //     city: businessCity,
                  //     zip: businessZip,
                  //     country: businessCountry,
                  //   },
                  //   languages: totalLanguages,
                  //   why_join_eterno: whyEterno,
                  //   working_hours: workingHours,
                  //   careers: careers,
                  //   professional_data: professionalData,
                  // });
                  let localError = {
                    privateAddress: false,
                    whyEterno: false,
                    workingHours: false,
                    careers: false,
                    validDate: true,
                  };
                  if (
                    privateCity === "" ||
                    privateCountry === "" ||
                    privateStreet === "" ||
                    privateZip === ""
                  ) {
                    localError["privateAddress"] = true;
                  }

                  if (whyEterno === "") {
                    localError["whyEterno"] = true;
                  }

                  if (workingHours === "") {
                    localError["workingHours"] = true;
                  }

                  if (
                    careers[0].from === "" ||
                    careers[0].to === "" ||
                    careers[0].employer === ""
                  ) {
                    localError["careers"] = true;
                  }

                  let validDate = true;

                  careers.forEach((career) => {
                    validDate = validateDate(
                      career.from,
                      "boolean",
                      "dd/mm/yyyy"
                    );

                    validDate = validateDate(
                      career.to,
                      "boolean",
                      "dd/mm/yyyy"
                    );
                  });

                  localError["validDate"] = validDate;
                  setError(localError);

                  console.log(professionalData);
                  let err = validProfessionalData(
                    professionalData,
                    parseInt(docId)
                  );
                  if (err.error) {
                    updateErrorData(err);
                  } else {
                    submit();
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
