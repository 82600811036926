import React, { useContext } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";

import Header from "./Header";
import Footer from "./Footer";
import Registration from "../pages/Registration";
import Login from "../pages/Login";
import DoctorType from "../pages/DoctorType";
import RegistrationForm from "../pages/RegistrationForm";
import ConfirmRegistration from "../pages/ConfirmRegistration";
import Dashboard from "../pages/Dashboard";
import { Context as AuthContext } from "../context/AuthContext";
import { processAPIWithHeaders } from "../utils/helpers";
import TermsConditions from "../pages/TermsConditions";
import Privacy from "../pages/Privacy";
import Imprint from "../pages/Imprint";

function Router() {
  const {
    state: { user, onBoarding },
    setuser,
    setusersession,
    setOnboarding,
  } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log(user);
      const username = user.username;
      const token = user.signInUserSession.idToken.jwtToken;

      const response = await processAPIWithHeaders(
        "secure/users/" + username,
        "GET",
        {
          headers: {
            //"Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      console.log(response);

      const onboardingResponse = await processAPIWithHeaders(
        "secure/user/onboarding-data/" + username,
        "GET",
        {
          headers: {
            //"Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      setOnboarding(onboardingResponse.has_data);

      setuser(response);
      setusersession(user.signInUserSession);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) => {
          if (user) {
            if (onBoarding) {
              if (
                !location.pathname.includes("/doctor-type") &&
                !location.pathname.includes("/doctor-registration-form")
              ) {
                return children;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/confirm-registration",
                      state: { from: location },
                    }}
                  />
                );
              }
            } else {
              if (
                !location.pathname.includes("/doctor-type") &&
                !location.pathname.includes("/doctor-registration-form")
              ) {
                return (
                  <Redirect
                    to={{
                      pathname: "/doctor-type",
                      state: { from: location },
                    }}
                  />
                );
              } else {
                return children;
              }
            }
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            );
          }
        }}
      />
    );
  };

  if (loading) {
    return (
      <div style={{ height: 800 }}>
        <h3></h3>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Header />

      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return user ? (
              <Redirect to="/confirm-registration" />
            ) : (
              <Registration />
            );
          }}
        />
        <Route
          exact
          path="/login"
          render={() => {
            return user ? <Redirect to="/confirm-registration" /> : <Login />;
          }}
        />
        <Route exact path="/privacy" component={() => <Privacy />} />
        <Route exact path="/terms" component={() => <TermsConditions />} />
        <Route exact path="/imprint" component={() => <Imprint />} />
        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/doctor-type">
          <DoctorType />
        </PrivateRoute>
        <PrivateRoute path="/doctor-registration-form/:docId">
          <RegistrationForm />
        </PrivateRoute>
        <PrivateRoute path="/confirm-registration">
          <ConfirmRegistration />
        </PrivateRoute>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default Router;
