import createDataContext from "./createDataContext";

const onboardingReducer = (state, action) => {
  switch (action.type) {
    case "update_onboarding_data":
      let data = state.onboardingData;
      let payload = action.payload;
      data = { ...data, ...payload };
      console.log(data);
      return { ...state, onboardingData: data };
    case "update_professional_data":
      let data1 = state.professionalData;
      let payload1 = action.payload;
      data1 = { ...data1, ...payload1 };

      return { ...state, professionalData: data1 };
    case "set_professional_data":
      let payloadData = action.payload;

      return { ...state, professionalData: payloadData };
    case "update_error_data":
      return { ...state, errorData: action.payload };
    case "reset_professional_data":
      return { ...state, professionalData: {} };
    case "reset_error_data":
      return { ...state, errorData: {} };
    case "returning_from_form":
      return { ...state, isReturningFromForm: action.payload };
    default:
      return state;
  }
};

const updateOnboardingData = (dispatch) => async (data) => {
  dispatch({ type: "update_onboarding_data", payload: data });
};

const updateProfessionalData = (dispatch) => async (data) => {
  dispatch({ type: "update_professional_data", payload: data });
};

const setProfessionalData = (dispatch) => async (data) => {
  dispatch({ type: "set_professional_data", payload: data });
};

const updateErrorData = (dispatch) => async (data) => {
  dispatch({ type: "update_error_data", payload: data });
};
const returningFromForm = (dispatch) => async (data) => {
  dispatch({ type: "returning_from_form", payload: data });
};

const resetProfessionalData = (dispatch) => async () => {
  dispatch({ type: "reset_professional_data" });
};

const resetErrorData = (dispatch) => async () => {
  dispatch({ type: "reset_error_data" });
};

export const { Provider, Context } = createDataContext(
  onboardingReducer,
  {
    updateOnboardingData,
    updateProfessionalData,
    resetProfessionalData,
    setProfessionalData,
    updateErrorData,
    resetErrorData,
    returningFromForm,
  },
  {
    onboardingData: {},
    professionalData: {},
    errorData: {},
    isReturningFromForm: false,
  }
);
