import React, { useContext, useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import placeholderIcon from "../assets/images/placeholder.png";
import ic_arrow_bottom from "../assets/Icons/ic_arrow_bottom.svg";
import ic_check from "../assets/Icons/ic_check.svg";
import uncheck from "../assets/Icons/uncheck.svg";
import check from "../assets/Icons/check.svg";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import i18next from "i18next";

import { Context as AuthContext } from "../context/AuthContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import DialogBox from "../components/Common/DialogBox";
import isMatch from "date-fns/isMatch";

import {
  processAPI,
  getISODate,
  processAPIWithHeaders,
} from "../utils/helpers";

import usePrismicRegistration from "../hooks/usePrismicRegistration";

const gendersDict = {
  "-": "",
  Male: "MALE",
  Female: "FEMALE",
  Other: "OTHER",
};

const titlesDict = {
  "-": "",
  "Dr.": "DR",
  "Prof.": "PROF",
  "Prof. Dr.": "PROF_DR",
};

const languageMap = {
  en: "en-us",
  de: "de-de",
};

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    "&.focused": {
      color: theme.palette.common.darkGrey,
    },
    "&.shrink": {
      fontSize: "1rem",
    },
  },

  contentContainer: {
    maxWidth: theme.layout.contentWidth,

    margin: "0 auto",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
    },
  },
  placeholder: {
    backgroundColor: "blue",
    height: 100,
    width: "100%",
  },

  mainContent: {
    width: "100%",
    height: 220,
    backgroundColor: "#d8d8d8",
    borderRadius: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    ...theme.typography.HL2,
  },

  contentText: {
    ...theme.typography.content,
  },
  checkMark: {
    width: "0.875em",
    height: "0.875em",
    marginTop: 2,
  },
  icon: {
    width: "3.75em",
    height: "3.75em",
  },
  registrationForm: {
    backgroundColor: "white",

    width: "100%",
    boxShadow: "5px 10px 40px rgba(120, 120, 120, 0.22)",
    borderRadius: 8,
  },
  registrationFormContent: {
    marginLeft: 50,
    marginRight: 50,
    paddingTop: 43,
    paddingBottom: 43,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
      marginRight: 30,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 23,
      paddingBottom: 23,
    },
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  downArrow: {
    height: "0.75em",
  },
  dropdownFilled: {
    backgroundColor: theme.palette.common.lightBrown,
  },
  form: {
    marginTop: 23,
  },
  textFields: {
    height: "3.75em",
  },
  textFieldsError: {
    height: "3.75em",
    boxShadow: "0 0 0 2px #da3030",
    borderRadius: 5,
  },
  dropDownContainer: {
    display: "flex",
    flexDirection: "row",
  },
  dropDownRoot: {
    "&:hover": {
      backgroundColor: theme.palette.common.lightBrown,
    },
  },
  blackCircle: {
    backgroundColor: theme.palette.common.darkGrey,
    width: 7,
    height: 7,
    borderRadius: 5,
    marginRight: 5,
  },
  greenCircle: {
    backgroundColor: "#5ba668",
    width: 7,
    height: 7,
    borderRadius: 5,
    marginRight: 5,
  },
  passwordText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
  },
  passwordValidText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.brown,
  },
  submitButton: {
    height: "3.75em",
    backgroundColor: theme.palette.common.main,
    marginTop: 18,
    borderRadius: "2em",
    "&:hover": {
      backgroundColor: theme.palette.common.main,
    },
  },
  disabledButton: {
    backgroundColor: theme.palette.common.main,
  },
  buttonText: {
    ...theme.typography.buttonText,
  },
  subheading: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
  },
  alreadyMember: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
    width: "100%",
    textAlign: "center",
    textDecorationLine: "underline",
    textDecorationColor: theme.palette.common.darkGrey,
  },
  check: {
    widht: 30,
    height: 30,
  },
  contentTextLink: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",

    lineHeight: "1.5rem",
    textTransform: "none",
    textDecorationLine: "underline",
    textDecorationColor: theme.palette.common.main,
    textDecorationThickness: 2,
    color: theme.palette.common.darkGrey,
    textUnderlineOffset: "0.25em",
  },
  underlineText: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",

    lineHeight: "1.5rem",
    textTransform: "uppercase",
    textDecorationLine: "underline",
    textDecorationColor: theme.palette.common.main,
    textDecorationThickness: 2,
    color: theme.palette.common.darkGrey,
    width: "100%",
    textAlign: "center",
    textUnderlineOffset: "0.25em",
  },

  dropdownStyle: {
    backgroundColor: theme.palette.common.lightBrown,
    marginTop: 50,
  },
  errorText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.red,
  },
  fieldFocused: {
    backgroundColor: "white",
    border: "2px solid #4C726D",
  },
  filledInputRoot: {
    overflow: "hidden",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingRight: 10,
    borderRadius: 5,
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    height: "100%",
    "&:hover": {
      backgroundColor: "#F9F8F4",
      //backgroundColor: "red",
    },
  },
  dropDownBackground: {
    backgroundColor: theme.palette.common.lightBrown,
  },
  downArrow: {
    height: "0.75em",
  },
  formControl: {
    width: "100%",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingTop: 14,
    paddingBottom: 14,
    height: "4.3em",
    marginBottom: 15,
    justifyContent: "space-between",
    // [theme.breakpoints.down("md")]: {
    //   width: 150,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: 100,
    // },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#F9F8F4",
    },
  },
  customWidth: {
    "& div": {
      // this is just an example, you can use vw, etc.
      width: "100%",
    },
  },
  dropDownMenuPopup: {
    //width: 200,
    backgroundColor: theme.palette.common.lightBrown,
    paddingTop: 10,

    border: "2px solid #ffce2d",
    // [theme.breakpoints.down("md")]: {
    //   width: 150,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: 100,
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "82%",
    // },
  },
  menuItem: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    textTransform: "none",
  },
}));

export default function Registration() {
  // React.useEffect(() => {
  //   history.replace("/dashboard");
  // }, []);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();

  const classes = useStyles();

  const [gender, setGender] = React.useState("-");
  const [title, setTitle] = React.useState("-");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [dob, setDOB] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [checked, setchecked] = React.useState(false);
  const [passwordValid, setPasswordValid] = React.useState({
    characters: false,
    number: false,
    special: false,
    password: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const { setuser, setusersession, setOnboarding } = useContext(AuthContext);
  const [code, setCode] = React.useState("");
  const [localLoading, setLocalLoading] = React.useState(false);
  const [errFirstName, setErrFirstName] = React.useState("");
  const [errLastName, setErrLastName] = React.useState("");
  const [errEmail, setErrEmail] = React.useState("");
  const [errDOB, setErrDOB] = React.useState("");
  const [errPassword, setErrPassword] = React.useState("");
  const [errConfirmPassword, setErrConfirmPassword] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [genderWidth, setGenderWidth] = useState("0");

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState({
    title: "Invalid Code",
    text: "Please enter correct code",
  });

  const [content, setContent] = useState({
    "en-us": {},
    "de-de": {},
  });

  const [benefits, setBenefits] = useState({
    "en-us": [],
    "de-de": [],
  });
  const [fieldNames, setFieldNames] = useState({
    "en-us": [],
    "de-de": [],
  });

  const [dataSet, setDataSet] = useState(false);

  const { prismicData, isPremismicLoading, prismicError } =
    usePrismicRegistration();

  useEffect(() => {
    if (prismicData) {
      console.log("prismicData",prismicData);
      setBenefits({
        "en-us": [],
        "de-de": [],
      });
      let results = prismicData.results;
      let c = content;
      let localBenefits = benefits;
      let localFields = fieldNames;
      results.map((result) => {
        if (
          result.type === "registration" &&
          result.data.application === "Practitioners"
        ) {
          c[result.lang] = result.data;
          let langBenefitData = localBenefits[result.lang];
          console.log(" langBenefits ,", langBenefitData);
          let langBenefits = result.data.benefits_group;
          if (langBenefitData.length !== 4) {
            langBenefits.map((benefit) => {
              let d = results.filter(
                (results) => results.id == benefit.benefit.id
              );
              langBenefitData.push(d[0]);
            });
            localBenefits[result.lang] = langBenefitData;
          }
          localFields[result.lang] = result.data;
        }
      });
      setContent(c);
      setBenefits(localBenefits);
      setFieldNames(localFields);
      console.log("field Names ", fieldNames);
      setDataSet(true);
    }
  }, [prismicData]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const handleClick2 = (event) => {
    var w = document.getElementById("genderButton").clientWidth;
    console.log(w);
    setGenderWidth(w.toString());
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = (title = "") => {
    setAnchorEl2(null);
    if (title !== "") {
      setTitle(title);
    }
  };

  const handleClick = (event) => {
    var w = document.getElementById("genderButton").clientWidth;
    console.log(w);
    setGenderWidth(w.toString());
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (gender = "") => {
    setAnchorEl(null);
    if (gender !== "") {
      setGender(gender);
    }
  };
  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleTitleGender = (event) => {
    setTitle(event.target.value);
  };

  const hasNumber = (myString) => {
    return /\d/.test(myString);
  };

  const hasSpecial = (myString) => {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(myString);
  };

  const emailExists = async () => {
    Auth.signIn(email, "123")
      .then(() => {
        setErrEmail("");
      })
      .catch((error) => {
        const code = error.code;
        switch (code) {
          case "NotAuthorizedException":
            setErrEmail( dataSet? mainDataFields.used_email[0].text :t("EmailAlreadyExists"));
            break;
          default:
            break;
        }
      });
  };

  const validateForm = (type) => {
    switch (type) {
      case "firstName":
        setErrFirstName("");
        if (firstName === "") {
          setErrFirstName(t("RequiredField"));
        }
        break;
      case "lastName":
        setErrLastName("");
        if (lastName === "") {
          setErrLastName(t("RequiredField"));
        }
        break;
      case "dob":
        setErrDOB("");
        if (dob === "") {
          setErrDOB(t("RequiredField"));
        } else {
          if (!isMatch(dob, "dd/MM/yyyy")) {
            setErrDOB(t("InvalidDate"));
          }
        }
        break;

      case "confirmPassword":
        setErrConfirmPassword("");
        if (confirmPassword === "") {
          setErrConfirmPassword(dataSet ? mainDataFields.required_field_error_message[0].text :t("RequiredField"));
        } else {
          if (confirmPassword !== passwordValid.password) {
            setErrConfirmPassword(dataSet ? mainDataFields.same_password[0].text : t("PasswordsNotMatch"));
          }
        }
        break;
      case "email":
        setErrEmail("");
        if (email === "") {
          setErrEmail(dataSet ? mainDataFields.required_field_error_message[0].text :t("RequiredField"));
        } else {
          let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          if (!re.test(email)) {
            setErrEmail(dataSet ? mainDataFields.invalid_email_error_message[0].text : t("InvalidEmail"));
          } else {
            emailExists();
          }
        }
        break;

      default:
        break;
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrEmail("");
    setErrFirstName("");
    setErrLastName("");
    setErrDOB("");
    setErrPassword("");
    setErrConfirmPassword("");
    if (user) {
      if (code.length !== 6) {
        setError({
          title: "Error verifying",
          text: error.message,
        });
        setOpen(true);
        return;
      }
      verifyCode();
    } else {
      let err = false;
      if (email === "") {
        setErrEmail(dataSet ? mainDataFields.required_field_error_message[0].text :t("RequiredField"));
        err = true;
      } else {
        var reg =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!reg.test(email)) {
          setErrEmail(dataSet ? mainDataFields.invalid_email_error_message[0].text : t("InvalidEmail"));
          err = true;
        }
      }

      if (firstName === "") {
        setErrFirstName(t("RequiredField"));
        err = true;
      }

      if (lastName === "") {
        setErrLastName(t("RequiredField"));
        err = true;
      }
      if (dob === "") {
        setErrDOB(t("RequiredField"));
        err = true;
      }
      if (confirmPassword === "") {
        setErrConfirmPassword(t("RequiredField"));
        err = true;
      }
      if (passwordValid.password === "") {
        setErrPassword(t("RequiredField"));
        err = true;
      }
      if (gender === "" || title === "") {
        setError({
          title: "Incomplete details",
          text: "Please enter all details",
        });
        setOpen(true);
        return;
      }

      if (err) {
        return;
      }

      if (passwordValid.password !== confirmPassword) {
        setError({
          title: "Password do not match",
          text: "Both passwords should be same",
        });
        setOpen(true);
        return;
      }

      if (
        !passwordValid.characters ||
        !passwordValid.number ||
        !passwordValid.special
      ) {
        setError({
          title: "Weak password",
          text: "Please choose a strong password.",
        });
        setOpen(true);
        return;
      }

      if (!checked) {
        setError({
          title: "Privay Policy & Terms",
          text: "Please agree to Privacy Policy and Terms & Conditions",
        });
        setOpen(true);
        return;
      }
      signUp();
    }
  };

  async function verifyCode() {
    console.log("verifying");
    try {
      setLocalLoading(true);
      const result = await Auth.confirmSignUp(user.username, code);
      // console.log(result);
      signIn();
    } catch (err) {
      setLocalLoading(false);
      setError({
        title: "Error verifying",
        text: err.message,
      });
      setOpen(true);
      console.log("error verifying:", error);
    }
  }
  async function signIn() {
    console.log("signing in");
    try {
      const user = await Auth.signIn(email, passwordValid.password);
      console.log(user);
      const userData = user.attributes;

      const response = await processAPIWithHeaders("secure/users", "POST", {
        body: {
          id: user.username,
          email: userData.email,
          name: {
            first: userData["custom:firstName"],
            last: userData["custom:lastName"],
          },
          gender: userData["custom:gender"],
          date_of_birth: userData["custom:dob"],
          title: userData["custom:title"],
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      });
      console.log(response);
      setOnboarding(false);
      setuser(response);
      setusersession(user.signInUserSession);
      setLocalLoading(false);
      history.replace("/doctor-type");
    } catch (err) {
      setLocalLoading(false);
      setError({
        title: "Error signing in",
        text: err.message,
      });
      setOpen(true);

      console.log("error signing in", error);
    }
  }

  async function signUp() {
    console.log("signing up");
    try {
      setLocalLoading(true);
      const { user } = await Auth.signUp({
        username: email,
        password: passwordValid.password,
        attributes: {
          email: email, // optional
          "custom:firstName": firstName,
          "custom:lastName": lastName,
          "custom:gender": gendersDict[gender],
          "custom:title": titlesDict[title],
          "custom:dob": getISODate(dob),
        },
      });
      console.log(user);
      setUser(user);
      setLocalLoading(false);
    } catch (err) {
      setLocalLoading(false);
      setError({
        title: "Error signing up",
        text: err.message,
      });
      setOpen(true);
      console.log("error signing up:", error);
    }
  }

  const renderForm = () => {
    if (user) {
      return (
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleFormSubmit}
        >
          <TextField
            id="verifyCode"
            label={ dataSet ?mainDataFields.verification_code_input_label[0].text : "Verification Code"}
            variant="filled"
            color="primary"
            placeholder={ dataSet ? mainDataFields.verify_code_textfield_placeholder[0].text : "Please Enter the code sent to your email address."}
            value={code}
            onChange={(event) => {
              const text = event.target.value;
              setCode(text);
            }}
            fullWidth
            classes={{ root: classes.textFields }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: "focused",
                shrink: "shrink",
              },
            }}
            InputProps={{
              classes: {
                root: classes.filledInputRoot,
                focused: classes.fieldFocused,
                shrink: "shrink",
              },
            }}
          />

          <Button
            variant="contained"
            type="submit"
            fullWidth
            disableElevation
            classes={{
              root: classes.submitButton,
              label: classes.buttonText,
            }}
          >
            {localLoading ? <CircularProgress /> : dataSet ? mainDataFields.verify_cta[0].text : t("Verify")}
          </Button>
        </form>
      );
    } else {
      return (
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleFormSubmit}
        >
          <Grid container spacing={matches ? 0 : 2}>
            <Grid item md={6} sm={6} xs={12}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                // onClick={() => {
                //   if (i18next.language === "en") {
                //     i18next.changeLanguage("de", (err, t) => {
                //       if (err)
                //         return console.log("something went wrong loading", err);
                //     });
                //   } else {
                //     i18next.changeLanguage("en", (err, t) => {
                //       if (err)
                //         return console.log("something went wrong loading", err);
                //     });
                //   }
                // }}
                disableRipple
                className={classes.formControl}
                classes={{ label: classes.menuLabel }}
              >
                <div>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-Regular",
                      fontSize: "0.875rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      padding: 0,
                    }}
                  >
                    {dataSet ? mainDataFields.gender_label[0].text : "Gender"}
                  </Typography>
                  { dataSet ?
                        mainDataFields.gender_values.map((val) => (
                          gender == val.gender_api_value &&
                         <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                        { val.gender_display_value[0].text}
                    </Typography>
                    
                     ))
                      : 
                      <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                          {gender == "MALE" 
                      ? dataSet ? mainDataFields.gender_values[1].gender_display_value[0].text : "Male"
                      : gender == "FEMALE"
                      ? dataSet ? mainDataFields.gender_values[2].gender_display_value[0].text : "Femae"
                      : gender == "OTHER"
                      ? dataSet ? mainDataFields.gender_values[3].gender_display_value[0].text : "Other"
                      : dataSet ? mainDataFields.gender_values[0].gender_display_value[0].text : "-"
                      }
                </Typography>
                    }
                </div>
                <img
                  src={ic_arrow_bottom}
                  alt="select"
                  className={classes.downArrow}
                />
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  handleClose();
                }}
                //className={classes.customWidth}
                // classes={{
                //   paper: classes.dropDownMenuPopup,
                // }}
                PaperProps={{
                  style: {
                    width: parseFloat(genderWidth),
                    backgroundColor: "white",
                    paddingTop: 10,

                    border: `2px solid ${theme.palette.common.main}`,
                  },
                }}
              >
                <div
                  onClick={() => {
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: 15,
                    justifyContent: "space-between",
                    paddingRight: 15,
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-Regular",
                        fontSize: "0.875rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        padding: 0,
                      }}
                    >
                    {dataSet ? mainDataFields.gender_label[0].text : "Gender"}
                    </Typography>
                    { dataSet ?
                        mainDataFields.gender_values.slice(1,).map((val) => (
                          gender == val.gender_api_value &&
                         <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                        { val.gender_display_value[0].text}
                    </Typography>
                     ))
                      : 
                      <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                          {gender == "MALE" 
                      ? dataSet ? mainDataFields.gender_values[1].gender_display_value[0].text : "Male"
                      : gender == "FEMALE"
                      ? dataSet ? mainDataFields.gender_values[2].gender_display_value[0].text : "Female"
                      : gender == "OTHER"
                      ? dataSet ? mainDataFields.gender_values[3].gender_display_value[0].text : "Other"
                      : dataSet ? mainDataFields.gender_values[0].gender_display_value[0].text : "-"
                      }
                </Typography>
                    }
                  </div>
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                </div>

                {
                 dataSet ? mainDataFields.gender_values.slice(1,).map((valu) => (
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose(valu.gender_api_value)}
                >
                  {
                  valu.gender_display_value[0].text
                  } 
                </MenuItem>
                 )) 
                  :
                   <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose("Female")}
                >
                  Female
                </MenuItem>
                }
                {/* <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose("Female")}
                >
                  Female
                </MenuItem> */}
              </Menu>
            </Grid>

            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              style={{
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                id="genderButton"
                onClick={handleClick2}
                // onClick={() => {
                //   if (i18next.language === "en") {
                //     i18next.changeLanguage("de", (err, t) => {
                //       if (err)
                //         return console.log("something went wrong loading", err);
                //     });
                //   } else {
                //     i18next.changeLanguage("en", (err, t) => {
                //       if (err)
                //         return console.log("something went wrong loading", err);
                //     });
                //   }
                // }}
                disableRipple
                className={classes.formControl}
                classes={{ label: classes.menuLabel }}
              >
                <div>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-Regular",
                      fontSize: "0.875rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      padding: 0,
                    }}
                  >
                    {dataSet ? mainDataFields.title_label[0].text : "Title"}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-SemiBold",
                      fontSize: "1rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      marginLeft: title === "Mr" ? -10 : 0,
                    }}
                  >
                             { dataSet ?
                        mainDataFields.title_values.slice(1,).map((val) => (
                          title == val.title_api_value &&
                         <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                        { val.title_display_value[0].text}
                        
                    </Typography>
                     )
                     )
                      : 
                      <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                          {title == "MALE" 
                      ? dataSet ? mainDataFields.title_values[1].title_display_value[0].text : "Male"
                      : title == "FEMALE"
                      ? dataSet ? mainDataFields.title_values[2].title_display_value[0].text : "Female"
                      : title == "OTHER"
                      ? dataSet ? mainDataFields.title_values[3].title_display_value[0].text : "Other"
                      : dataSet ? mainDataFields.title_values[0].title_display_value[0].text : "-"
                      }
                </Typography>
                    }
                  </Typography>
                </div>
                <img
                  src={ic_arrow_bottom}
                  alt="select"
                  className={classes.downArrow}
                />
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl2}
                keepMounted
                open={Boolean(anchorEl2)}
                onClose={() => {
                  handleClose2();
                }}
                // classes={{
                //   paper: classes.dropDownMenuPopup,
                // }}
                PaperProps={{
                  style: {
                    width: parseFloat(genderWidth),
                    backgroundColor: "white",
                    paddingTop: 10,

                    border: `2px solid ${theme.palette.common.main}`,
                  },
                }}
              >
                <div
                  onClick={() => {
                    handleClose2();
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: 15,
                    justifyContent: "space-between",
                    paddingRight: 15,
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-Regular",
                        fontSize: "0.875rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        padding: 0,
                      }}
                    >
{dataSet ? mainDataFields.title_label[0].text : "Title"}          
          </Typography>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: title ? 0 : 0,
                      }}
                    >
             { dataSet ?
                        mainDataFields.title_values.slice(1,).map((val) => (
                          title == val.title_api_value &&
                         <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                        { val.title_display_value[0].text}
                    </Typography>
                     ))
                      : 
                      <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                          {title == "MALE" 
                      ? dataSet ? mainDataFields.title_values[1].title_display_value[0].text : "Male"
                      : title == "FEMALE"
                      ? dataSet ? mainDataFields.title_values[2].title_display_value[0].text : "Female"
                      : title == "OTHER"
                      ? dataSet ? mainDataFields.title_values[3].title_display_value[0].text : "Other"
                      : dataSet ? mainDataFields.title_values[0].title_display_value[0].text : "-"
                      }
                </Typography>
                    }
                    </Typography>
                  </div>
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                </div>
               {
                 dataSet ? mainDataFields.title_values.slice(1,).map((val) => (
                  <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose2(val.title_api_value)}
                >
                  {val.title_display_value[0].text}
                  </MenuItem>
                 ))
                 :
                 <div className="">
                       <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose2(dataSet ? mainDataFields.title_values[1].title_api_value : "Dr.")}
                >
                  { dataSet ? mainDataFields.title_values[1].title_display_value[0].text :  "Dr."}
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose2(dataSet ? mainDataFields.title_values[2].title_api_value : "Prof.")}
                >
                  { dataSet ? mainDataFields.title_values[2].title_display_value[0].text : "Prof."}
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose2(dataSet ? mainDataFields.title_values[3].title_api_value : "Prof. Dr.")}
                >
                   { dataSet ? mainDataFields.title_values[3].title_display_value[0].text : "Prof. Dr."}
                </MenuItem>
                 </div>
               }
              </Menu>
            </Grid>
          </Grid>

          {/* <FormControl variant="filled" className={classes.formControl} >
             

              <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose2(dataSet ? mainDataFields.title_values[1].title_api_value : "Dr.")}
                >
                  { dataSet ? mainDataFields.title_values[1].title_display_value[0].text :  "Dr."}
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose2(dataSet ? mainDataFields.title_values[2].title_api_value : "Prof.")}
                >
                  { dataSet ? mainDataFields.title_values[2].title_display_value[0].text : "Prof."}
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose2(dataSet ? mainDataFields.title_values[3].title_api_value : "Prof. Dr.")}
                >
                   { dataSet ? mainDataFields.title_values[3].title_display_value[0].text : "Prof. Dr."}
                </MenuItem>
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                labelId="gender"
                id="gender"
                value={gender}
                onChange={handleChangeGender}
                variant="filled"
                MenuProps={{
                  classes: { paper: classes.dropdownStyle },
                }}
                
                classes={{
                  filled: classes.dropdownFilled,
                  
                }}
                IconComponent={() => (
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                )}
                inputProps={{
                  classes: {
                    root: classes.dropDownBackground
                  }
                }}
               
                
                
              >
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
                
            </FormControl> */}

          {/* <FormControl
              variant="filled"
              className={classes.formControl}
              style={{ marginLeft: 15 }}
            >
              <InputLabel id="gender">Title</InputLabel>
              <Select
                labelId="title"
                id="title"
                value={title}
                onChange={handleTitleGender}
                classes={{
                  filled: classes.dropdownFilled,
                  root: classes.dropDownRoot,
                }}
                MenuProps={{
                  classes: { paper: classes.dropdownStyle },
                }}
                IconComponent={() => (
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                )}
              >
                <MenuItem value={"Mr"}>Mr</MenuItem>
                <MenuItem value={"Mrs"}>Mrs</MenuItem>
              </Select>
            </FormControl> */}

          <TextField
            id="firstName"
            label={dataSet ? mainDataFields.first_name_label[0].text : "First Name"}
            variant="filled"
            color="primary"
            value={firstName}
            onChange={(event) => {
              const text = event.target.value;
              setFirstName(text);
            }}
            onBlur={() => validateForm("firstName")}
            fullWidth
            classes={{
              root: errFirstName ? classes.textFieldsError : classes.textFields,
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: "focused",
                shrink: "shrink",
              },
            }}
            InputProps={{
              classes: {
                root: classes.filledInputRoot,
                focused: classes.fieldFocused,
                shrink: "shrink",
              },
            }}
          />

          {errFirstName && (
            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
              {dataSet ? mainDataFields.required_field_error_message[0].text : errFirstName}
            </Typography>
          )}

          <TextField
            id="lastName"
            label={dataSet ? mainDataFields.last_name_label[0].text : "Last Name"}
            variant="filled"
            color="primary"
            value={lastName}
            onChange={(event) => {
              const text = event.target.value;
              setLastName(text);
            }}
            onBlur={() => validateForm("lastName")}
            fullWidth
            style={{ marginTop: 15 }}
            classes={{
              root: errLastName ? classes.textFieldsError : classes.textFields,
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: "focused",
                shrink: "shrink",
              },
            }}
            InputProps={{
              classes: {
                root: classes.filledInputRoot,
                focused: classes.fieldFocused,
                shrink: "shrink",
              },
            }}
          />

          {errLastName && (
            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
              {dataSet ? mainDataFields.required_field_error_message[0].text :errLastName}
            </Typography>
          )}

          <TextField
            id="email"
            label={dataSet ? mainDataFields.email_label[0].text: "Email"}
            value={email}
            onChange={(event) => {
              const text = event.target.value;
              setEmail(text);
            }}
            onBlur={() => validateForm("email")}
            variant="filled"
            color="primary"
            fullWidth
            style={{ marginTop: 15 }}
            classes={{
              root: errEmail ? classes.textFieldsError : classes.textFields,
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: "focused",
                shrink: "shrink",
              },
            }}
            InputProps={{
              classes: {
                root: classes.filledInputRoot,
                focused: classes.fieldFocused,
                shrink: "shrink",
              },
            }}
          />

          {errEmail && (
            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
              {errEmail}
            </Typography>
          )}

          <TextField
            id="dateofbirth"
            label={dataSet ? mainDataFields.date_of_birth_label[0].text : "Date Of Birth"}
            value={dob}
            onChange={(event) => {
              let text = event.target.value;
              console.log(text);
              console.log(text.length);
              if (
                dob.length > text.length &&
                (text.length === 5 || text.length === 2)
              ) {
                setDOB(text.slice(0, text.length - 1));
                return;
              }
              if (
                dob.length < text.length &&
                (text.length === 2 || text.length === 5)
              ) {
                text += "/";
              }
              if (text.length === 11) {
                return;
              }
              setDOB(text);
            }}
            onBlur={() => validateForm("dob")}
            variant="filled"
            color="primary"
            fullWidth
            placeholder={ dataSet ? mainDataFields.dob_placeholder_day[0].text + mainDataFields.dob_placeholder_separator[0].text + mainDataFields.dob_placeholder_month[0].text + mainDataFields.dob_placeholder_separator[0].text + mainDataFields.dob_placeholder_year[0].text : "DD/MM/YYYY"}
            style={{ marginTop: 15 }}
            classes={{
              root: errDOB ? classes.textFieldsError : classes.textFields,
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: "focused",
                shrink: "shrink",
              },
            }}
            InputProps={{
              classes: {
                root: classes.filledInputRoot,
                focused: classes.fieldFocused,
                shrink: "shrink",
              },
            }}
          />

          {errDOB && (
            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
              {dataSet ? mainDataFields.required_field_error_message[0].text :errDOB}
            </Typography>
          )}

          <TextField
            id="password"
            label={dataSet ? mainDataFields.password_label[0].text : "Password"}
            variant="filled"
            color="primary"
            value={passwordValid.password}
            onChange={(event) => {
              const password = event.target.value;
              var characters = false;
              var number = false;
              var special = false;

              if (password.length > 7) {
                characters = true;
              }

              number = hasNumber(password);
              special = hasSpecial(password);

              setPasswordValid({
                characters,
                number,
                special,
                password: password,
              });
            }}
            fullWidth
            type={showPassword ? "text" : "password"}
            style={{ marginTop: 15 }}
            classes={{
              root:
                errPassword && passwordValid.password === ""
                  ? classes.textFieldsError
                  : classes.textFields,
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: "focused",
                shrink: "shrink",
              },
            }}
            InputProps={{
              classes: {
                root: classes.filledInputRoot,
                focused: classes.fieldFocused,
                shrink: "shrink",
              },
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {errPassword && passwordValid.password === "" && (
            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
              {dataSet ? mainDataFields.required_field_error_message[0].text :errPassword}
            </Typography>
          )}
          {passwordValid.password !== "" ? (
            <div>
              <Typography
                className={classes.passwordText}
                style={{ marginTop: 8 }}
              >
                { dataSet ? mainDataFields.password_restrictions_heading[0].text : "Your password must contain:"}
              </Typography>
              <Grid container alignItems="center">
                <Grid item>
                  <div
                    className={
                      passwordValid.characters
                        ? classes.greenCircle
                        : classes.blackCircle
                    }
                  ></div>
                </Grid>
                <Grid item>
                  <Typography
                    className={
                      passwordValid.characters
                        ? classes.passwordValidText
                        : classes.passwordText
                    }
                  >
                  { dataSet ? mainDataFields.password_restrictions[0].password_restriction[0].text : "At least 8 characters"} 
                 </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item>
                  <div
                    className={
                      passwordValid.number
                        ? classes.greenCircle
                        : classes.blackCircle
                    }
                  ></div>
                </Grid>
                <Grid item>
                  <Typography
                    className={
                      passwordValid.number
                        ? classes.passwordValidText
                        : classes.passwordText
                    }
                  >
                   {dataSet ? mainDataFields.password_restrictions[1].password_restriction[0].text :"A number"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container alignItems="center">
                <Grid item>
                  <div
                    className={
                      passwordValid.special
                        ? classes.greenCircle
                        : classes.blackCircle
                    }
                  ></div>
                </Grid>
                <Grid item>
                  <Typography
                    className={
                      passwordValid.special
                        ? classes.passwordValidText
                        : classes.passwordText
                    }
                  >
                    {dataSet ? mainDataFields.password_restrictions[2].password_restriction[0].text :"A special character"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          ) : null}

          <TextField
            id="confirmpasword"
            label={
              dataSet ? mainDataFields.confirm_password_label[0].text : "Confirm Password"
            }
            variant="filled"
            color="primary"
            fullWidth
            type={showPassword2 ? "text" : "password"}
            style={{ marginTop: 15 }}
            value={confirmPassword}
            onChange={(event) => {
              const text = event.target.value;
              setConfirmPassword(text);
            }}
            onBlur={() => validateForm("confirmPassword")}
            classes={{
              root: errConfirmPassword
                ? classes.textFieldsError
                : classes.textFields,
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: "focused",
                shrink: "shrink",
              },
            }}
            InputProps={{
              classes: {
                root: classes.filledInputRoot,
                focused: classes.fieldFocused,
                shrink: "shrink",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    onMouseDown={handleMouseDownPassword2}
                  >
                    {showPassword2 ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {errConfirmPassword && (
            <Typography className={classes.errorText} style={{ marginTop: 3 }}>
              {errConfirmPassword}
            </Typography>
          )}
          <div
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Link
              to="#"
              onClick={() => {
                setchecked(!checked);
              }}
            >
              <img
                src={checked ? check : uncheck}
                alt="icon"
                className={classes.check}
              />
            </Link>

            <div className={classes.subheading} style={{ marginLeft: 10 }}>
              {dataSet ? mainDataFields["agree_statment"][0].text : "I agree to"}
              <Link
                className={classes.contentTextLink}
                to={"/privacy"}
                target="_blank"
              >
                {" "}
                {dataSet ? mainDataFields.privacy_condition[0].text : "Privacy Policy"}
              </Link>
              <span>
                {" "}
                {dataSet ? mainDataFields.and[0].text :"and"}
              </span>
              <Link
                className={classes.contentTextLink}
                to={"/terms"}
                target="_blank"
              >
                {" "}
                {dataSet ? mainDataFields.terms_condition[0].text : "Terms & Conditions"}
              </Link>
            </div>
          </div>

          <Button
            variant="contained"
            type="submit"
            fullWidth
            disableElevation
            disabled={!checked}
            classes={{
              root: classes.submitButton,
              label: classes.buttonText,
              disabled: classes.disabledButton,
            }}
            style={{
              opacity: checked ? 1 : 0.4,
              backgroundColor: theme.palette.common.main,
            }}
          >
            {localLoading ? <CircularProgress /> : t("Register")}
          </Button>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Typography
              style={{ marginTop: 26 }}
              className={classes.underlineText}
            >
                {dataSet ? mainDataFields["sign-in_text"][0].text : "Already a member? Sign in here"}
            </Typography>
          </Link>
        </form>
      );
    }
  };

  let lan = languageMap[i18n.language];
  let mainData = content[lan];
  let mainBenefits = benefits[lan];
  console.log(mainData);
  console.log(mainBenefits);
  // field names
  // let lan = languageMap[i18n.language];
  let mainDataFields = fieldNames[lan];
  // let mainBenefits = benefits[lan];
  console.log("mainDataFields", mainDataFields);
  // console.log(mainBenefits);
  // field names end
  const getPrismicText = (textArr, type) => {
    let returnText = null;
    textArr.forEach((text) => {
      if (text.type === type && text.text !== "") {
        returnText = text.text;
      }
    });
    return returnText;
  };

  const renderListItems = (data) => {
    let p = [];
    data.forEach((item) => {
      if (item.type === "list-item") {
        p.push(
          <Grid
            key={item.text}
            container
            direction="row"
            style={{ marginTop: p.length === 0 ? 0 : 10 }}
          >
            <Grid item xs={0} style={{ marginRight: 10 }}>
              <img
                src={ic_check}
                alt="checkmark"
                className={classes.checkMark}
              />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.contentText}>
                {item.text}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    });
    return p;
  };

  const renderBenefits = (data) => {
    let p = [];

    for (let count = 0; count < data.length; count += 2) {
      console.log(count);
      p.push(
        <Grid key={count} item>
          <Grid container direction="row" spacing={2}>
            <Grid item md={6} xs={12}>
              <img
                src={data[count].data.icon.url}
                alt="icon"
                className={classes.icon}
              />
              <Typography className={classes.subheading}>
                {data[count].data.text[0].text}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <img
                src={data[count + 1].data.icon.url}
                alt="icon"
                className={classes.icon}
              />
              <Typography className={classes.subheading}>
                {data[count + 1].data.text[0].text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return p;
  };

  const renderHeroContent = () => {
    const {
      header_section_type,
      image_background_color,
      image_type,
      primary_image,
      video,
      video_controls,
    } = mainData;

    if (header_section_type === "image") {
      return (
        <div
          className={classes.mainContent}
          style={{
            backgroundColor:
              image_background_color !== null
                ? image_background_color
                : "transparent",
          }}
        >
          <img
            src={primary_image.url}
            style={{
              width: "100%",
              height: "100%",
              objectFit: image_type,
              borderRadius: image_type === "cover" ? 7 : 0,
            }}
          />
        </div>
      );
    } else {
      return (
        <div
          className={classes.mainContent}
          style={{
            backgroundColor: "black",
          }}
        >
          <video
            width="100%"
            height={220}
            controls={video_controls}
            autoPlay={true}
            muted={video_controls ? false : true}
          >
            <source src={video.url} type="video/mp4" />
          </video>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className={classes.contentContainer}>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <Grid container direction="column">
              <Grid item>{dataSet && renderHeroContent()}</Grid>

              <Grid item style={{ marginTop: 23 }}>
                {dataSet && (
                  <Typography className={classes.heading}>
                    {getPrismicText(mainData.primary_text, "heading1")}
                  </Typography>
                )}
              </Grid>

              <Grid item style={{ marginTop: 9 }}>
                {dataSet && (
                  <Typography className={classes.contentText}>
                    {getPrismicText(mainData.primary_text, "paragraph")}
                  </Typography>
                )}
              </Grid>

              <Grid item style={{ marginTop: 23 }}>
                {dataSet && renderListItems(mainData.primary_text)}
              </Grid>

              <Grid item style={{ marginTop: 24 }}>
                <Grid container direction="column" spacing={3}>
                  {dataSet && renderBenefits(mainBenefits)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12}>
            <div className={classes.registrationForm}>
              <div className={classes.registrationFormContent}>
                <Typography className={classes.heading}>
                  { dataSet ? mainDataFields.signup_heading[0].text : "Lorem ipsum dolor sit"}
                </Typography>
                <Typography
                  className={classes.contentText}
                  style={{ marginTop: 5 }}
                >
                  { dataSet ? mainDataFields["signup_sub-heading"][0].text : "This is a subheadline"}
                </Typography>

                {renderForm()}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <DialogBox
        open={open}
        onClose={() => {
          console.log("closing dialog");
          setOpen(false);
        }}
        title={error.title}
        text={error.text}
      />
    </React.Fragment>
  );
}
