import React, { useContext, useEffect } from "react";

//import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import placeholderIcon from "../assets/images/placeholder.png";
import ic_arrow_bottom from "../assets/Icons/ic_arrow_bottom.svg";
import ic_check from "../assets/Icons/ic_check.svg";
import uncheck from "../assets/Icons/uncheck.svg";
import check from "../assets/Icons/check.svg";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import i18next from "i18next";

import doctor from "../assets/Illustrations/illustration-doctor.svg";
import psychotherapist from "../assets/Illustrations/illustration-psychotherapist.svg";
import psychiatrist from "../assets/Illustrations/illustration-psychiatrist.svg";
import physiotherapist from "../assets/Illustrations/illustration-physiotherapist.svg";
import yoga from "../assets/Illustrations/illustration-yoga.svg";
import meditation from "../assets/Illustrations/illustration-meditation.svg";
import coach from "../assets/Illustrations/illustration-coach.svg";
import naturopath from "../assets/Illustrations/illustration-naturopath.svg";

// import { Context as AuthContext } from "../context/AuthContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import { Context as OnboardingContext } from "../context/OnboardingContext";
import { Context as AuthContext } from "../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    maxWidth: theme.layout.contentWidth,

    margin: "0 auto",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
    },
  },
  heading: {
    ...theme.typography.HL1,
  },
  subheading: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1.5rem",
    textTransform: "none",
    lineHeight: "1.875rem",
    color: theme.palette.common.darkGrey,
  },
  cardHeading: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.875rem",
    color: theme.palette.common.darkGrey,
    width: "100%",
    textAlign: "center",
    height: 50,
    [theme.breakpoints.down("lg")]: {
      height: 70,
    },
  },
  contentText: {
    ...theme.typography.content,
  },
  subText: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.875rem",
    color: theme.palette.common.darkGrey,
  },
  chatText: {
    ...theme.typography.content,
    width: "100%",
    textAlign: "center",
    paddingTop: 10,
  },
  cardContent: {
    backgroundColor: "white",

    width: "100%",
    boxShadow: "5px 10px 40px rgba(120, 120, 120, 0.22)",
    borderRadius: 8,
    display: "block",
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    "&:hover": {
      backgroundColor: "white",
    },
  },
  illustration: {
    width: "100%",
  },
}));

export default function DoctorType() {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();

  const classes = useStyles();
  const {
    state: { user, usersession },
    setOnboarding,
  } = useContext(AuthContext);

  const {
    state: { onboardingData, isReturningFromForm },
    updateOnboardingData,
  } = useContext(OnboardingContext);

  useEffect(() => {
    if (!isReturningFromForm) {
      fetchSavedData();
    }
  }, []);

  const fetchSavedData = async () => {
    let userId = user.id;
    let dataObj = localStorage.getItem(userId.toString());
    if (dataObj) {
      let data = JSON.parse(dataObj);

      const { category } = data;

      console.log(category);
      if (category === "GeneralPractitioner") {
        history.push("/doctor-registration-form/0");
      } else if (category === "Psychotherapist") {
        history.push("/doctor-registration-form/1");
      } else if (category === "MidWives") {
        history.push("/doctor-registration-form/2");
      } else if (category === "Physiotherapists") {
        history.push("/doctor-registration-form/3");
      } else if (category === "YogaTeacher") {
        history.push("/doctor-registration-form/4");
      } else if (category === "MeditationTrainer") {
        history.push("/doctor-registration-form/5");
      } else if (category === "Coach") {
        history.push("/doctor-registration-form/6");
      } else if (category === "Naturopath") {
        history.push("/doctor-registration-form/7");
      }
    }
  };

  return (
    <React.Fragment>
      <div className={classes.contentContainer}>
        <div style={{ marginTop: 23 }}>
          <Typography className={classes.heading}>
            {t("WelcomeToEterno")} Maximilian
          </Typography>
        </div>

        <div style={{ marginTop: 23 }}>
          <Typography className={classes.subheading}>
            {t("StartSettingProfile")}
          </Typography>
        </div>

        <div style={{ marginTop: 10 }}>
          <Typography className={classes.subText}>
            {t("ChooseProfileField")}
          </Typography>
        </div>

        <Grid container spacing={2} style={{ marginTop: 23 }}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Button
              //component={Link}
              // to="/doctor-registration-form/0"
              onClick={() => {
                updateOnboardingData({ category: "GeneralPractitioner" });
                history.push("/doctor-registration-form/0");
              }}
              disableRipple
              className={classes.cardContent}
            >
              <div>
                <img
                  src={doctor}
                  alt="Doctor"
                  className={classes.illustration}
                />
              </div>

              <Typography className={classes.cardHeading}>
                {t("GeneralPractitioner")}
              </Typography>
            </Button>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Button
              onClick={() => {
                updateOnboardingData({ category: "Psychotherapist" });
                history.push("/doctor-registration-form/1");
              }}
              disableRipple
              className={classes.cardContent}
            >
              <img
                src={psychotherapist}
                alt="Doctor"
                className={classes.illustration}
              />
              <Typography className={classes.cardHeading}>
                {t("Psychotherapist")}
              </Typography>
            </Button>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Button
              onClick={() => {
                updateOnboardingData({ category: "MidWives" });
                history.push("/doctor-registration-form/2");
              }}
              disableRipple
              className={classes.cardContent}
            >
              <img
                src={psychiatrist}
                alt="Doctor"
                className={classes.illustration}
              />
              <Typography className={classes.cardHeading}>
                {t("MidWives")}
              </Typography>
            </Button>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Button
              onClick={() => {
                updateOnboardingData({ category: "Physiotherapists" });
                history.push("/doctor-registration-form/3");
              }}
              disableRipple
              className={classes.cardContent}
            >
              <img
                src={physiotherapist}
                alt="Doctor"
                className={classes.illustration}
              />
              <Typography className={classes.cardHeading}>
                {t("Physiotherapists")}
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 8 }}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Button
              onClick={() => {
                updateOnboardingData({ category: "YogaTeacher" });
                history.push("/doctor-registration-form/4");
              }}
              disableRipple
              className={classes.cardContent}
            >
              <div>
                <img src={yoga} alt="Doctor" className={classes.illustration} />
              </div>

              <Typography className={classes.cardHeading}>
                {t("YogaTeacher")}
              </Typography>
            </Button>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Button
              onClick={() => {
                updateOnboardingData({ category: "MeditationTrainer" });
                history.push("/doctor-registration-form/5");
              }}
              disableRipple
              className={classes.cardContent}
            >
              <img
                src={meditation}
                alt="Doctor"
                className={classes.illustration}
              />
              <Typography className={classes.cardHeading}>
                {t("MeditationTrainer")}
              </Typography>
            </Button>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Button
              onClick={() => {
                updateOnboardingData({ category: "Coach" });
                history.push("/doctor-registration-form/6");
              }}
              disableRipple
              className={classes.cardContent}
            >
              <img src={coach} alt="Doctor" className={classes.illustration} />
              <Typography className={classes.cardHeading}>
                {t("Coach")}
              </Typography>
            </Button>
          </Grid>

          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Button
              onClick={() => {
                updateOnboardingData({ category: "Naturopath" });
                history.push("/doctor-registration-form/7");
              }}
              disableRipple
              className={classes.cardContent}
            >
              <img
                src={naturopath}
                alt="Doctor"
                className={classes.illustration}
              />
              <Typography className={classes.cardHeading}>
                {t("Naturopath")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
