import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import ic_arrow_bottom from "../../assets/Icons/ic_arrow_bottom.svg";

import { expertiseList } from "../../utils/Constant";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StyledRadio from "../FormFields/StyledRadio";
import { Context as OnboardingContext } from "../../context/OnboardingContext";
import UnderlinedButton from "../Common/UnderlinedButton";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    backgroundColor: "white",

    maxWidth: theme.layout.contentWidth,
    boxShadow: "5px 10px 40px rgba(120, 120, 120, 0.22)",
    borderRadius: 8,
    display: "block",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 23,
  },
  heading: {
    ...theme.typography.HL2,
  },

  radioLabel: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    textTransform: "none",
  },
  formControl: {
    width: "100%",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingTop: 14,
    paddingBottom: 14,
    height: "4.3em",
    marginBottom: 15,
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
    },
  },
  menuItem: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    textTransform: "none",
  },
  textFields: {
    height: "3.75em",
  },

  fieldFocused: {
    backgroundColor: "white",
    border: "2px solid #4C726D",
  },
  filledInputRoot: {
    overflow: "hidden",
    backgroundColor: "white",
    border: "1px solid #ABABAB",
    paddingRight: 10,
    borderRadius: 5,
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    height: "100%",
    "&:hover": {
      backgroundColor: "#F9F8F4",
      //backgroundColor: "red",
    },
  },
  inputLabel: {
    "&.focused": {
      color: theme.palette.common.darkGrey,
    },
    "&.shrink": {
      fontSize: "1rem",
    },
  },
  addLanguage: {
    width: "100%",
    backgroundColor: "transparent",
    paddingTop: 14,
    paddingBottom: 14,
    height: "4.3em",
    marginBottom: 15,
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  errorText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.red,
  },
}));

export default function GeneralPhysicianForm() {
  const initialAdditionalDesignations = [
    "Akupunktur",
    "Allergologie",
    "Andrologie",
    "Arbeitsmedizin",
    "Ärztliches Qualitätsmanagement",
  ];

  const {
    state: { professionalData, errorData },
    updateProfessionalData,
    resetProfessionalData,
  } = useContext(OnboardingContext);

  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const [approbation, setApprobation] = useState("-");
  const [approbationWidth, setApprobationWidth] = useState("0");
  const [approbationAnchorEl, setApprobationAnchorEl] = React.useState(null);

  const [anderes, setAnderes] = useState("");

  const [licenseDate, setLicenseDate] = useState("");

  const [furtherTraining, setFurtherTraining] = useState("");

  const [furtherTrainingDate, setFurtherTrainingDate] = useState("");

  const [licenseFor, setLicenseFor] = useState("");

  const [medicalHistory, setMedicalHistory] = useState("");

  const [areaExpertiseWidth, setAreaExpertiseWidth] = useState("0");
  const [areaExpertiseAnchorEl, setAreaExpertiseAnchorEl] =
    React.useState(null);
  const [areaExpertise, setAreaExpertise] = useState(["-"]);
  const [expertiseCount, setExpertiseCount] = useState(1);
  const [menuIndex, setMenuIndex] = useState(-1);

  const [additionalDesignations, setAdditionalDesignations] = useState([
    "Akupunktur",
    "Allergologie",
    "Andrologie",
    "Arbeitsmedizin",
    "Ärztliches Qualitätsmanagement",
  ]);
  const [additionalDesignationWidth, setAdditionalDesignationWidth] =
    useState("0");
  const [additionalDesignationAnchorEl, setAdditionalDesignationAnchorEl] =
    React.useState(null);
  const [totalAD, setTotalAD] = useState(["-"]);
  const [adCount, setADCount] = useState(1);

  const [licenseRegistered, setLicenseRegistered] = useState("-");
  const [lrWidth, setLRWidth] = useState("0");
  const [lrAnchorEl, setlrAnchorEL] = React.useState(null);
  const [lDoctor, setLDoctor] = useState("");

  useEffect(() => {
    //resetProfessionalData();
    if (
      professionalData.area_of_expertise !== undefined &&
      areaExpertise[0] === "-"
    ) {
      setAreaExpertise(professionalData.area_of_expertise);
      setExpertiseCount(professionalData.area_of_expertise.length);
    }
  }, [professionalData.area_of_expertise]);

  useEffect(() => {
    //resetProfessionalData();
    if (
      professionalData.additional_medical_designations !== undefined &&
      totalAD[0] === "-"
    ) {
      setTotalAD(professionalData.additional_medical_designations);
      setADCount(professionalData.additional_medical_designations.length);
    }
  }, [professionalData.additional_medical_designations]);

  const handleClickApprobation = (event) => {
    var w = document.getElementById("approbation").clientWidth;
    console.log(w);
    setApprobationWidth(w.toString());
    setApprobationAnchorEl(event.currentTarget);
  };

  const handleCloseApprobation = (approbation = "") => {
    setApprobationAnchorEl(null);
    if (approbation !== "") {
      updateProfessionalData({ approbation_in: approbation });
      setApprobation(approbation);
    }
  };

  const handleFurtherTrainingChange = (event) => {
    updateProfessionalData({
      further_studies: {
        completed: event.target.value,
        date: furtherTrainingDate,
      },
    });
    setFurtherTraining(event.target.value);
  };

  const handleLicenseFor = (event) => {
    updateProfessionalData({
      license_for_registered_doctor: event.target.value,
    });
    setLicenseFor(event.target.value);
  };

  const handleClickAreaExpertise = (event, index) => {
    var w = document.getElementById("areaExpertise").clientWidth;
    console.log(w);
    setAreaExpertiseWidth(w.toString());
    setMenuIndex(index);
    setAreaExpertiseAnchorEl(event.currentTarget);
  };

  const handleCloseAreaExpertise = (expertise = "", index) => {
    setAreaExpertiseAnchorEl(null);
    if (expertise !== "") {
      let tl = areaExpertise;
      console.log(index);
      tl[menuIndex] = expertise;
      updateProfessionalData({
        area_of_expertise: tl,
      });
      setAreaExpertise(tl);
      setMenuIndex(-1);
    }
  };

  const handleClickAD = (event, index) => {
    var w = document.getElementById("additionalDesignations").clientWidth;
    console.log(w);
    setAdditionalDesignationWidth(w.toString());
    var l = additionalDesignations;

    totalAD.forEach((t) => {
      var i = l.indexOf(t);
      if (i > -1) {
        l.splice(i, 1);
      }
    });
    console.log(l);
    setMenuIndex(index);
    setAdditionalDesignations(l);
    setAdditionalDesignationAnchorEl(event.currentTarget);
  };

  const handleCloseAD = (lang = "", index) => {
    setAdditionalDesignationAnchorEl(null);
    if (lang !== "") {
      let tl = totalAD;

      tl[menuIndex] = lang;
      setTotalAD(tl);
      updateProfessionalData({
        additional_medical_designations: tl,
      });
      setAdditionalDesignations(initialAdditionalDesignations);
      setMenuIndex(-1);
    }
  };

  const handleClickLR = (event) => {
    var w = document.getElementById("whereLicenseRegistered").clientWidth;
    console.log(w);
    setLRWidth(w.toString());
    setlrAnchorEL(event.currentTarget);
  };

  const handleCloseLR = (approbation = "") => {
    setlrAnchorEL(null);
    if (approbation !== "") {
      updateProfessionalData({
        license_for_registered_doctor_at: approbation,
      });
      setLicenseRegistered(approbation);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.cardContent}>
        <Typography className={classes.heading}>
          {t("ProfessionalExperience")}
        </Typography>

        <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                value={
                  professionalData.liscensed_doctor === undefined
                    ? ""
                    : professionalData.liscensed_doctor
                }
                aria-label="licensedDoctor"
                name="customized-radios"
                onChange={(event, value) => {
                  updateProfessionalData({ liscensed_doctor: value });
                }}
              >
                <Typography className={classes.radioLabel}>
                  {t("LicensedDoctor")}*
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {errorData.liscense_doctor && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("RequiredField")}
          </Typography>
        )}

        {/* <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("ReceivedApprobation")}*
        </Typography> */}

        {/* <Grid container style={{ marginTop: 5 }} spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Button
              aria-controls="approbation"
              aria-haspopup="true"
              onClick={handleClickApprobation}
              id="approbation"
              disableRipple
              className={classes.formControl}
              classes={{ label: classes.menuLabel }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "MessinaSans-Regular",
                    fontSize: "0.875rem",
                    color: theme.palette.common.darkGrey,
                    textTransform: "none",
                    padding: 0,
                    marginLeft: 3,
                  }}
                >
                  Approbation
                </Typography>
                <Typography
                  style={{
                    fontFamily: "MessinaSans-SemiBold",
                    fontSize: "1rem",
                    color: theme.palette.common.darkGrey,
                    textTransform: "none",
                    marginLeft: 3,
                    textAlign: "left",
                  }}
                >
                  {professionalData.approbation_in === undefined
                    ? "-"
                    : professionalData.approbation_in !== "Deutschland"
                    ? "Anderes Land"
                    : "Deutschland"}
                </Typography>
              </div>
              <img
                src={ic_arrow_bottom}
                alt="select"
                className={classes.downArrow}
              />
            </Button>

            <Menu
              id="approbation"
              anchorEl={approbationAnchorEl}
              open={Boolean(approbationAnchorEl)}
              onClose={() => {
                handleCloseApprobation();
              }}
              PaperProps={{
                style: {
                  width: parseFloat(approbationWidth),
                  backgroundColor: "white",
                  paddingTop: 10,

                  border: `2px solid ${theme.palette.common.main}`,
                },
              }}
            >
              <div
                onClick={() => {
                  handleCloseApprobation();
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: 15,
                  justifyContent: "space-between",
                  paddingRight: 15,
                  marginBottom: 10,
                }}
              >
                <div>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-Regular",
                      fontSize: "0.875rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      padding: 0,
                    }}
                  >
                    Approbation
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-SemiBold",
                      fontSize: "1rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      marginLeft: i18next.language === "en" ? 0 : 0,
                    }}
                  >
                    {professionalData.approbation_in === undefined
                      ? "-"
                      : professionalData.approbation_in !== "Deutschland"
                      ? "Anderes Land"
                      : "Deutschland"}
                  </Typography>
                </div>
                <img
                  src={ic_arrow_bottom}
                  alt="select"
                  className={classes.downArrow}
                />
              </div>

              <MenuItem
                className={classes.menuItem}
                onClick={() => handleCloseApprobation("Deutschland")}
              >
                Deutschland
              </MenuItem>

              <MenuItem
                className={classes.menuItem}
                onClick={() => handleCloseApprobation("Anderes Land")}
              >
                Anderes Land
              </MenuItem>
            </Menu>

            {professionalData.approbation_in !== "-" &&
              professionalData.approbation_in !== "Deutschland" && (
                <TextField
                  id="anderesland"
                  label={t("Anderes Land")}
                  variant="filled"
                  color="primary"
                  value={
                    professionalData.approbation_in === "Anderes Land"
                      ? ""
                      : professionalData.approbation_in
                  }
                  onChange={(event) => {
                    const text = event.target.value;
                    updateProfessionalData({ approbation_in: text });
                    setAnderes(text);
                  }}
                  fullWidth
                  classes={{
                    root: classes.textFields,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputLabel,
                      focused: "focused",
                      shrink: "shrink",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.filledInputRoot,
                      focused: classes.fieldFocused,
                      shrink: "shrink",
                    },
                  }}
                />
              )}
          </Grid>
        </Grid>
        {errorData.approbation_in && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("RequiredField")}
          </Typography>
        )} */}

        {/* <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("HowLongLicense")}*
        </Typography>

        <Grid container style={{ marginTop: 5 }} spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TextField
              id="licenseDate"
              label={t("From")}
              variant="filled"
              color="primary"
              placeholder="MM/YYYY"
              value={
                professionalData.liscense_from === undefined
                  ? ""
                  : professionalData.liscense_from
              }
              onChange={(event) => {
                const text = event.target.value;
                updateProfessionalData({ liscense_from: text });
                setLicenseDate(text);
              }}
              fullWidth
              classes={{
                root: classes.textFields,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.filledInputRoot,
                  focused: classes.fieldFocused,
                  shrink: "shrink",
                },
              }}
            />
          </Grid>
        </Grid>

        {errorData.liscense_from && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("RequiredField")}
          </Typography>
        )}

        <Grid container style={{ marginTop: 15 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                defaultValue={
                  professionalData.further_studies !== undefined
                    ? professionalData.further_studies.completed
                    : ""
                }
                value={
                  professionalData.further_studies !== undefined
                    ? professionalData.further_studies.completed
                    : ""
                }
                aria-label="furthertraining"
                name="customized-radios"
                onChange={handleFurtherTrainingChange}
              >
                <Typography className={classes.radioLabel}>
                  {t("FurtherTrainer")}*
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {professionalData.further_studies?.completed === "yes" && (
          <Grid container style={{ marginTop: 5 }} spacing={2}>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <TextField
                id="furtherTrainingDate"
                label={t("WhenGraduate")}
                variant="filled"
                color="primary"
                placeholder="MM/YYYY"
                value={
                  professionalData.further_studies.date === undefined
                    ? ""
                    : professionalData.further_studies.date
                }
                onChange={(event) => {
                  const text = event.target.value;
                  updateProfessionalData({
                    further_studies: {
                      completed: furtherTraining,
                      date: text,
                    },
                  });
                  setFurtherTrainingDate(text);
                }}
                fullWidth
                classes={{
                  root: classes.textFields,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.filledInputRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        {professionalData.further_studies?.completed === "no" && (
          <Grid container style={{ marginTop: 5 }} spacing={2}>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <TextField
                id="furtherTrainingDate"
                label={t("ExpectGraduate")}
                variant="filled"
                color="primary"
                placeholder="MM/YYYY"
                value={
                  professionalData.further_studies.date === undefined
                    ? ""
                    : professionalData.further_studies.date
                }
                onChange={(event) => {
                  const text = event.target.value;
                  updateProfessionalData({
                    further_studies: {
                      completed: furtherTraining,
                      date: text,
                    },
                  });
                  setFurtherTrainingDate(text);
                }}
                fullWidth
                classes={{
                  root: classes.textFields,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.inputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.filledInputRoot,
                    focused: classes.fieldFocused,
                    shrink: "shrink",
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        {errorData.further_studies && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("RequiredField")}
          </Typography>
        )}

        <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("HistoySince")}*
        </Typography>

        <Grid container style={{ marginTop: 5 }} spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TextField
              id="medicalhistory"
              label={t("From")}
              variant="filled"
              color="primary"
              placeholder="YYYY"
              value={
                professionalData.medical_history_since === undefined
                  ? ""
                  : professionalData.medical_history_since
              }
              onChange={(event) => {
                const text = event.target.value;
                updateProfessionalData({
                  medical_history_since: text,
                });
                setMedicalHistory(text);
              }}
              fullWidth
              classes={{
                root: classes.textFields,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.filledInputRoot,
                  focused: classes.fieldFocused,
                  shrink: "shrink",
                },
              }}
            />
          </Grid>
        </Grid>

        {errorData.medical_history_since && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("RequiredField")}
          </Typography>
        )} */}

        <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("Area of Expertise")}*
        </Typography>

        {areaExpertise.map((expertise, index) => (
          <Grid
            container
            style={{ marginTop: index === 0 ? 10 : -20 }}
            spacing={2}
          >
            <Grid item md={4} sm={12} xs={12}>
              <Button
                aria-controls="areaExpertise"
                aria-haspopup="true"
                onClick={(event) => handleClickAreaExpertise(event, index)}
                id="areaExpertise"
                disableRipple
                className={classes.formControl}
                classes={{ label: classes.menuLabel }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-Regular",
                      fontSize: "0.875rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      padding: 0,
                      marginLeft: 3,
                    }}
                  >
                    {t("SelectSubject")}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-SemiBold",
                      fontSize: "1rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      marginLeft: 3,
                      textAlign: "left",
                    }}
                  >
                    {expertise}
                  </Typography>
                </div>
                <img
                  src={ic_arrow_bottom}
                  alt="select"
                  className={classes.downArrow}
                />
              </Button>

              <Menu
                id="areaExpertise"
                anchorEl={areaExpertiseAnchorEl}
                open={Boolean(areaExpertiseAnchorEl)}
                onClose={() => {
                  handleCloseAreaExpertise();
                }}
                PaperProps={{
                  style: {
                    width: parseFloat(areaExpertiseWidth),
                    backgroundColor: "white",
                    paddingTop: 10,

                    border: `2px solid ${theme.palette.common.main}`,
                    maxHeight: 400,
                  },
                }}
              >
                <div
                  onClick={() => {
                    handleCloseAreaExpertise();
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: 15,
                    justifyContent: "space-between",
                    paddingRight: 15,
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-Regular",
                        fontSize: "0.875rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        padding: 0,
                      }}
                    >
                      {t("SelectSubject")}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                      {expertise}
                    </Typography>
                  </div>
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                </div>

                <Typography
                  style={{
                    fontFamily: "MessinaSans-SemiBold",
                    fontSize: "1rem",
                    color: theme.palette.common.darkGrey,
                    textTransform: "none",
                    marginLeft: 5,
                  }}
                >
                  Hausarzt/-ärztin
                </Typography>

                {expertiseList["Hausarzt/-ärztin"].map((l, ind) => (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseAreaExpertise(l, index)}
                  >
                    {l}
                  </MenuItem>
                ))}

                <Typography
                  style={{
                    fontFamily: "MessinaSans-SemiBold",
                    fontSize: "1rem",
                    color: theme.palette.common.darkGrey,
                    textTransform: "none",
                    marginLeft: 5,
                  }}
                >
                  Anästhesist/-in
                </Typography>

                {expertiseList["Anästhesist/-in"].map((l, ind) => (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseAreaExpertise(l, index)}
                  >
                    {l}
                  </MenuItem>
                ))}

                <Typography
                  style={{
                    fontFamily: "MessinaSans-SemiBold",
                    fontSize: "1rem",
                    color: theme.palette.common.darkGrey,
                    textTransform: "none",
                    marginLeft: 5,
                  }}
                >
                  Anästhesist/-in
                </Typography>

                {expertiseList["Frauenarzt/-ärztin"].map((l, ind) => (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseAreaExpertise(l, index)}
                  >
                    {l}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            {/* {index === expertiseCount - 1 && (
              <Grid item md={4} sm={12} xs={12}>
                <UnderlinedButton
                  text={t("SelectOtherSubjects")}
                  noMarginTop={true}
                  marginTop={matches ? 0 : 20}
                  onPress={() => {
                    var tl = areaExpertise;
                    tl.push("-");
                    console.log(tl);
                    setAreaExpertise(tl);
                    setExpertiseCount(expertiseCount + 1);
                  }}
                />
              </Grid>
            )} */}
          </Grid>
        ))}

        {errorData.area_of_expertise && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("RequiredField")}
          </Typography>
        )}

        {/* <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("AdditionalMedicalDesignations")}*
        </Typography>

        {totalAD.map((expertise, index) => (
          <Grid
            container
            style={{ marginTop: index === 0 ? 10 : -20 }}
            spacing={2}
          >
            <Grid item md={4} sm={12} xs={12}>
              <Button
                aria-controls="additionalDesignations"
                aria-haspopup="true"
                onClick={(event) => handleClickAD(event, index)}
                id="additionalDesignations"
                // onClick={() => {
                //   if (i18next.language === "en") {
                //     i18next.changeLanguage("de", (err, t) => {
                //       if (err)
                //         return console.log("something went wrong loading", err);
                //     });
                //   } else {
                //     i18next.changeLanguage("en", (err, t) => {
                //       if (err)
                //         return console.log("something went wrong loading", err);
                //     });
                //   }
                // }}
                disableRipple
                className={classes.formControl}
                classes={{ label: classes.menuLabel }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-Regular",
                      fontSize: "0.875rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      padding: 0,
                      marginLeft: 3,
                    }}
                  >
                    {t("SelectAdditionalDesignations")}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-SemiBold",
                      fontSize: "1rem",
                      color: theme.palette.common.darkGrey,
                      textTransform: "none",
                      marginLeft: 3,
                      textAlign: "left",
                    }}
                  >
                    {expertise}
                  </Typography>
                </div>
                <img
                  src={ic_arrow_bottom}
                  alt="select"
                  className={classes.downArrow}
                />
              </Button>

              <Menu
                id="additionalDesignations"
                anchorEl={additionalDesignationAnchorEl}
                open={Boolean(additionalDesignationAnchorEl)}
                onClose={() => {
                  handleCloseAD();
                }}
                PaperProps={{
                  style: {
                    width: parseFloat(additionalDesignationWidth),
                    backgroundColor: "white",
                    paddingTop: 10,

                    border: `2px solid ${theme.palette.common.main}`,
                    maxHeight: 400,
                  },
                }}
              >
                <div
                  onClick={() => {
                    handleCloseAD();
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: 15,
                    justifyContent: "space-between",
                    paddingRight: 15,
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-Regular",
                        fontSize: "0.875rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        padding: 0,
                      }}
                    >
                      {t("SelectAdditionalDesignations")}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                      {expertise}
                    </Typography>
                  </div>
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                </div>

                {additionalDesignations.map((l, ind) => (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseAD(l, index)}
                  >
                    {l}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            {index === adCount - 1 && (
              <Grid item md={4} sm={12} xs={12}>
                <UnderlinedButton
                  text={t("SelectOtherAdditionalDesignations")}
                  noMarginTop={true}
                  marginTop={matches ? 0 : 20}
                  onPress={() => {
                    var tl = totalAD;
                    tl.push("-");
                    console.log(tl);
                    setTotalAD(tl);
                    setADCount(adCount + 1);
                  }}
                />
              </Grid>
            )}
          </Grid>
        ))}

        {errorData.additional_medical_designations && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("RequiredField")}
          </Typography>
        )} */}

        <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("CurrentActivity")}*
        </Typography>

        <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                defaultValue={
                  professionalData.type_of_current_activity === undefined
                    ? ""
                    : professionalData.type_of_current_activity
                }
                value={
                  professionalData.type_of_current_activity === undefined
                    ? ""
                    : professionalData.type_of_current_activity
                }
                aria-label="currentActivity"
                name="customized-radios"
                onChange={(event, value) => {
                  updateProfessionalData({
                    type_of_current_activity: value,
                  });
                }}
              >
                <FormControlLabel
                  classes={{ root: classes.radioLabel }}
                  value="Niedergelassener, freiberuflicher tätiger Vertragsarzt (Kassenarzt)" 
                  control={<StyledRadio />}
                  label={t(
                    "Niedergelassener, freiberuflicher tätiger Vertragsarzt (Kassenarzt)"
                  )}
                />
                  <FormControlLabel
                  classes={{ root: classes.radioLabel }}
                  value="Niedergelassener, freiberuflich tätiger Privatarzt"
                  control={<StyledRadio />}
                  label={t(
                    "Niedergelassener, freiberuflich tätiger Privatarzt"
                  )}
                />
                <FormControlLabel
                  classes={{ root: classes.radioLabel }}
                  value="Zugelassener Vertragsarzt in einem Medizinischen Vorsorgezentrum (MVZ)"
                  control={<StyledRadio />}
                  label={t(
                    "Zugelassener Vertragsarzt in einem Medizinischen Vorsorgezentrum (MVZ)"
                  )}
                />
                <FormControlLabel
                  classes={{ root: classes.radioLabel }}
                  value="Anstellung bei einem Vertragsarzt oder in einem MVZ"
                  control={<StyledRadio />}
                  label={t(
                    "Anstellung bei einem Vertragsarzt oder in einem MVZ"
                  )}
                />
                <FormControlLabel
                  classes={{ root: classes.radioLabel }}
                  value="Anstellung in einem Krankenhaus"
                  control={<StyledRadio />}
                  label={t("Anstellung in einem Krankenhaus")}
                />
                <FormControlLabel
                  classes={{ root: classes.radioLabel }}
                  value="Sonstiges"
                  control={<StyledRadio />}
                  label={t("Sonstiges")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {errorData.type_of_current_activity && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("RequiredField")}
          </Typography>
        )}

        <Grid container style={{ marginTop: 15 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                defaultValue={
                  professionalData.license_for_registered_doctor === undefined
                    ? ""
                    : professionalData.license_for_registered_doctor
                }
                value={
                  professionalData.license_for_registered_doctor === undefined
                    ? ""
                    : professionalData.license_for_registered_doctor
                }
                aria-label="licenseFor"
                name="customized-radios"
                onChange={handleLicenseFor}
              >
                <Typography className={classes.radioLabel}>
                  {t("LicenseFor")}*
                </Typography>
                <div>
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="yes"
                    control={<StyledRadio />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    classes={{ root: classes.radioLabel }}
                    value="no"
                    control={<StyledRadio />}
                    label={t("No")}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {errorData.license_for_registered_doctor && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("RequiredField")}
          </Typography>
        )}
        {/* {professionalData.license_for_registered_doctor === "yes" && (
          <div style={{ width: "100%" }}>
            <Typography
              className={classes.radioLabel}
              style={{ marginTop: 20 }}
            >
              {t("WhereLicenseRegistered")}*
            </Typography>

            <Grid container style={{ marginTop: 5 }} spacing={2}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Button
                  aria-controls="whereLicenseRegistered"
                  aria-haspopup="true"
                  onClick={handleClickLR}
                  id="whereLicenseRegistered"
                  disableRipple
                  className={classes.formControl}
                  classes={{ label: classes.menuLabel }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-Regular",
                        fontSize: "0.875rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        padding: 0,
                        marginLeft: 3,
                      }}
                    >
                      License Registered At
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.darkGrey,
                        textTransform: "none",
                        marginLeft: 3,
                        textAlign: "left",
                      }}
                    >
                      {professionalData.license_for_registered_doctor_at ===
                      undefined
                        ? "-"
                        : professionalData.license_for_registered_doctor_at}
                    </Typography>
                  </div>
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                </Button>

                <Menu
                  id="whereLicenseRegistered"
                  anchorEl={lrAnchorEl}
                  open={Boolean(lrAnchorEl)}
                  onClose={() => {
                    handleCloseLR();
                  }}
                  PaperProps={{
                    style: {
                      width: parseFloat(lrWidth),
                      backgroundColor: "white",
                      paddingTop: 10,

                      border: `2px solid ${theme.palette.common.main}`,
                    },
                  }}
                >
                  <div
                    onClick={() => {
                      handleCloseLR();
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: 15,
                      justifyContent: "space-between",
                      paddingRight: 15,
                      marginBottom: 10,
                    }}
                  >
                    <div>
                      <Typography
                        style={{
                          fontFamily: "MessinaSans-Regular",
                          fontSize: "0.875rem",
                          color: theme.palette.common.darkGrey,
                          textTransform: "none",
                          padding: 0,
                        }}
                      >
                        License Registered At
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "MessinaSans-SemiBold",
                          fontSize: "1rem",
                          color: theme.palette.common.darkGrey,
                          textTransform: "none",
                          marginLeft: i18next.language === "en" ? 0 : 0,
                        }}
                      >
                        {professionalData.license_for_registered_doctor_at ===
                        undefined
                          ? "-"
                          : professionalData.license_for_registered_doctor_at}
                      </Typography>
                    </div>
                    <img
                      src={ic_arrow_bottom}
                      alt="select"
                      className={classes.downArrow}
                    />
                  </div>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Baden-Württemberg")}
                  >
                    Baden-Württemberg
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Bayern")}
                  >
                    Bayern
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Berlin")}
                  >
                    Berlin
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Brandenburg")}
                  >
                    Brandenburg
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Bremen")}
                  >
                    Bremen
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Hamburg")}
                  >
                    Hamburg
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Hessen")}
                  >
                    Hessen
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Mecklenburg-Vorpommern")}
                  >
                    Mecklenburg-Vorpommern
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Niedersachsen")}
                  >
                    Niedersachsen
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Nordrhein")}
                  >
                    Nordrhein
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Rheinland-Pfalz")}
                  >
                    Rheinland-Pfalz
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Saarland")}
                  >
                    Saarland
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Sachsen")}
                  >
                    Sachsen
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Sachsen-Anhalt")}
                  >
                    Sachsen-Anhalt
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Schleswig-Holstein")}
                  >
                    Schleswig-Holstein
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Thüringen")}
                  >
                    Thüringen
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleCloseLR("Westfalen-Lippe")}
                  >
                    Westfalen-Lippe
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
            {errorData.license_for_registered_doctor_at && (
              <Typography
                className={classes.errorText}
                style={{ marginTop: 3 }}
              >
                {t("RequiredField")}
              </Typography>
            )}
          </div>
        )} */}

        <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t(
            "Wie viele Mindestsprechstunden müssen Sie für Ihren Versorgungsauftrag anbieten?"
          )}
          *
        </Typography>

        <Grid container style={{ marginTop: 10 }} spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                defaultValue={
                  professionalData.minimum_consultation_hours === undefined
                    ? ""
                    : professionalData.minimum_consultation_hours
                }
                value={
                  professionalData.minimum_consultation_hours === undefined
                    ? ""
                    : professionalData.minimum_consultation_hours
                }
                aria-label="consultationHours"
                name="customized-radios"
                onChange={(event, value) => {
                  updateProfessionalData({
                    minimum_consultation_hours: value,
                  });
                }}
              >
                <FormControlLabel
                  classes={{ root: classes.radioLabel }}
                  value="25"
                  control={<StyledRadio />}
                  label={t("Voller Versorgungsauftrag (25 Std./Woche)")}
                />
                <FormControlLabel
                  classes={{ root: classes.radioLabel }}
                  value="18.75"
                  control={<StyledRadio />}
                  label={t("Dreiviertel Versorgungsauftrag (18,75 Std./Woche)")}
                />
                <FormControlLabel
                  classes={{ root: classes.radioLabel }}
                  value="12.5"
                  control={<StyledRadio />}
                  label={t("Hälftiger Versorgungsauftrag (12,5 Std./Woche)")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {errorData.minimum_consultation_hours && (
          <Typography className={classes.errorText} style={{ marginTop: 3 }}>
            {t("RequiredField")}
          </Typography>
        )}

        {/* <Typography className={classes.radioLabel} style={{ marginTop: 20 }}>
          {t("SpecialInterest")}
        </Typography>

        <Grid container style={{ marginTop: 5 }} spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TextField
              id="specialInterest"
              label={t("SpecialInterestSingle")}
              variant="filled"
              color="primary"
              fullWidth
              value={
                professionalData.special_fields_of_interest === undefined
                  ? ""
                  : professionalData.special_fields_of_interest
              }
              onChange={(event) => {
                const text = event.target.value;
                updateProfessionalData({
                  special_fields_of_interest: text,
                });
              }}
              classes={{
                root: classes.textFields,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                  focused: "focused",
                  shrink: "shrink",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.filledInputRoot,
                  focused: classes.fieldFocused,
                  shrink: "shrink",
                },
              }}
            />
          </Grid>
        </Grid> */}
      </div>
    </React.Fragment>
  );
}
