import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: {
      Login: "Login",
      Register: "Register",
      Verify: "Verify",
      RequiredField: "This is a required field.",
      InvalidEmail: "Please enter a valid email address.",
      InvalidDate: "Please enter a valid date in DD/MM/YYYY format.",
      WelcomeToEterno: "Welcome to Eterno, ",
      StartSettingProfile: "Let's start by setting up your Eterno profile",
      ChooseProfileField: "Choose your professional field to continue.",
      GeneralPractitioner: "General Practitioner",
      Psychotherapist: "Psychotherapist",
      Psychiatrist: "Psychiatrist",
      Physiotherapists: "Physiotherapists",
      YogaTeacher: "Yoga Teacher",
      MeditationTrainer: "Meditation Trainer",
      Coach: "Coach",
      Naturopath: "Naturopath",
      DontFindCategory:
        "You don't find any category that suit you? Chat with us",
      OffentlicheInformation: "Offentliche Information",
      PrivateAddress: "Private Address",
      StreetNumber: "Street & Number",
      City: "City",
      Zip: "ZIP Code",
      Country: "Country",
      BusinessAddress: "Business Address",
      LanguagesYouSpeak: "Languages you speak?",
      SelectLanguage: "Select Language",
      AddAnotherLanguage: "Add another language",
      WhyEterno: "Why do you consider joining Eterno?",
      WriteText: "Write text here",
      PlannedWorkingHours: "Planned working hours?",
      TenHours: "Up to ten hours per week (0,25)",
      TwentyHours: "Over ten to 20 hours per week (0,50)",
      ThirtyHours: "Over 20 to 30 hours per week (0,75)",
      OverThirty: "Over 30 hours per week (1,0)",
      Career: "Career",
      From: "From",
      To: "To",
      Employeer: "Employer",
      ProfessionalExperience: "Professional Experience",
      LicensedDoctor: "I am a Licensed Doctor?",
      Yes: "Yes",
      No: "No",
      ReceivedApprobation: "I received my approbation in:",
      HowLongLicense: "How long do you have your license",
      FurtherTrainer:
        "Have you completed further training to become a specialist?",
      WhenGraduate: "When did you graduate?",
      ExpectGraduate: "When do you expect to graduate?",
      HistorySince: "Medical history since",
      SelectOtherSubjects: "Select other subject areas",
      AreaExpertise: "Area of Expertise",
      SelectSubject: "Select subject areas",
      AdditionalMedicalDesignations: "Additional Medical Designations",
      SelectAdditionalDesignations: "Select additional designations",
      SelectOtherAdditionalDesignations: "Select other additional designations",
      CurrentActivity: "Type of current medical activity",
      SpecialInterest: "Do you have special fields of interests?",
      SpecialInterestSingle: "Special Interest",
      LicenseFor: "I have a license for registered doctor",
      WhereLicenseRegistered:
        "Where is your license for registered doctor registered?",
      Back: "Back",
      Continue: "Continue",
      SaveForLater: "Save for later",
      StateRecognizedTraining:
        "Do you have a state-recognized professional training?",
      TreatmentFocus: "What is your treatment focus?",
      WhereWasPermitIssues: "Where was your professional permit issued:",
      WhenWasPermitIssued: "When was your professional permit issued?",
      CashRegisterSeat: "Do you have a cash register seat?",
      NeedCashRegister: "I need a cash register seat",
      WhereHeadOffice:
        "Where is your head office in the area of ​​the Association of Statutory Health Insurance Physicians (KV)?",
      MobileService: "Would you also like to offer mobile services?",
      MinimumConsultationHours:
        "How many minimum consultation hours do you have to offer for your supply assignment?",
      WhatTreatmentFocus: "What is your treatment focus?",
      Supervision: "Do you offer supervision?",
      CoupleTherapy: "Do you offer couples therapy?",
      CoachingServices: "Do you offer coaching services?",
      WhatMainFocus: "What is your main focus?",
      BasicQualification: "Do you have a recognized basic qualification?",
      MedicalExaminer: "Did you have a medical examiner?",
      HealthDepartment: "Health Department",
      WhichHealthDepartment:
        "Which responsible health department carried out the check?",
      WhenReviewed: "When was the review carried out?",
      TraditionalChineseMedicine: "Do you offer traditional Chinese medicine?",
      WhereBasicQualification:
        "Where did you get your recognized basic qualification?",
      WhenBasicQualification:
        "When did you receive your recognized basic qualification?",
      HealthInsuranceApproval:
        "Do you have health insurance approval or have you been certified by the central testing center for prevention?",
      KindOfYoga: "What kind of yoga do you offer?",
      TellSomething: "Tell something about you",
      AddFirstService: "Add my first service",
      NoService: "You don't have any services yet",
      YourServices: "Your Services",
      UploadImage: "Upload your profile image",
      MidWives: "Mid Wives",
      AnotherCareer: "Add another career",
      KindOfMeditation: "What kind of meditation do you offer?",
      RequiredDocuments: "Required documents",
      RequiredFields: "All the fields with * are required",
      AllFieldsRequiredInSection: "All fields in this section are required.",
      EnterValidDate: "Please enter valid date",
      EmailAlreadyExists: "This email address is already in use.",
    },
  },
  de: {
    translation: {
      Login: "Anmeldung",
      Register: "Registrieren",
      Verify: "Verify",
      WelcomeToEterno: "Willkommen bei Eterno, ",
      StartSettingProfile:
        "Um lhr Profil veroffentlichen zu konnen, mussen wir einige Informationen verifizieren.",
      ChooseProfileField:
        "Sollten Sie nicht alle erforderlichen Dokumente zur Hand haven, konnen Sie lhre Daten speichern und spater fortfahren.",
      GeneralPractitioner: "Arzt:in",
      Psychotherapist: "Psychotherapeut",
      Psychiatrist: "Hebamme",
      Physiotherapists: "Physiotherapeut",
      YogaTeacher: "Yoga Lehrer",
      MeditationTrainer: "Meditation Trainer",
      Coach: "Coach",
      Naturopath: "Heilpraktiker",
      DontFindCategory:
        "You don't find any category that suit you? Chat with us",
      OffentlicheInformation: "Offentliche Information",
      PrivateAddress: "Private Adresse",
      StreetNumber: "Street & Number",
      City: "City",
      Zip: "ZIP Code",
      Country: "Country",
      BusinessAddress: "Geschäftliche Anschrift",
      LanguagesYouSpeak: "Sprachen, in denen Sie arbeiten?",
      SelectLanguage: "Select Language",
      AddAnotherLanguage: "Weitere Sprachen hinzufugen",
      WhyEterno: "Was erwarten Sie von Eterno?",
      WriteText: "Hier text eingeben",
      PlannedWorkingHours: "Was ist Ihre geplante Arbeitszeit pro Woche",
      TenHours: "bis zehn Stunden pro Woche (0,25)",
      TwentyHours: "über zehn bis 20 Stunden pro Woche (0,50)",
      ThirtyHours: "über 20 bis 30 Stunden pro Woche (0,75)",
      OverThirty: "über 30 Stunden pro Woche (1,0)",
      Career: "Werdegang Ihrer Tätigkeiten?",
      From: "Von",
      To: "Bis",
      Employeer: "Arbeitgeber",
      ProfessionalExperience: "Berufserfahrung",
      LicensedDoctor: "Sind Sie ein Approbierter Arzt?",
      Yes: "Ja",
      No: "Nein",
      ReceivedApprobation:
        "Wo wurde Ihre Approbation bzw. Berufserlaubnis ausgestellt:",
      HowLongLicense:
        "In welchem Jahr haben Sie Ihre ärztlichen Approbation erhalten",
      FurtherTrainer:
        "Haben Sie eine Weiterbildung zum Facharzt abgeschlossen?",
      WhenGraduate: "Wann haben Sie Ihren Abschluss abgeschlossen?",
      ExpectGraduate: "Wann erwarten Sie Ihren Abschluss abzuschließen?",
      HistorySince: "Dauer Ihrer ärztlichen Tätigkeit",
      SelectOtherSubjects: "Weitere Fachrichtungen auswahlen",
      AreaExpertise: "Ärztliche Fachrichtung",
      SelectSubject: "Fachrichtungen auswahlen",
      AdditionalMedicalDesignations: "Ärztliche Zusatzbezeichnungen",
      SelectAdditionalDesignations: "Zusatzbezeichnungen auswählen",
      SelectOtherAdditionalDesignations:
        "Weitere auswählen Zusatzbezeichnungen",
      CurrentActivity: "Form der aktuellen ärztlichen Tätigkeit",
      SpecialInterest: "Haben Sie besondere Interessen & Tätigkeitsfelder? ",
      SpecialInterestSingle: "Besonderes Interesse",
      LicenseFor: "Habe Sie einen Kassensitz?",
      WhereLicenseRegistered:
        "Wo befindet sich Ihr Kassensitz in Bereich der Kassenärztlichen Vereinigung (KV)?",
      StateRecognizedTraining:
        "Haben Sie eine staatlich anerkannte Berufsausbildung?",
      TreatmentFocus: "Wo liegt Ihr Behandlungsschwerpunkt?",
      WhereWasPermitIssues: "Wo wurde Ihre Berufserlaubnis ausgestellt:",
      WhenWasPermitIssued: "Wann wurde Ihre Berufserlaubnis ausgestellt?",
      CashRegisterSeat: "Habe Sie einen Kassensitz?",
      NeedCashRegister: "Ich benötige einen Kassensitz",
      WhereHeadOffice:
        "Wo befindet sich Ihr Kassensitz in Bereich der Kassenärztlichen Vereinigung (KV)?",
      MobileService: "Möchten Sie auch mobile Dienstleistungen anbieten?",
      MinimumConsultationHours:
        "Wie viele Mindestsprechstunden müssen Sie für Ihren Versorgungsauftrag anbieten?",
      WhatTreatmentFocus: "Wo liegt Ihr Behandlungsschwerpunkt?",
      Supervision: "Bieten Sie eine Supervision an?",
      CoupleTherapy: "Bieten Sie eine Paartherapie an?",
      CoachingServices: "Bieten Sie Coaching Leistungen an?",
      WhatMainFocus: "Wo liegt Ihr Schwerpunkt?",
      BasicQualification: "Haben Sie eine anerkannte Grundqualifikation?",
      MedicalExaminer: "Hatten Sie eine amtsärztlichen Überprüfung?",
      WhichHealthDepartment:
        "Welches zuständige Gesundheitsamt hatte die Überprüfung durchgeführt?",
      WhenReviewed: "Wann wurde die Überprüfung  durchgeführt? ",
      TraditionalChineseMedicine:
        "Bieten Sie Traditionelle Chinesische Medizin an?",
      WhereBasicQualification:
        "Wo haben Sie Ihre anerkannte Grundqualifikation erhalten? ",
      WhenBasicQualification:
        "Wann haben Sie Ihre anerkannte Grundqualifikation erhalten?",
      HealthInsuranceApproval:
        "Haben Sie eine Krankenkassenanerkennung bzw. wurden Sie von der zentralen Prüfstelle für Prävention zertifiziert?",
      KindOfYoga: "Welche Art von Yoga bieten Sie an?",
      AnotherCareer: "eine andere Karriere hinzufügen",
      RequiredDocuments: "Required documents",
      RequiredFields: "All the fields with * are required",
      AllFieldsRequiredInSection: "All fields in this section are required.",
      EnterValidDate: "Please enter valid date",
      EmailAlreadyExists: "This email address is already in use.",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: "de",
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
