import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import clsx from "clsx";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  findButton: {
    ...theme.button,
    backgroundColor: "white",
  },
  buttonText: {
    ...theme.typography.buttonText,
    color: theme.palette.common.darkGrey,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  mediumBrown: {
    border: `solid 2px ${theme.palette.common.mediumBrown}`,
    "&:hover": {
      backgroundColor: "white",
    },
  },
  main: {
    border: `solid 2px ${theme.palette.common.main}`,
    "&:hover": {
      backgroundColor: "white",
    },
  },
}));

export default function OutlinedButton({
  text,
  color,
  onPress,
  fullWidth,
  height,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Button
      variant="outlined"
      disableElevation
      fullWidth={fullWidth === true ? true : false}
      onClick={() => {
        onPress();
      }}
      classes={{
        root: clsx(
          classes.findButton,
          color === "mediumBrown" ? classes.mediumBrown : classes.main
        ),
        label: classes.buttonText,
      }}
      style={{ height: height ? height : null }}
    >
      {text}
    </Button>
  );
}
