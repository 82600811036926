import React, { useContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import uncheck from "../assets/Icons/uncheck.svg";
import check from "../assets/Icons/check.svg";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { Context as AuthContext } from "../context/AuthContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";

import usePrismicStatic from "../hooks/usePrismicStatic";

const languageMap = {
  en: "en-us",
  de: "de-de",
};

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    maxWidth: theme.layout.contentWidth,

    margin: "0 auto",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
    },
  },
  mainHeading: {
    ...theme.typography.HL1,
  },
  heading: {
    ...theme.typography.HL2,
  },
  smallHeading: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1.5rem",
    textTransform: "none",

    color: theme.palette.common.darkGrey,
  },

  subHeading: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    textTransform: "none",
    whiteSpace: "pre-line",
    color: theme.palette.common.darkGrey,
  },
}));

export default function Imprint() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [content, setContent] = useState({
    "en-us": {},
    "de-de": {},
  });
  const [dataSet, setDataSet] = useState(false);

  const { prismicData, isPremismicLoading, prismicError } = usePrismicStatic();

  useEffect(() => {
    if (prismicData) {
      console.log(prismicData);
      let results = prismicData.results;
      let c = content;

      results.map((result) => {
        if (
          result.type === "static_pages" &&
          result.data.application === "Practitioners" &&
          result.data.page === "Imprint"
        ) {
          //language data
          c[result.lang] = result.data;

          //language benefits
        }
      });

      setContent(c);
      setDataSet(true);
    }
  }, [prismicData]);

  let lan = languageMap[i18n.language];
  let mainData = content[lan];

  console.log(mainData);

  return (
    <React.Fragment>
      <div className={classes.contentContainer}>
        <Typography className={classes.mainHeading}>
          {dataSet && mainData.main_heading[0].text}
        </Typography>

        {dataSet &&
          mainData.page_content.map((content) => {
            if (content.type === "heading2") {
              return (
                <Typography
                  className={classes.heading}
                  style={{ marginTop: 36 }}
                >
                  {content.text}
                </Typography>
              );
            } else if (content.type === "paragraph") {
              return (
                <Typography
                  className={classes.subHeading}
                  style={{ marginTop: 10 }}
                >
                  {content.text}
                </Typography>
              );
            } else if (content.type === "heading3") {
              return (
                <Typography
                  className={classes.smallHeading}
                  style={{ marginTop: 28 }}
                >
                  {content.text}
                </Typography>
              );
            }
          })}
      </div>
    </React.Fragment>
  );
}
