import useSWR from "swr";
import { fetcher } from "../utils/helpers";

export default function usePrismicRef() {
  const { data, error } = useSWR(
    [
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_PRISMIC_REPO}${process.env.REACT_APP_PRISMIC_BASE_URL}?access_token=${process.env.REACT_APP_PRISMIC_ACCESS_TOKEN}`,
      null,
    ],
    fetcher
  );

  return {
    ref: data,
    isRefLoading: !error && !data,
    refError: error,
  };
}
