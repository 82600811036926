/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:ca6bcbd3-2364-4c4c-82e6-56085741c491",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_jmMy463Nu",
    "aws_user_pools_web_client_id": "6e2jl26r4g23miio08d24pl066",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "eternodoctor-20210802104830-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://eternodoctor-20210802104830-hostingbucket-dev.s3-website.eu-central-1.amazonaws.com"
};


export default awsmobile;
