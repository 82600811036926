import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import Router from "./components/Router";
import { Provider as AuthProvider } from "./context/AuthContext";
import { Provider as OnboardingProvider } from "./context/OnboardingContext";
import { Auth } from "aws-amplify";

function App() {
    // Auth.signOut();
  return (
    <ThemeProvider theme={theme}>
      <OnboardingProvider>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </OnboardingProvider>
    </ThemeProvider>
  );
}

export default App;
