import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";

import close from "../../assets/Icons/close_grey.svg";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    backgroundColor: "rgba(76, 114, 109, 0.8)",
  },
  text: {
    ...theme.typography.content,
  },
  title: {
    ...theme.typography.HL1,
    paddingTop: 0,
    position: "absolute",
    marginTop: 23,
  },
}));

export default function DialogBox({ text, open, onClose, title }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        root: classes.dialogRoot,
      }}
      PaperProps={{
        style: {
          backgroundColor: "white",
          minHeight: 250,
          width: "90%",
          maxWidth: 500,
        },
      }}
    >
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          <img
            src={close}
            style={{ width: 30, height: 30, objectFit: "contain" }}
          />
        </Button>
      </DialogActions>
      <DialogTitle
        id="alert-dialog-title"
        classes={{
          root: classes.title,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          classes={{
            root: classes.text,
          }}
        >
          {text}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
