import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import logo from "../assets/logo.svg";

import ic_arrow_bottom from "../assets/Icons/ic_arrow_bottom_white.svg";
import ic_facebook from "../assets/Icons/ic_facebook.svg";
import ic_instagram from "../assets/Icons/ic_instagram.svg";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import i18next from "i18next";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { useTranslation } from "react-i18next";
import usePrismicFooter from "../hooks/usePrismicFooter";

const languageMap = {
  en: "en-us",
  de: "de-de",
};

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    backgroundColor: theme.palette.common.main,
    marginTop: 170,
    [theme.breakpoints.down("xs")]: {
      marginTop: 120,
    },
  },
  footerCopyright: {
    width: "100%",
    backgroundColor: theme.palette.common.main,
    display: "flex",
    justifyContent: "center",
  },
  copyright: {
    color: "white",
    fontSize: "0.875rem",
    lineHeight: "2.5rem",
    paddingTop: 20,
    paddingBottom: 20,
    fontFamily: "MessinaSans-Regular",
  },
  logo: {
    height: "1.7em",
    marginTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down("xs")]: {
      marginTop: 30,
      marginBottom: 30,
    },
  },

  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  contentContainer: {
    maxWidth: theme.layout.contentWidth,
    margin: "0 auto",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
    },
  },
  subHeading: {
    color: theme.palette.common.white,
    fontSize: "0.75rem",
    fontWeight: "500",
    lineHeight: "2.5rem",
    fontFamily: "MessinaSans-SemiBold",
  },
  subLinks: {
    color: theme.palette.common.white,
    fontSize: "1rem",
    lineHeight: "2.5rem",
    padding: 0,
    textDecoration: "none",
    fontFamily: "MessinaSans-Regular",
  },
  placeholder: {
    width: "100%",
    height: 200,
    backgroundColor: "#dfdfdf",
  },
  placeholder2: {
    width: "100%",
    height: 200,
    backgroundColor: "red",
  },
  placeholder3: {
    width: "100%",
    height: 200,
    backgroundColor: "orange",
  },
  formControl: {
    width: "100%",
    paddingTop: 14,
    paddingBottom: 14,
    border: "2px solid white",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  socialContainer: {
    marginTop: 30,
  },
  socialIconContainer: {
    padding: 0,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  socialicon: {
    height: 30,
    width: 30,
  },
  socialRoot: {
    minWidth: 0,
  },
  formRoot: {
    backgroundColor: "transparent",
  },
  menuLabel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 10,
    marginRight: 10,
    alignItems: "center",
  },
  downArrow: {
    height: "0.75em",
  },
  lanugageMenu: {
    width: 310,
    backgroundColor: theme.palette.common.main,
    paddingTop: 10,
    marginTop: -10,
    border: "2px solid white",
    [theme.breakpoints.down("md")]: {
      width: 385,
    },
    [theme.breakpoints.down("sm")]: {
      width: 250,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  menuItem: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    color: theme.palette.common.white,
    textTransform: "none",
  },
  footerLine: {
    width: "100%",
    height: 1,
    backgroundColor: theme.palette.common.white,
    marginTop: 40,
    opacity: 0.15,
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [content, setContent] = useState({
    "en-us": {},
    "de-de": {},
  });

  const [aboutMenu, setAboutMenu] = useState({
    "en-us": {},
    "de-de": {},
  });

  const [legalMenu, setLegalMenu] = useState({
    "en-us": {},
    "de-de": {},
  });

  const [dataSet, setDataSet] = useState(false);

  const { prismicData, isPremismicLoading, prismicError } = usePrismicFooter();

  useEffect(() => {
    if (prismicData) {
      console.log(prismicData);
      let results = prismicData.results;
      let c = content;
      let am = aboutMenu;
      let lm = legalMenu;

      results.map((result) => {
        if (
          result.type === "footer" &&
          result.data.application === "Practitioners"
        ) {
          //language data
          c[result.lang] = result.data;

          //About Menu

          let aboutNavMenuId = result.data.about_eterno.id;
          let aboutNavMenuObjArray = results.filter(
            (results) => results.id == aboutNavMenuId
          );
          let aboutNavMenuObj = aboutNavMenuObjArray[0];
          let aboutNavMenuItemsObjs = aboutNavMenuObj.data.items;

          let aboutNavMenu = [];
          aboutNavMenuItemsObjs.map((menuItem) => {
            let linkType = "internal";
            let externalLink = null;
            let internalLink = null;

            if (menuItem.external_link["id"] !== undefined) {
              linkType = "external";
              let externalLinkArr = results.filter(
                (results) => results.id == menuItem.external_link.id
              );
              externalLink = externalLinkArr[0].data;
            } else if (menuItem.internal_link["id"] !== undefined) {
              linkType = "internal";
              let externalLinkArr = results.filter(
                (results) => results.id == menuItem.internal_link.id
              );
              internalLink = externalLinkArr[0].data;
            }

            aboutNavMenu.push({
              type: linkType,
              link: linkType === "internal" ? internalLink : externalLink,
            });
          });
          am[result.lang] = aboutNavMenu;

          //Legal Menu

          let legalNavMenuId = result.data.legal.id;
          let legalNavMenuObjArray = results.filter(
            (results) => results.id == legalNavMenuId
          );
          let legalNavMenuObj = legalNavMenuObjArray[0];
          let legalNavMenuItemsObjs = legalNavMenuObj.data.items;

          let legalNavMenu = [];
          legalNavMenuItemsObjs.map((menuItem) => {
            let linkType = "internal";
            let externalLink = null;
            let internalLink = null;

            if (menuItem.external_link["id"] !== undefined) {
              linkType = "external";
              let externalLinkArr = results.filter(
                (results) => results.id == menuItem.external_link.id
              );
              externalLink = externalLinkArr[0].data;
            } else if (menuItem.internal_link["id"] !== undefined) {
              linkType = "internal";
              let externalLinkArr = results.filter(
                (results) => results.id == menuItem.internal_link.id
              );
              internalLink = externalLinkArr[0].data;
            }

            legalNavMenu.push({
              type: linkType,
              link: linkType === "internal" ? internalLink : externalLink,
            });
          });
          lm[result.lang] = legalNavMenu;
        }
      });

      setContent(c);
      setAboutMenu(am);
      setLegalMenu(lm);

      setDataSet(true);
    }
  }, [prismicData]);

  const handleClose = (language = "") => {
    setAnchorEl(null);
    if (language !== "") {
      i18next.changeLanguage(language, (err, t) => {
        if (err) return console.log("something went wrong loading", err);
      });
    }
  };

  let lan = languageMap[i18n.language];
  let mainData = content[lan];
  let aboutMenuData = aboutMenu[lan];
  let legalMenuData = legalMenu[lan];
  console.log("mainData->",mainData);
  console.log(aboutMenuData);
  console.log(legalMenuData);
  return (
    <React.Fragment>
      <div className={classes.footer}>
        <div className={classes.contentContainer}>
          {dataSet && (
            <Button
              component={Link}
              to="/"
              className={classes.logoContainer}
              disableRipple
            >
              <img
                src={mainData.logo.url}
                alt="Eterno"
                className={classes.logo}
              />
            </Button>
          )}

          <Grid container spacing={1}>
            <Grid item lg={3} md={4} sm={4} xs={6}>
              <Grid container direction="column">
                <Grid item className={classes.subHeading}>
                  { dataSet ? mainData.about_heading[0].text : "ABOUT ETERNO"}
                </Grid>

                {dataSet &&
                  aboutMenuData.map((dataObj) => {
                    if (dataObj.type === "internal") {
                      return (
                        <Grid
                          component={Link}
                          to={dataObj.link.path[0].text}
                          item
                          className={classes.subLinks}
                          onClick={() => {}}
                        >
                          {dataObj.link.title[0].text}
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid
                          component={Link}
                          item
                          className={classes.subLinks}
                          onClick={() => {
                            window.location.href = dataObj.link.path[0].text;
                          }}
                        >
                          {dataObj.link.title[0].text}
                        </Grid>
                      );
                    }
                  })}
              </Grid>
            </Grid>

            <Grid item lg={3} md={4} sm={4} xs={6}>
              <Grid container direction="column">
                <Grid item className={classes.subHeading}>
                  {dataSet ? mainData.legal_heading[0].text : "LEGAL"}
                </Grid>

                {dataSet &&
                  legalMenuData.map((dataObj) => {
                    if (dataObj.type === "internal") {
                      return (
                        <Grid
                          component={Link}
                          to={dataObj.link.path[0].text}
                          item
                          className={classes.subLinks}
                          onClick={() => {}}
                        >
                          {dataObj.link.title[0].text}
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid
                          component={Link}
                          item
                          className={classes.subLinks}
                          onClick={() => {
                            window.location.href = dataObj.link.path[0].text;
                          }}
                        >
                          {dataObj.link.title[0].text}
                        </Grid>
                      );
                    }
                  })}
              </Grid>
            </Grid>
            <Hidden mdDown>
              <Grid item md={3}></Grid>
            </Hidden>

            <Grid item lg={3} md={4} sm={4} xs={12} justify="flex-end">
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                // onClick={() => {
                //   if (i18next.language === "en") {
                //     i18next.changeLanguage("de", (err, t) => {
                //       if (err)
                //         return console.log("something went wrong loading", err);
                //     });
                //   } else {
                //     i18next.changeLanguage("en", (err, t) => {
                //       if (err)
                //         return console.log("something went wrong loading", err);
                //     });
                //   }
                // }}
                disableRipple
                className={classes.formControl}
                classes={{ label: classes.menuLabel }}
              >
                <div>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-Regular",
                      fontSize: "0.875rem",
                      color: theme.palette.common.white,
                      textTransform: "none",
                      padding: 0,
                    }}
                  > 
                    { dataSet ? mainData.language_label[0].text : "Language"}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "MessinaSans-SemiBold",
                      fontSize: "1rem",
                      color: theme.palette.common.white,
                      textTransform: "none",
                      marginLeft: i18next.language === "en" ? -10 : -5,
                    }}
                  >            {
                    dataSet ? mainData.language_values.map((val) => (
                     i18next.language === val.language_code &&

                 <Typography
                   style={{
                     fontFamily: "MessinaSans-SemiBold",
                     fontSize: "1rem",
                     color: theme.palette.common.white,
                     textTransform: "none",
                     marginLeft: i18next.language === "en" ? 0 : 0,
                   }}
                 >
                 {val.display_value[0].text}

                   </Typography>
                    )) :

                    <Typography
                    style={{
                      fontFamily: "MessinaSans-SemiBold",
                      fontSize: "1rem",
                      color: theme.palette.common.white,
                      textTransform: "none",
                      marginLeft: i18next.language === "en" ? 0 : 0,
                    }}
                  >
                    { i18next.language === "en" 
                       ? 
                     "English" 
                       :
                      "German"}
                   
                 </Typography>
                   }
                  </Typography>
                </div>
                <img
                  src={ic_arrow_bottom}
                  alt="select"
                  className={classes.downArrow}
                />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  handleClose();
                }}
                classes={{
                  paper: classes.lanugageMenu,
                }}
              >
                <div
                  onClick={() => {
                    handleClose();
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: 15,
                    justifyContent: "space-between",
                    paddingRight: 15,
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        fontFamily: "MessinaSans-Regular",
                        fontSize: "0.875rem",
                        color: theme.palette.common.white,
                        textTransform: "none",
                        padding: 0,
                      }}
                    >
                    { dataSet ? mainData.language_label[0].text : "Language"}
                    </Typography>

                    {
                       dataSet ? mainData.language_values.map((val) => (
                        i18next.language === val.language_code &&

                    <Typography
                      style={{
                        fontFamily: "MessinaSans-SemiBold",
                        fontSize: "1rem",
                        color: theme.palette.common.white,
                        textTransform: "none",
                        marginLeft: i18next.language === "en" ? 0 : 0,
                      }}
                    >
                    {val.display_value[0].text}

                      </Typography>
                       )) :

                       <Typography
                       style={{
                         fontFamily: "MessinaSans-SemiBold",
                         fontSize: "1rem",
                         color: theme.palette.common.white,
                         textTransform: "none",
                         marginLeft: i18next.language === "en" ? 0 : 0,
                       }}
                     >
                       { i18next.language === "en" 
                          ? 
                        "English" 
                          :
                         "German"}
                      
                    </Typography>
                      }
                  </div>
                  <img
                    src={ic_arrow_bottom}
                    alt="select"
                    className={classes.downArrow}
                  />
                </div>
                {
                  dataSet ? mainData.language_values.map((val) => (
                    <MenuItem
                    className={classes.menuItem}
                    onClick={() => handleClose(val.language_code)}
                  >
                    {val.display_value[0].text}
                  </MenuItem>
                  )) :
            <div className="">
            <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose("en")}
                >
                  English
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleClose("de")}
                >
                  German
                </MenuItem>
            </div>
                }
              </Menu>

              <Grid
                container
                direction="row"
                className={classes.socialContainer}
                justify={matches ? "center" : "flex-end"}
              >
                <Button
                  href={dataSet ? mainData.fb_url.url : "/"}
                  target="_blank"
                  className={classes.socialIconContainer}
                  disableRipple
                  classes={{
                    root: classes.socialRoot,
                  }}
                >
                  <img
                    src={ic_facebook}
                    alt="Facebook"
                    className={classes.socialicon}
                  />
                </Button>
                <Button
                  href={dataSet ? mainData.instagram_url.url : "/"}
                  target="_blank"
                  className={classes.socialIconContainer}
                  disableRipple
                  classes={{
                    root: classes.socialRoot,
                  }}
                  style={{ marginLeft: 20 }}
                >
                  <img
                    src={ic_instagram}
                    alt="Instagram"
                    className={classes.socialicon}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.footerLine}></div>
        </div>
      </div>
      <div className={classes.footerCopyright}>
        <Typography className={classes.copyright}>
          Copyright © 2020 Eterno GmbH
        </Typography>
      </div>
    </React.Fragment>
  );
}
