import React, { useContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import placeholderIcon from "../assets/images/placeholder.png";
import ic_arrow_bottom from "../assets/Icons/ic_arrow_bottom.svg";
import ic_check from "../assets/Icons/ic_check.svg";
import uncheck from "../assets/Icons/uncheck.svg";
import check from "../assets/Icons/check.svg";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import i18next from "i18next";

import consultant from "../assets/Illustrations/illustration-doctor.svg";
import progressbar from "../assets/images/progressbar.svg";
import { Context as AuthContext } from "../context/AuthContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";

import useOnboarding from "../hooks/useOnboarding";

import { processAPIWithHeaders } from "../utils/helpers";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    "&.focused": {
      color: theme.palette.common.darkGrey,
    },
    "&.shrink": {
      fontSize: "1rem",
    },
  },

  contentContainer: {
    maxWidth: theme.layout.contentWidth,

    margin: "0 auto",
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
    },
  },
  cardContent: {
    backgroundColor: "white",

    maxWidth: theme.layout.contentWidth,
    boxShadow: "5px 10px 40px rgba(120, 120, 120, 0.22)",
    borderRadius: 8,
    display: "block",
    paddingTop: 34,
    paddingBottom: 27,
    paddingLeft: 30,
    paddingRight: 30,
  },
  cardContentProfile: {
    backgroundColor: "white",

    maxWidth: theme.layout.contentWidth,
    boxShadow: "5px 10px 40px rgba(120, 120, 120, 0.22)",
    borderRadius: 8,
    display: "block",

    paddingBottom: 27,
  },
  placeholder: {
    backgroundColor: "blue",
    height: 100,
    width: "100%",
  },

  mainContent: {
    width: "100%",
    height: 250,
    backgroundColor: "#d8d8d8",
    borderRadius: 7,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    ...theme.typography.HL2,
  },

  contentText: {
    ...theme.typography.content,
  },
  contentTextHead: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1.25rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.brown,
    width: "100%",
    textAlign: "center",
  },
  contentTextBot: {
    ...theme.typography.content,
    width: "100%",
    textAlign: "center",
  },
  checkMark: {
    width: "0.875em",
    height: "0.875em",
    marginTop: 2,
  },
  icon: {
    width: "3.75em",
    height: "3.75em",
  },
  registrationForm: {
    backgroundColor: "white",

    width: "100%",
    boxShadow: "5px 10px 40px rgba(120, 120, 120, 0.22)",
    borderRadius: 8,
  },
  registrationFormContent: {
    marginLeft: 50,
    marginRight: 50,
    paddingTop: 43,
    paddingBottom: 43,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
      marginRight: 30,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 23,
      paddingBottom: 23,
    },
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  downArrow: {
    height: "0.75em",
  },
  dropdownFilled: {
    backgroundColor: theme.palette.common.lightBrown,
  },
  form: {
    marginTop: 23,
  },
  textFields: {
    height: "3.75em",
  },
  textFieldsError: {
    height: "3.75em",
    boxShadow: "0 0 0 2px #da3030",
  },
  dropDownContainer: {
    display: "flex",
    flexDirection: "row",
  },
  dropDownRoot: {
    "&:hover": {
      backgroundColor: theme.palette.common.lightBrown,
    },
  },
  blackCircle: {
    backgroundColor: theme.palette.common.darkGrey,
    width: 7,
    height: 7,
    borderRadius: 5,
    marginRight: 5,
  },
  greenCircle: {
    backgroundColor: "#5ba668",
    width: 7,
    height: 7,
    borderRadius: 5,
    marginRight: 5,
  },
  passwordText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
  },
  passwordValidText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.brown,
  },
  submitButton: {
    height: "3.75em",
    backgroundColor: theme.palette.common.yellow,
    marginTop: 18,
    "&:hover": {
      backgroundColor: theme.palette.common.yellow,
    },
  },
  submitButtonBrown: {
    height: "3.75em",
    backgroundColor: theme.palette.common.brown,
    marginTop: 18,
    "&:hover": {
      backgroundColor: theme.palette.common.brown,
    },
  },
  buttonText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
  },
  alreadyMember: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
    width: "100%",
    textAlign: "center",
  },
  check: {
    widht: 30,
    height: 30,
  },
  contentTextLink: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.darkGrey,
  },
  dropdownStyle: {
    backgroundColor: theme.palette.common.lightBrown,
    marginTop: 50,
  },
  errorText: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "none",
    lineHeight: "1.5rem",
    color: theme.palette.common.red,
  },
  fieldFocused: {
    backgroundColor: theme.palette.common.lightBrown,
    boxShadow: "0 0 0 2px #ffce2d",
  },
  filledInputRoot: {
    overflow: "hidden",
    backgroundColor: theme.palette.common.lightBrown,
    paddingRight: 10,
    borderRadius: 5,
    fontFamily: "MessinaSans-Regular",
    fontSize: "1rem",
    height: "100%",
    "&:hover": {
      backgroundColor: "rgba(168,160,146,0.2)",
      //backgroundColor: "red",
    },
  },
  dropDownBackground: {
    backgroundColor: theme.palette.common.lightBrown,
  },
  downArrow: {
    height: "0.75em",
  },
  formControl: {
    width: "100%",
    backgroundColor: theme.palette.common.lightBrown,
    paddingTop: 14,
    paddingBottom: 14,
    height: "4.3em",
    marginBottom: 15,
    justifyContent: "space-between",
    // [theme.breakpoints.down("md")]: {
    //   width: 150,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: 100,
    // },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
    borderRadius: 5,
    "&:hover": {
      backgroundColor: theme.palette.common.lightBrown,
    },
  },
  customWidth: {
    "& div": {
      // this is just an example, you can use vw, etc.
      width: "100%",
    },
  },
  dropDownMenuPopup: {
    //width: 200,
    backgroundColor: theme.palette.common.lightBrown,
    paddingTop: 10,

    border: "2px solid #ffce2d",
    // [theme.breakpoints.down("md")]: {
    //   width: 150,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: 100,
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "82%",
    // },
  },
  menuItem: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    textTransform: "none",
  },
  contentMainText: {
    fontFamily: "MessinaSans-Regular",
    fontSize: "1.125rem",
    textTransform: "none",
    lineHeight: "1.75rem",
    color: theme.palette.common.darkGrey,
  },
  contentMainHeading: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1.125rem",
    textTransform: "none",
    lineHeight: "1.75rem",
    color: theme.palette.common.darkGrey,
  },
  emptyClassesImg: {
    width: 125,
    height: 125,
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: 200,
      height: 200,
    },
  },
  progressbarImg: {
    width: "100%",
    objectFit: "contain",
  },
  profileImageContainer: {
    width: "100%",
    height: 200,
    backgroundColor: theme.palette.common.brown,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editButton: {
    background: "transparent",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonLabel: {
    padding: 0,
    justifyContent: "left",
  },
  editLinks: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "1rem",
    textTransform: "none",
    textDecorationLine: "underline",
    color: theme.palette.common.darkGrey,
  },
}));

export default function Dashboard() {
  // React.useEffect(() => {
  //   history.replace("/dashboard");
  // }, []);
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const classes = useStyles();

  const [gender, setGender] = React.useState("-");
  const [title, setTitle] = React.useState("-");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [dob, setDOB] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [checked, setchecked] = React.useState(false);
  const [passwordValid, setPasswordValid] = React.useState({
    characters: false,
    number: false,
    special: false,
    password: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const { state, setuser } = useContext(AuthContext);
  const [code, setCode] = React.useState("");
  const [localLoading, setLocalLoading] = React.useState(false);
  const [errFirstName, setErrFirstName] = React.useState("");
  const [errLastName, setErrLastName] = React.useState("");
  const [errEmail, setErrEmail] = React.useState("");
  const [errDOB, setErrDOB] = React.useState("");
  const [errPassword, setErrPassword] = React.useState("");
  const [errConfirmPassword, setErrConfirmPassword] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [genderWidth, setGenderWidth] = useState("0");

  // const { onBoarded, isOnboardingLoading, onBoardingError } = useOnboarding({
  //   token: state.usersession.idToken.jwtToken,
  //   username: state.user.id,
  // });

  // useEffect(() => {
  //   if (!onBoardingError && !isOnboardingLoading) {
  //     console.log("onboarding:" + onBoarded);
  //     if (!onBoarded) {
  //       history.replace("/doctor-type");
  //     }
  //   }
  // }, [onBoarded]);

  const fetchOnboardingData = async () => {
    const token = state.usersession.idToken.jwtToken;
    const response = await processAPIWithHeaders(
      "secure/user/onboarding-data/" + state.user.id,
      "GET",
      {
        headers: {
          //"Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!response?.has_data) {
      history.replace("/doctor-type");
    }
  };

  // useEffect(() => {
  //   fetchOnboardingData();
  // }, []);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClick2 = (event) => {
    var w = document.getElementById("genderButton").clientWidth;
    console.log(w);
    setGenderWidth(w.toString());
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = (title = "") => {
    setAnchorEl2(null);
    if (title !== "") {
      setTitle(title);
    }
  };

  const handleClick = (event) => {
    var w = document.getElementById("genderButton").clientWidth;
    console.log(w);
    setGenderWidth(w.toString());
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (gender = "") => {
    setAnchorEl(null);
    if (gender !== "") {
      setGender(gender);
    }
  };
  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleTitleGender = (event) => {
    setTitle(event.target.value);
  };

  const hasNumber = (myString) => {
    return /\d/.test(myString);
  };

  const hasSpecial = (myString) => {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(myString);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setErrEmail("");
    setErrFirstName("");
    setErrLastName("");
    setErrDOB("");
    setErrPassword("");
    setErrConfirmPassword("");
    if (user) {
      if (code.length !== 6) {
        alert("Please enter correct code");
        return;
      }
      verifyCode();
    } else {
      let err = false;
      if (email === "") {
        setErrEmail(t("RequiredField"));
        err = true;
      } else {
        var reg =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!reg.test(email)) {
          setErrEmail(t("InvalidEmail"));
          err = true;
        }
      }

      if (firstName === "") {
        setErrFirstName(t("RequiredField"));
        err = true;
      }

      if (lastName === "") {
        setErrLastName(t("RequiredField"));
        err = true;
      }
      if (dob === "") {
        setErrDOB(t("RequiredField"));
        err = true;
      }
      if (confirmPassword === "") {
        setErrConfirmPassword(t("RequiredField"));
        err = true;
      }
      if (passwordValid.password === "") {
        setErrPassword(t("RequiredField"));
        err = true;
      }
      if (gender === "" || title === "") {
        alert("Please enter all details");
        return;
      }

      if (err) {
        return;
      }

      if (passwordValid.password !== confirmPassword) {
        alert("Both passwords should be same");
        return;
      }

      if (
        !passwordValid.characters ||
        !passwordValid.number ||
        !passwordValid.special
      ) {
        alert("Password not strong enough.");
        return;
      }

      if (!checked) {
        alert("Please agree to Privacy Policy and Terms & Conditions");
        return;
      }
      signUp();
    }
  };

  async function verifyCode() {
    console.log("verifying");
    try {
      setLocalLoading(true);
      // const result = await Auth.confirmSignUp(user.username, code);
      // console.log(result);
      signIn();
    } catch (error) {
      setLocalLoading(false);
      alert("Error verifying: " + error.message);
      console.log("error verifying:", error);
    }
  }
  async function signIn() {
    console.log("signing in");
    try {
      // const user = await Auth.signIn(email, passwordValid.password);
      // console.log(user);
      // setuser(user);
      setLocalLoading(false);
      history.replace("/dashboard");
    } catch (error) {
      setLocalLoading(false);
      alert("Error signing in: " + error.message);

      console.log("error signing in", error);
    }
  }

  async function signUp() {
    console.log("signing up");
    try {
      setLocalLoading(true);
      // const { user } = await Auth.signUp({
      //   username: email,
      //   password: passwordValid.password,
      //   attributes: {
      //     email: email, // optional
      //     "custom:firstName": firstName,
      //     "custom:lastName": lastName,
      //     "custom:gender": gender,
      //     "custom:title": title,
      //     "custom:dob": dob,
      //   },
      // });
      // console.log(user);
      // setUser(user);
      setLocalLoading(false);
    } catch (error) {
      setLocalLoading(false);
      alert("Error signing up: " + error.message);
      console.log("error signing up:", error);
    }
  }

  return (
    <React.Fragment>
      <div className={classes.contentContainer}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <div className={classes.cardContentProfile}>
              <div className={classes.profileImageContainer}>
                <Button
                  disableElevation
                  disableRipple
                  classes={{
                    root: classes.editButton,
                    label: classes.buttonLabel,
                  }}
                  onClick={() => {}}
                >
                  <Typography className={classes.editLinks}>
                    {t("UploadImage")}
                  </Typography>
                </Button>
              </div>

              <Typography
                className={classes.heading}
                style={{ marginLeft: 20, marginTop: 10 }}
              >
                Claudia Schmidt
              </Typography>

              <Typography
                className={classes.contentMainText}
                style={{ marginLeft: 20, marginTop: 10 }}
              >
                Psychiatrist
              </Typography>
            </div>
          </Grid>

          <Grid item md={8} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>
                  About Claudia
                </Typography>
                <div
                  className={classes.cardContent}
                  style={{
                    marginTop: 23,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Typography
                      className={classes.contentMainText}
                      style={{ marginBottom: 13 }}
                    >
                      Lorem ipsum dolor sit am, lorem elit consectetur. Lorem
                      ipsum dolor sit am, lorem elit consectetur
                    </Typography>

                    <Button
                      variant="contained"
                      disableElevation
                      classes={{
                        root: classes.submitButtonBrown,
                        label: classes.buttonText,
                      }}
                    >
                      {t("TellSomething")}
                    </Button>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.heading}>
                  {t("YourServices")}
                </Typography>
                <div
                  className={classes.cardContent}
                  style={{
                    marginTop: 23,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "column",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Typography
                      className={classes.contentMainText}
                      style={{ marginBottom: 13 }}
                    >
                      {t("NoService")}
                    </Typography>

                    <Button
                      variant="contained"
                      disableElevation
                      classes={{
                        root: classes.submitButton,
                        label: classes.buttonText,
                      }}
                    >
                      {t("AddFirstService")}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
