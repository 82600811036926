import { createMuiTheme } from "@material-ui/core/styles";
const darkGrey = "#2e2e2e";
const lightBrown = "#efede8";
const brown = "#a8a092";
const main = " #4C726D";

export default createMuiTheme({
  palette: {
    common: {
      darkGrey: `${darkGrey}`,
      brown: `${brown}`,
      lightBrown: `${lightBrown}`,
      yellow: "#ffce2d",
      green: "#5ba668",
      cream: "#faf9f8",
      white: "#ffffff",
      red: "#da3030",
      mediumBrown: "#cac5b4",
      main: `${main}`,
    },
    primary: {
      main: "#faf9f8",
    },
    secondary: {
      main: `${lightBrown}`,
    },
  },
  typography: {
    menu: {
      textTransform: "none",
      fontFamily: "MessinaSans-SemiBold",
      fontSize: "1rem",
    },
    mainMenu: {
      textTransform: "none",
      fontFamily: "MessinaSans-SemiBold",
      fontSize: "1.125rem",
    },
    HL1: {
      fontFamily: "MessinaSans-SemiBold",
      fontSize: "1.75rem",
      textTransform: "none",
      lineHeight: "2.187rem",
      color: `${darkGrey}`,
    },
    HL2: {
      fontFamily: "MessinaSans-Regular",
      fontSize: "1.5rem",
      textTransform: "none",
      lineHeight: "1.875rem",
      color: `${darkGrey}`,
    },
    HL3: {
      fontFamily: "MessinaSans-Regular",
      fontSize: "1.313rem",
      textTransform: "none",
      lineHeight: "1.75rem",
      color: `${darkGrey}`,
    },
    HL4: {
      fontFamily: "MessinaSans-Regular",
      fontSize: "0.75rem",
      textTransform: "none",
      lineHeight: "1rem",
      color: `${brown}`,
    },
    HL5: {
      fontFamily: "MessinaSans-SemiBold",
      fontSize: "1.313rem",
      textTransform: "none",
      lineHeight: "1.75rem",
      color: `${darkGrey}`,
    },
    BGHL1: {
      fontFamily: "MessinaSans-Regular",
      fontSize: "1rem",
      textTransform: "none",
      lineHeight: "1.5rem",
      color: `${darkGrey}`,
    },
    content: {
      fontFamily: "MessinaSerif-Regular",
      fontSize: "1rem",
      textTransform: "none",
      lineHeight: "1.5rem",
      color: `${darkGrey}`,
    },
    buttonText: {
      fontFamily: "MessinaSans-SemiBold",
      fontSize: "0.875rem",
      textTransform: "uppercase",
      lineHeight: "1.5rem",
      color: "white",
      paddingLeft: 36,
      paddingRight: 36,
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  layout: {
    contentWidth: 1280,
  },
  button: {
    height: "3.75em",
    backgroundColor: `${main}`,
    marginTop: 0,
    borderRadius: "2em",
    "&:hover": {
      backgroundColor: `${main}`,
    },
  },

  overrides: {
    MuiFormLabel: {
      root: {
        overflow: "hidden",
        backgroundColor: "transparent",
        paddingRight: 10,
        fontFamily: "MessinaSans-Regular",
        fontSize: "1rem",
        color: `${darkGrey}`,

        // "&$focused": {
        //   color: `${darkGrey}`,
        // },
      },
    },
    MuiFilledInput: {
      root: {
        overflow: "hidden",
        backgroundColor: "white",
        paddingRight: 10,
        borderRadius: 5,
        fontFamily: "MessinaSans-Regular",
        fontSize: "1rem",
        height: "100%",
        "&:hover": {
          backgroundColor: "#F9F8F4",
        },
        "&$focused": {
          backgroundColor: "white",

          boxShadow: `0 0 0 2px ${main}`,
        },
      },
    },
    MuiInputLabel: {
      filled: {
        "&$shrink": {
          transform: "translate(12px, 10px) scale(0.875)",
        },
      },
    },
  },
  props: {
    MuiFilledInput: {
      disableUnderline: true,
    },
  },
});
