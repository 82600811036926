import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  detailsButton: {
    background: "transparent",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonLabel: {
    padding: 0,
    justifyContent: "left",
  },
  detailsLinks: {
    fontFamily: "MessinaSans-SemiBold",
    fontSize: "0.875rem",
    textTransform: "uppercase",
    textDecorationLine: "underline",

    textDecorationThickness: 2,

    color: theme.palette.common.darkGrey,
    marginTop: 30,
  },
  detailsLinksNoMarginTop: {
    fontSize: "0.875rem",
    textTransform: "uppercase",
    textDecorationLine: "underline",

    textDecorationThickness: 2,

    color: theme.palette.common.darkGrey,
  },
}));

export default function UnderlinedButton({
  text,
  color,
  onPress,
  noMarginTop,
  marginTop,
  marginBottom,
  underlineColor = "#4C726D",
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Button
      disableElevation
      disableRipple
      classes={{
        root: classes.detailsButton,
        label: classes.buttonLabel,
      }}
      onClick={() => {
        onPress();
      }}
    >
      <Typography
        className={
          noMarginTop ? classes.detailsLinksNoMarginTop : classes.detailsLinks
        }
        style={{
          color: color,
          marginBottom: marginBottom ? marginBottom : null,
          textDecorationColor: underlineColor,
          marginTop: marginTop ? marginTop : null,
          textUnderlineOffset: "0.25em",
        }}
      >
        {text}
      </Typography>
    </Button>
  );
}
