const doctorTypes = [
  "GeneralPractitioner",
  "Psychotherapist",
  "MidWives",
  "Physiotherapists",
  "YogaTeacher",
  "MeditationTrainer",
  "Coach",
  "Naturopath",
];

export const validProfessionalData = (data, docId) => {
  console.log(data);
  if (docId === 0) {
    let err = { error: false };
    if (data.liscensed_doctor === undefined || data.liscensed_doctor === "") {
      err["liscense_doctor"] = true;
      err["error"] = true;
    } else {
      if (data.liscensed_doctor === "yes") {
        if (data.approbation_in === "") {
          err["approbation_in"] = true;
          err["error"] = true;
        }

        if (data.liscense_from === "") {
          err["liscense_from"] = true;
          err["error"] = true;
        }
      }
    }

    if (data.further_studies === undefined) {
      err["further_studies"] = true;
      err["error"] = true;
    } else {
      if (data.further_studies.date === "") {
        err["further_studies"] = true;
        err["error"] = true;
      }
    }

    if (
      data.medical_history_since === undefined ||
      data.medical_history_since === ""
    ) {
      err["medical_history_since"] = true;
      err["error"] = true;
    }

    if (data.area_of_expertise === undefined) {
      err["area_of_expertise"] = true;
      err["error"] = true;
    }

    if (data.area_of_expertise === undefined) {
      err["additional_medical_designations"] = true;
      err["error"] = true;
    }

    if (
      data.type_of_current_activity === undefined ||
      data.type_of_current_activity === ""
    ) {
      err["type_of_current_activity"] = true;
      err["error"] = true;
    }

    if (
      data.license_for_registered_doctor === undefined ||
      data.license_for_registered_doctor === ""
    ) {
      err["license_for_registered_doctor"] = true;
      err["error"] = true;
    } else {
      if (data.license_for_registered_doctor === "yes") {
        if (
          data.license_for_registered_doctor_at === undefined ||
          data.license_for_registered_doctor_at === ""
        ) {
          err["license_for_registered_doctor_at"] = true;
          err["error"] = true;
        }
      }
    }

    if (
      data.minimum_consultation_hours === undefined ||
      data.minimum_consultation_hours === ""
    ) {
      err["minimum_consultation_hours"] = true;
      err["error"] = true;
    }

    return err;
  } else if (docId === 1) {
    let err = { error: false };
    if (
      data.professional_training === undefined ||
      data.professional_training === ""
    ) {
      err["professional_training"] = true;
      err["error"] = true;
    } else {
      if (data.professional_training === "yes") {
        if (data.professional_permit_issued_in === "") {
          err["professional_permit_issued_in"] = true;
          err["error"] = true;
        }

        if (data.professional_permit_issued_on === "") {
          err["professional_permit_issued_on"] = true;
          err["error"] = true;
        }
      }
    }

    if (data.cash_register_seat === undefined) {
      err["cash_register_seat"] = true;
      err["error"] = true;
    } else {
      if (data.cash_register_seat.have === "yes") {
        if (
          data.cash_register_seat.head_office === undefined ||
          data.cash_register_seat.head_office === ""
        ) {
          err["cash_register_seat_head_office"] = true;
          err["error"] = true;
        }
      }
    }

    if (
      data.minimum_consultation_hours === undefined ||
      data.minimum_consultation_hours === ""
    ) {
      err["minimum_consultation_hours"] = true;
      err["error"] = true;
    }
    if (data.treatment_focus === undefined) {
      err["treatment_focus"] = true;
      err["error"] = true;
    } else {
      if (data.treatment_focus.includes("Weiteres")) {
        if (
          data.additional_treatment === undefined ||
          data.additional_treatment === ""
        ) {
          err["additional_treatment"] = true;
          err["error"] = true;
        }
      }
    }

    if (data.coaching_services === undefined) {
      err["coaching_services "] = true;
      err["error"] = true;
    } else {
      if (
        data.coaching_services.offer === "yes" &&
        data.coaching_services.services.includes("Weiteres")
      ) {
        if (
          data.additional_coaching_service === undefined ||
          data.additional_coaching_service === ""
        ) {
          err["additional_coaching_service"] = true;
          err["error"] = true;
        }
      }
    }

    if (
      data.offer_couple_therapy === undefined ||
      data.offer_couple_therapy === ""
    ) {
      err["offer_couple_therapy"] = true;
      err["error"] = true;
    }

    return err;
  } else if (docId === 2) {
    let err = { error: false };
    if (
      data.professional_training === undefined ||
      data.professional_training === ""
    ) {
      err["professional_training"] = true;
      err["error"] = true;
    } else {
      if (data.professional_training === "yes") {
        if (data.professional_permit_issued_in === "") {
          err["professional_permit_issued_in"] = true;
          err["error"] = true;
        }

        if (data.professional_permit_issued_on === "") {
          err["professional_permit_issued_on"] = true;
          err["error"] = true;
        }
      }
    }
    return err;
  } else if (docId === 3) {
    let err = { error: false };
    if (
      data.professional_training === undefined ||
      data.professional_training === ""
    ) {
      err["professional_training"] = true;
      err["error"] = true;
    } else {
      if (data.professional_training === "yes") {
        if (data.professional_permit_issued_in === "") {
          err["professional_permit_issued_in"] = true;
          err["error"] = true;
        }

        if (data.professional_permit_issued_on === "") {
          err["professional_permit_issued_on"] = true;
          err["error"] = true;
        }
      }
    }

    if (data.cash_register_seat === undefined) {
      err["cash_register_seat"] = true;
      err["error"] = true;
    } else {
      if (data.cash_register_seat.have === "yes") {
        if (
          data.cash_register_seat.head_office === undefined ||
          data.cash_register_seat.head_office === ""
        ) {
          err["cash_register_seat_head_office"] = true;
          err["error"] = true;
        }
      }
    }

    if (data.treatment_focus === undefined || data.treatment_focus === "") {
      err["treatment_focus"] = true;
      err["error"] = true;
    }

    if (
      data.offer_mobile_service === undefined ||
      data.offer_mobile_service === ""
    ) {
      err["offer_mobile_service"] = true;
      err["error"] = true;
    }

    return err;
  } else if (docId === 4) {
    let err = { error: false };
    if (
      data.recognized_basic_qualification === undefined ||
      data.recognized_basic_qualification === ""
    ) {
      err["recognized_basic_qualification"] = true;
      err["error"] = true;
    } else {
      if (data.recognized_basic_qualification === "yes") {
        if (data.qualification_from === "") {
          err["qualification_from"] = true;
          err["error"] = true;
        }

        if (data.qualification_date === "") {
          err["qualification_date"] = true;
          err["error"] = true;
        }
      }
    }

    if (
      data.health_insurance_approval === undefined ||
      data.health_insurance_approval === ""
    ) {
      err["health_insurance_approval"] = true;
      err["error"] = true;
    }

    if (data.yoga_offering === undefined || data.yoga_offering.length === 0) {
      err["yoga_offering"] = true;
      err["error"] = true;
    }

    return err;
  } else if (docId === 5) {
    let err = { error: false };
    if (
      data.recognized_basic_qualification === undefined ||
      data.recognized_basic_qualification === ""
    ) {
      err["recognized_basic_qualification"] = true;
      err["error"] = true;
    } else {
      if (data.recognized_basic_qualification === "yes") {
        if (data.qualification_from === "") {
          err["qualification_from"] = true;
          err["error"] = true;
        }

        if (data.qualification_date === "") {
          err["qualification_date"] = true;
          err["error"] = true;
        }
      }
    }

    if (
      data.health_insurance_approval === undefined ||
      data.health_insurance_approval === ""
    ) {
      err["health_insurance_approval"] = true;
      err["error"] = true;
    }

    if (
      data.meditation_offering === undefined ||
      data.meditation_offering.length === 0
    ) {
      err["meditation_offering"] = true;
      err["error"] = true;
    }

    return err;
  } else if (docId === 6) {
    let err = { error: false };
    if (
      data.recognized_basic_qualification === undefined ||
      data.recognized_basic_qualification === ""
    ) {
      err["recognized_basic_qualification"] = true;
      err["error"] = true;
    } else {
      if (data.recognized_basic_qualification === "yes") {
        if (data.qualification_from === "") {
          err["qualification_from"] = true;
          err["error"] = true;
        }

        if (data.qualification_date === "") {
          err["qualification_date"] = true;
          err["error"] = true;
        }
      }
    }

    if (
      data.health_insurance_approval === undefined ||
      data.health_insurance_approval === ""
    ) {
      err["health_insurance_approval"] = true;
      err["error"] = true;
    }

    if (
      data.coaching_services === undefined ||
      data.coaching_services.length === 0
    ) {
      err["coaching_services"] = true;
      err["error"] = true;
    } else {
      if (data.coaching_services.includes("Weiteres")) {
        if (
          data.additional_coaching_service === undefined ||
          data.additional_coaching_service === ""
        ) {
          err["additional_coaching_service"] = true;
          err["error"] = true;
        }
      }
    }

    return err;
  } else if (docId === 7) {
    let err = { error: false };
    if (
      data.recognized_basic_qualification === undefined ||
      data.recognized_basic_qualification === ""
    ) {
      err["recognized_basic_qualification"] = true;
      err["error"] = true;
    } else {
      if (data.recognized_basic_qualification === "yes") {
        if (data.qualification_from === "") {
          err["qualification_from"] = true;
          err["error"] = true;
        }

        if (data.qualification_date === "") {
          err["qualification_date"] = true;
          err["error"] = true;
        }
      }
    }

    if (
      data.have_medical_examiner === undefined ||
      data.have_medical_examiner === ""
    ) {
      err["have_medical_examiner"] = true;
      err["error"] = true;
    }

    if (data.treatment_focus === undefined || data.treatment_focus === "") {
      err["treatment_focus"] = true;
      err["error"] = true;
    }

    if (
      data.offer_chinese_medicine === undefined ||
      data.offer_chinese_medicine === ""
    ) {
      err["offer_chinese_medicine"] = true;
      err["error"] = true;
    }

    return err;
  }
};
